import { useState, useEffect, useCallback, useRef, useContext } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { i18n } from '../../translate/i18n'
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaAngleDown,
  FaAngleUp,
  FaPowerOff
} from 'react-icons/fa'

import { FcSearch } from 'react-icons/fc'
import { BiFilterAlt, BiRefresh } from 'react-icons/bi'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { VscSettings, VscLinkExternal } from 'react-icons/vsc'
import produce from 'immer'

import { Navbar } from '../../components/Navbar'
import { BreadCrumbs } from '../../components/BreadCrumbs'
import { RequestManager } from '../../security/RequestManager'
import { AuthContext, IUser } from '../../contexts/auth'
import {
  Container,
  Content,
  Sidebar,
  TitleSidebar,
  Skills,
  Domain,
  DomainRequired,
  Range,
  Card,
  NeedRefresh,
  Search,
  ContentPopUp
} from './styles'

import Funnel from '../../assets/img/funnel.svg'

import { IFunnel } from '../Funnel'
import { Filter } from '../../components/Filter'
import { CreateFilterContext } from '../../contexts/filter'
import { Tooltip } from 'src/components/Tooltip'
import PopUp, { IPropsExport } from 'src/components/PopUp'

interface ICandidate {
  score: number
  step: string
  time: number
  stepProgress: number

  id: number
  taxId: string
  emailAddress: string
  firstName: string
  lastName: string
  gender: string
  ethnicity: string
  withDisability: string
  disabilityDescription: string
  birthDate: string
  phone: string
  region: string
  city: string
  linkedinURL: string
  educationalLevel: string
  englishLevel: string
  undergradStudent: string
  course: string
  university: string
  currentSemester: string
  graduationYear: string
  currentEmployment: string
  professionalExperience: string
  developmentExperience: string
  industryExperience: string
  salesforceExperience: string
  trailheadProfileURL: string
  recommendedBy: string
  whySubscribed: string
  timeAvailability: string
  haveComputer: string
  creationDate: string
  notes: string
  status: 'A' | 'R' | 'P' | 'N'

  domains: {
    id: number
    skillLevel: number
    sourceWeight: number
  }[]
}

interface IDomains {
  id: number
  type: string
  tag: string
  title: string
  isRequired: boolean
  skillLevel: number
  skillLevelBase: number
  description: string
  skills: {
    id: number
    title: string
    description: string
    skillLevel: number
    skillLevelBase: number
  }[]
}

interface IRequest {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string

    candidates: ICandidate[]
    hasMore: boolean
    needRefresh: boolean
    orgId: number
    programId: number
    programTitle: string
    searchCount: number
    skillVersion: number
  }
}

interface IRequestFilter {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    filters: IFilter
    program: {
      title: string
      phases: IFunnel[]
      domains: IDomains[]
    }
  }
}

export interface IFilterSelect {
  value: string
  label: string
}

interface IFilter {
  currentEmployment: {
    options: {
      option: string
      count: number
    }[]
  }
  englishLevel: {
    options: {
      option: string
      count: number
    }[]
  }
  developmentExperience: {
    options: {
      option: string
      count: number
    }[]
  }
  ethnicity: {
    options: {
      option: string
      count: number
    }[]
  }
  gender: {
    options: {
      option: string
      count: number
    }[]
  }
  salesforceExperience: {
    options: {
      option: string
      count: number
    }[]
  }
  withDisability: {
    options: {
      option: string
      count: number
    }[]
  }
  region: {
    options: {
      option: string
      count: number
    }[]
  }
  educationalLevel: {
    options: {
      option: string
      count: number
    }[]
  }
}

interface IFilterData {
  field: FieldsOfFilter
  filter: string
  visible?: boolean
  filterOptions: {
    label: string
    quantity: number
  }[]
}

interface IRequestProgramStatus {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    id: number
    status: string
  }
}

type FieldsOfFilter =
  | 'currentEmployment'
  | 'englishLevel'
  | 'developmentExperience'
  | 'ethnicity'
  | 'gender'
  | 'salesforceExperience'
  | 'withDisability'
  | 'region'
  | 'educationalLevel'

export const Rank = () => {
  const query = useLocation().search

  const phase = new URLSearchParams(query).get('phase')

  const history = useHistory()

  const [status, setStatus] = useState('')
  const [filter, setFilter] = useState('')
  const [search, setSearch] = useState('')
  const [needRefresh, setNeedRefresh] = useState(false)
  const [loadFilters, setLoadFilters] = useState(false)
  const [changeSkill, setChangeSkill] = useState(true)
  const [visible, setVisible] = useState(true)
  const [change, setChange] = useState(false)
  const [programStepId, setProgramStepId] = useState(0)
  const [skillVersion, setSkillVersion] = useState(0)

  const [fieldFilter, setFieldFilter] = useState<IFilter>({} as IFilter)
  const [programDomains, setProgramDomains] = useState<IDomains[]>([])
  const [dataFilter, setDataFilter] = useState<IFilterData[]>([])
  const [candidates, setCandidates] = useState<ICandidate[]>([])
  const [filters, setFilters] = useState<IFilterSelect[]>([])
  const [data, setData] = useState<IFunnel[]>([])

  const modalRef = useRef<IPropsExport>(null)

  const { user: userData } = useContext(AuthContext)
  const [user, setUser] = useState<IUser>({} as IUser)

  const [modals, setModals] = useState({
    toggleSort: true,
    toggleFilter: false
  })

  const [showContent, setShowContent] = useState({ show: false, id: 0 })
  const [configs, setConfigs] = useState({
    offset: 0,
    limit: 100
  })
  const [programInfo, setProgramInfo] = useState({
    orgId: 0,
    programId: Number(localStorage.getItem('TALENT_PROGRAM_ID')),
    programTitle: '',
    searchCount: 0,
    hasMore: false
  })
  const [filtered, setFiltered] = useState(
    [] as {
      name: string
      options: string[]
    }[]
  )

  const filterBy = useCallback(async () => {
    const response = (await RequestManager.requestAPI(
      '/talent/candidate/filter',
      {
        programId: Number(localStorage.getItem('TALENT_PROGRAM_ID')),
        programStepId,
        status
      }
    )) as IRequestFilter
    if (response.receivedData.filters) {
      setFieldFilter(response.receivedData.filters)
      setLoadFilters(true)
    }
  }, [status, programStepId])

  if (loadFilters) {
    setDataFilter([
      {
        field: 'salesforceExperience',
        filter: i18n.t('filters.fields.salesforceExperience'),
        filterOptions:
          fieldFilter?.salesforceExperience?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'developmentExperience',
        filter: i18n.t('filters.fields.developmentExperience'),
        filterOptions:
          fieldFilter?.developmentExperience?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'englishLevel',
        filter: i18n.t('filters.fields.englishLevel'),
        filterOptions:
          fieldFilter?.englishLevel?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'educationalLevel',
        filter: i18n.t('filters.fields.educationalLevel'),
        filterOptions:
          fieldFilter?.educationalLevel?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'region',
        filter: i18n.t('filters.fields.region'),
        filterOptions:
          fieldFilter?.region?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'gender',
        filter: i18n.t('filters.fields.gender'),
        filterOptions:
          fieldFilter?.gender?.options.map((filter) => {
            return {
              label:
                filter.option === 'M'
                  ? i18n.t('person.male')
                  : filter.option === 'F'
                  ? i18n.t('person.female')
                  : i18n.t('person.other'),
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'currentEmployment',
        filter: i18n.t('filters.fields.currentEmployment'),
        filterOptions:
          fieldFilter?.currentEmployment?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      }
    ])
    setLoadFilters(false)
  }

  const filteredField = useCallback(
    async (field: FieldsOfFilter, option: string, action: 'add' | 'remove') => {
      if (
        [
          i18n.t('person.male'),
          i18n.t('person.female'),
          i18n.t('person.other')
        ].some((any) => any === option)
      ) {
        switch (option) {
          case i18n.t('person.male'):
            option = 'M'
            break
          case i18n.t('person.female'):
            option = 'F'
            break
          case i18n.t('person.other'):
            option = 'O'
            break

          default:
            break
        }
      }

      if (filtered.length === 0) {
        setFiltered(
          produce(filtered, (draft) => {
            draft.push({ name: field, options: [option] })
          })
        )
      } else {
        let index = 0
        if (action === 'add') {
          setFiltered(
            produce(filtered, (draft) => {
              if (action === 'add') {
                if (draft.some((any) => any.name === field)) {
                  draft.forEach((any, indexDraft) => {
                    if (any.name === field) {
                      index = indexDraft
                      return indexDraft
                    }
                  })
                  draft[index].options.push(option)
                } else draft.push({ name: field, options: [option] })
              }
            })
          )
        } else {
          setFiltered(
            produce(filtered, (draft) => {
              draft.map((drf, index) => {
                if (drf.name === field) {
                  if (drf.options.length > 1)
                    draft[index].options = draft[index].options.filter(
                      (fil) => fil !== option
                    )
                  else {
                    draft[index].options = []
                  }
                }
                return drf
              })
            })
          )
        }
      }

      setChange(true)
    },
    [filtered]
  )

  const loadMore = useCallback(
    async (filters?: { name: string; options: string[] }[]) => {
      setConfigs({
        limit: 100,
        offset: configs.offset + 100
      })

      const response = (await RequestManager.requestAPI(
        '/talent/candidate/list',
        {
          programId: programInfo.programId,
          limit: configs.limit,
          offset: configs.offset + 100,
          filters,
          version: skillVersion,
          programStepId,
          status
        }
      )) as IRequest

      setCandidates((oldArray) => [
        ...oldArray,
        ...response.receivedData.candidates
      ])

      setProgramInfo({
        orgId: programInfo.orgId,
        programId: programInfo.programId,
        programTitle: programInfo.programTitle,
        searchCount: programInfo.searchCount,
        hasMore: response.receivedData.hasMore
      })
    },
    [configs, programInfo, status, skillVersion, programStepId]
  )

  const academicList = useCallback(
    async (
      filters?: { name: string; options: string[] }[],
      search?: string
    ) => {
      const response = (await RequestManager.requestAPI(
        '/talent/candidate/list',
        {
          programId: programInfo.programId,
          programStepId,
          filters,
          status,
          search
        }
      )) as IRequest

      if (response.receivedData.candidates) {
        setCandidates(
          response.receivedData.candidates.map((candidate) => {
            if (candidate.domains.length > 0) {
              candidate.domains.map((domain) => {
                domain.skillLevel = domain.skillLevel * 10
                return domain
              })
            }
            return candidate
          })
        )
        setSkillVersion(response.receivedData.skillVersion)
        setNeedRefresh(response.receivedData.needRefresh)

        setProgramInfo({
          orgId: response.receivedData.orgId,
          programId: programInfo.programId,
          programTitle: response.receivedData.programTitle,
          searchCount: response.receivedData.searchCount,
          hasMore: response.receivedData.hasMore
        })
      }
    },
    [programStepId, status, programInfo]
  )

  const wichColor = useCallback(
    (id: number, skillLevel: number) => {
      const dividend = programDomains.find(
        (domain) => domain.id === id
      )?.skillLevel
      const difference = dividend
        ? Number((skillLevel / dividend).toFixed(2))
        : 0

      if (difference >= 1.1) return 'green'
      if (difference >= 1) return 'lightGreen'
      if (difference > 0.93) return 'lightOrange'
      if (difference > 0.86) return 'orange'
      if (difference > 0.8) return 'lightRed'
      return 'red'
    },
    [programDomains]
  )

  const resetSkills = useCallback(() => {
    setProgramDomains(
      programDomains.map((domain) => {
        domain.skillLevel = domain.skillLevelBase
        domain.skills.map((skill) => {
          skill.skillLevel = skill.skillLevelBase
          return skill
        })
        return domain
      })
    )
    setChangeSkill(
      !programDomains
        .map((domain) => domain.skillLevel === domain.skillLevelBase)
        .some((any) => any === false)
    )
  }, [programDomains])

  const saveSkills = useCallback(() => {
    setProgramDomains(
      programDomains.map((domain) => {
        const difference =
          (domain.skillLevel - domain.skillLevelBase) /
          domain.skills.length /
          10
        if (difference !== 0) {
          domain.skills.map((skill) => {
            skill.skillLevel = skill.skillLevel + difference
            return skill
          })
        }
        return domain
      })
    )
    setChangeSkill(true)
  }, [programDomains])

  const setProgramStatus = useCallback((id: number, status: string) => {
    ;(async () => {
      const resp = (await RequestManager.requestAPI(
        '/talent/program/set-status',
        {
          id,
          status
        }
      )) as IRequestProgramStatus

      if (resp.receivedData.status === 'C') history.push('/')
    })()
  }, [])

  useEffect(() => {
    setFilter(new URLSearchParams(query).get('filter')?.replace(/ /g, '') || '')

    switch (
      new URLSearchParams(query).get('filter')?.replace(/ /g, '').split(',')[1]
    ) {
      case i18n.t('filters.type.approved'):
        setStatus('A')
        break
      case i18n.t('filters.type.pending'):
        setStatus('P')
        break
      case i18n.t('filters.type.rejected'):
        setStatus('R')
        break
      case i18n.t('filters.type.completed'):
        setStatus('C')
        break
      default:
        setStatus('')
        break
    }

    ;(async () => {
      const response = (await RequestManager.requestAPI(
        '/talent/recruiter/loadprogramdata',
        {
          programId: Number(localStorage.getItem('TALENT_PROGRAM_ID'))
        }
      )) as IRequestFilter
      if (response.receivedData.program) {
        setData(response.receivedData.program.phases)
        setProgramDomains(
          response.receivedData.program.domains.map((program) => {
            if (program.type !== 'P' && program.skills.length > 0) {
              program.skillLevel =
                (program.skills
                  .map((skill) => skill.skillLevel)
                  .reduce((a, b) => a + b) /
                  program.skills.length) *
                10
              program.skillLevelBase =
                (program.skills
                  .map((skill) => skill.skillLevelBase)
                  .reduce((a, b) => a + b) /
                  program.skills.length) *
                10
            }

            return program
          })
        )

        if (phase) {
          setProgramStepId(
            Number(
              response.receivedData.program.phases.find(
                (found) => found.phase === phase
              )?.steps[0].id
            )
          )

          setFilter(
            `${
              response.receivedData.program.phases.find(
                (found) => found.phase === phase
              )?.steps[0].id +
              ',' +
              i18n.t('filters.type.pending')
            }`
          )
        } else
          setProgramStepId(
            Number(new URLSearchParams(query).get('filter')?.split(',')[0])
          )

        setChange(true)
      }
    })()
  }, [])

  useEffect(() => {
    userData && setUser(userData)
  }, [userData])

  const subscriberStepIds = useCallback((): Number[] => {
    const ids: Number[] = []

    data.forEach((dt) => {
      if (dt.phase === 'S')
        dt.steps.forEach((step) => {
          ids.push(step.id)
        })
    })

    return ids
  }, [data])

  useEffect(() => {
    if (change) {
      setFilters([])
      data.forEach((dt) => {
        let phaseName = ''
        switch (dt.phase) {
          case 'S':
            phaseName = i18n.t('filters.phaseName.subscription')
            break
          case 'D':
            phaseName = i18n.t('filters.phaseName.discovery')
            break
          case 'I':
            phaseName = i18n.t('filters.phaseName.ignite')
            break
          case 'A':
            phaseName = i18n.t('filters.phaseName.accelerate')
            break
          default:
            phaseName = ''
            break
        }

        dt.steps.forEach((step) => {
          let stepName = ''
          switch (step.type) {
            case '0':
              stepName = ''
              break
            case '1':
              stepName = i18n.t('filters.stepName.training')
              break
            case '2':
              stepName = i18n.t('filters.stepName.assessment')
              break
            case '3':
              stepName = i18n.t('filters.stepName.exam')
              break
            case '4':
              stepName = i18n.t('filters.stepName.agoge')
              break
            case '5':
              stepName = i18n.t('filters.stepName.interview')
              break
            case '9':
              stepName = i18n.t('filters.stepName.approval')
              break
            default:
              break
          }
          if (step.candidates.completed && step.status !== 'I') {
            setFilters((oldArray) => [
              ...oldArray,
              {
                value: step.id + ',' + i18n.t('filters.type.completed'),
                label: String(
                  (stepName ? phaseName + ' ' + stepName : phaseName) +
                    ' ' +
                    i18n.t('filters.type.completed') +
                    ' (#' +
                    (step.candidates.completed || 0) +
                    ')'
                )
              }
            ])
          }
          if (step.candidates.approved && step.status !== 'I') {
            setFilters((oldArray) => [
              ...oldArray,
              {
                value:
                  phaseName === i18n.t('filters.phaseName.subscription')
                    ? step.id + ','
                    : step.id + ',' + i18n.t('filters.type.approved'),
                label: String(
                  (stepName ? phaseName + ' ' + stepName : phaseName) +
                    ' ' +
                    i18n.t('filters.type.approved') +
                    ' (#' +
                    (step.candidates.approved || 0) +
                    ')'
                )
              }
            ])
          }
          if (step.candidates.pending && step.status !== 'I') {
            setFilters((oldArray) => [
              ...oldArray,
              {
                value: step.id + ',' + i18n.t('filters.type.pending'),
                label: String(
                  (stepName ? phaseName + ' ' + stepName : phaseName) +
                    ' ' +
                    i18n.t('filters.type.pending') +
                    ' (#' +
                    (step.candidates.pending || 0) +
                    ')'
                )
              }
            ])
          }
          if (step.candidates.rejected && step.status !== 'I') {
            setFilters((oldArray) => [
              ...oldArray,
              {
                value: step.id + ',' + i18n.t('filters.type.rejected'),
                label: String(
                  (stepName ? phaseName + ' ' + stepName : phaseName) +
                    ' ' +
                    i18n.t('filters.type.rejected') +
                    ' (#' +
                    (step.candidates.rejected || 0) +
                    ')'
                )
              }
            ])
          }
        })
      })

      academicList(filtered)
      filterBy()
      setChange(false)
    }
  }, [change, data])

  // useEffect(() => {
  //   console.log(
  //     !programDomains
  //       .map((domain) => domain.skillLevel === domain.skillLevelBase)
  //       .some((any) => any === false)
  //   )
  //   console.log(
  //     !programDomains
  //       .map((domain) => domain.skillLevel === domain.skillLevelBase)
  //       .some((any) => any === false)
  //   )

  //   programDomains.length > 0 &&
  //     setChangeSkill(
  //       !programDomains
  //         .map((domain) => domain.skillLevel === domain.skillLevelBase)
  //         .some((any) => any === false)
  //     )
  // }, [programDomains])

  document.title = 'Ranking :: Talent'

  return (
    <CreateFilterContext.Provider value={{ filteredField }}>
      <Navbar />
      <BreadCrumbs
        path={[
          {
            label: i18n.t('breadCrumbs.label.program'),
            route: 'programs'
          }
        ]}
      />

      <Container>
        <div className="tog">
          {!visible && (
            <FaChevronCircleRight
              size={20}
              onClick={() => setVisible(!visible)}
            />
          )}
        </div>

        <Sidebar toggle={visible}>
          <div className="bg">
            <div className="position">
              <div className="search">
                <Link to="/funnel">
                  <img src={Funnel} alt="Funnel" width="34" />
                </Link>
                <select
                  name="filter"
                  id="filter"
                  value={filter}
                  onChange={(option) => {
                    if (
                      subscriberStepIds().find(
                        (id) => id === Number(option.target.value.split(',')[0])
                      ) &&
                      option.target.value.split(',')[1].trim() !== ''
                    ) {
                      option.target.value.split(',')[1].trim() ===
                      i18n.t('filters.type.rejected')
                        ? history.push(
                            `/subscribers?filter=${
                              option.target.value.split(',')[0] +
                              ',' +
                              i18n.t('filters.type.rejected')
                            }`
                          )
                        : option.target.value.split(',')[1].trim() ===
                          i18n.t('filters.type.pending')
                        ? history.push(
                            `/subscribers?filter=${
                              option.target.value.split(',')[0] +
                              ',' +
                              i18n.t('filters.type.pending')
                            }`
                          )
                        : history.push('/subscribers')
                    }

                    switch (option.target.value.split(',')[1].trim()) {
                      case i18n.t('filters.type.approved'):
                        setStatus('A')
                        break
                      case i18n.t('filters.type.pending'):
                        setStatus('P')
                        break
                      case i18n.t('filters.type.rejected'):
                        setStatus('R')
                        break
                      case i18n.t('filters.type.completed'):
                        setStatus('C')
                        break
                      default:
                        setStatus('')
                        break
                    }
                    setProgramStepId(
                      Number(option.target.value.split(',')[0].trim())
                    )
                    setFilters([])
                    setChange(true)
                    setFilter(option.target.value)
                  }}
                >
                  {filters.map(
                    (fil, index) =>
                      fil.label && (
                        <option key={index} value={fil.value} title={fil.label}>
                          {fil.label}
                        </option>
                      )
                  )}
                </select>
              </div>
              <FaChevronCircleLeft
                className="tf"
                size={25}
                onClick={() => setVisible(!visible)}
              />
            </div>
          </div>
          <div className="bg">
            <Search
              onSubmit={(event) => {
                event.preventDefault()
                search && academicList(undefined, search)
              }}
            >
              <input
                type="search"
                placeholder={i18n.t('pages.rank.messages.searchBy')}
                onChange={(event) => setSearch(event.target.value)}
              />
              <button type="submit">
                <FcSearch size={25} />
              </button>
            </Search>
          </div>
          <div className="bg">
            <div className="df">
              <TitleSidebar>
                {i18n.t('pages.rank.titles.sort')}
                {!modals.toggleSort ? (
                  <MdKeyboardArrowDown
                    size={25}
                    className="resume"
                    onClick={() =>
                      setModals({
                        ...modals,
                        toggleSort: !modals.toggleSort
                      })
                    }
                  />
                ) : (
                  <MdKeyboardArrowUp
                    size={25}
                    className="resume"
                    onClick={() =>
                      setModals({
                        ...modals,
                        toggleSort: !modals.toggleSort
                      })
                    }
                  />
                )}
              </TitleSidebar>

              <VscSettings
                size={20}
                color="#1373fc"
                className="hoverEffect"
                onClick={() =>
                  setModals({
                    ...modals,
                    toggleSort: !modals.toggleSort
                  })
                }
              />
            </div>

            {programDomains.length > 0 &&
              modals.toggleSort &&
              programDomains.map(
                (domain, index) =>
                  domain.title.toUpperCase() !== 'PERSONALITY' &&
                  domain.skillLevel > 0 && (
                    <div key={index}>
                      <p className="titleDomain">
                        {domain.title.toLowerCase()}
                      </p>
                      <Range
                        name={domain.title}
                        type="range"
                        min="1"
                        max="100"
                        step="1"
                        value={domain.skillLevel}
                        onChange={(event) => {
                          setProgramDomains(
                            programDomains.map((dom) => {
                              if (dom.title === event.target.name) {
                                dom.skillLevel = Number(event.target.value)
                              }
                              return dom
                            })
                          )
                          setChangeSkill(
                            !programDomains
                              .map(
                                (domain) =>
                                  domain.skillLevel === domain.skillLevelBase
                              )
                              .some((any) => any === false)
                          )
                        }}
                      />
                      <DomainRequired width={domain.skillLevelBase} />
                    </div>
                  )
              )}

            {modals.toggleSort && (
              <div className="buttons">
                <button
                  type="button"
                  disabled={changeSkill}
                  onClick={() => saveSkills()}
                >
                  {i18n.t('pages.rank.buttons.save')}
                </button>
                <button
                  type="button"
                  disabled={changeSkill}
                  onClick={() => resetSkills()}
                >
                  {i18n.t('pages.rank.buttons.reset')}
                </button>
              </div>
            )}
          </div>
          <div className="bg">
            <div className="df">
              <TitleSidebar>
                {i18n.t('pages.rank.titles.filter')}
                {!modals.toggleFilter ? (
                  <MdKeyboardArrowDown
                    size={25}
                    className="resume"
                    onClick={() =>
                      setModals({
                        ...modals,
                        toggleFilter: !modals.toggleFilter
                      })
                    }
                  />
                ) : (
                  <MdKeyboardArrowUp
                    size={25}
                    className="resume"
                    onClick={() =>
                      setModals({
                        ...modals,
                        toggleFilter: !modals.toggleFilter
                      })
                    }
                  />
                )}
              </TitleSidebar>
              <BiFilterAlt
                size={20}
                color="#1373fc"
                className="hoverEffect"
                onClick={() =>
                  setModals({
                    ...modals,
                    toggleFilter: !modals.toggleFilter
                  })
                }
              />
            </div>
            {dataFilter.length > 0 &&
              modals.toggleFilter &&
              dataFilter.map((fil, index) => (
                <Filter
                  key={index}
                  visible={fil?.visible}
                  field={fil.field}
                  filter={fil.filter}
                  filterOptions={fil.filterOptions || []}
                />
              ))}
          </div>
          {user?.profile === 'ADMIN' && (
            <div className="bg">
              <div className="df">
                <TitleSidebar>
                  {i18n.t('pages.rank.titles.finish')}
                </TitleSidebar>
                <FaPowerOff
                  size={18}
                  color="#1373fc"
                  className="hoverEffect"
                  onClick={() => modalRef.current?.openModal()}
                />
              </div>
            </div>
          )}
        </Sidebar>

        <Content toggle={visible}>
          {candidates.length === 0 ? (
            <h1>{i18n.t('pages.rank.messages.notFound')}</h1>
          ) : (
            <h1>Ranking - {programInfo.programTitle}</h1>
          )}
          <h4>
            {i18n
              .t('pages.rank.messages.search')
              .replace('[?]', String(programInfo.searchCount))}
          </h4>
          {candidates.length > 0 &&
            candidates.map((c, index) => (
              <Card
                key={c.id}
                haveSkill={c.domains.length > 0}
                show={showContent.show && showContent.id === c.id}
              >
                <p className="about">
                  <span className="rank">{index + 1}º</span>
                  <span className="points">
                    {c.score && (
                      <>
                        <span className="numberPoints">{c.score}</span>
                        <span className="textPoints">
                          {i18n.t('pages.rank.messages.points')}
                        </span>
                      </>
                    )}
                  </span>
                  <span className="contact">
                    <span>
                      {c.firstName.toLowerCase() +
                        ' ' +
                        c.lastName.toLowerCase()}
                      <Link
                        to={`/academic/?id=${c.id}&position=${
                          index + 1
                        }&candidates=${programInfo.searchCount}`}
                        target="_blank"
                      >
                        <VscLinkExternal className="external" size={18} />
                      </Link>
                    </span>
                    <span>
                      {c.city.toLowerCase()} / {c.region}
                    </span>
                  </span>
                </p>

                <Skills>
                  {c.domains &&
                    c.domains.map((d) => (
                      <Domain
                        key={d.id}
                        type={wichColor(d.id, d.skillLevel)}
                        opacity={Number(d.sourceWeight)}
                      >
                        <Tooltip
                          title={
                            programDomains.find((domain) => domain.id === d.id)
                              ?.title || ''
                          }
                        >
                          <p>
                            {
                              programDomains.find(
                                (domain) => domain.id === d.id
                              )?.tag
                            }
                          </p>
                        </Tooltip>
                      </Domain>
                    ))}

                  {c.domains.length > 0 &&
                    (showContent.show && showContent.id === c.id ? (
                      <FaAngleUp
                        size={15}
                        className="toggleDomains"
                        onClick={() =>
                          setShowContent({
                            show: false,
                            id: c.id
                          })
                        }
                      />
                    ) : (
                      <FaAngleDown
                        size={15}
                        className="toggleDomains"
                        onClick={() =>
                          setShowContent({
                            show: true,
                            id: c.id
                          })
                        }
                      />
                    ))}
                </Skills>
              </Card>
            ))}

          {programInfo.hasMore && (
            <div className="load-more">
              <span onClick={() => loadMore(filtered)}>
                {i18n.t('pages.rank.buttons.load')}
              </span>
            </div>
          )}
        </Content>
      </Container>

      <PopUp ref={modalRef}>
        <ContentPopUp>
          <h2>
            {i18n
              .t('pages.rank.messages.finishProgram')
              .replace('[?]', String(programInfo.programTitle))}
          </h2>
          <div className="warn">
            <span>{i18n.t('pages.rank.messages.warnFinishProgram')}</span>
          </div>
          <div className="buttons">
            <input
              type="button"
              value={String(i18n.t('pages.rank.buttons.close') || 'Close')}
              style={{ backgroundColor: '#0761e7', color: '#fff' }}
              onClick={() => setProgramStatus(programInfo.programId, 'C')}
            />
            <input
              type="button"
              value={String(i18n.t('pages.rank.buttons.cancel' || 'Cancel'))}
              onClick={() => modalRef.current?.openModal()}
            />
          </div>
        </ContentPopUp>
      </PopUp>

      {needRefresh && (
        <NeedRefresh>
          <div className="needRefresh">
            <h1>{i18n.t('pages.rank.messages.attention')}</h1>
            <p>{i18n.t('pages.rank.messages.attentionMessage')}</p>
            <button type="button" onClick={() => location.reload()}>
              <BiRefresh size={25} />
            </button>
          </div>
        </NeedRefresh>
      )}
    </CreateFilterContext.Provider>
  )
}
