import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaUserTie, FaHandshake } from 'react-icons/fa'
import { RiMailSendLine } from 'react-icons/ri'
import { AiOutlineSchedule } from 'react-icons/ai'

import { i18n } from 'src/translate/i18n'
import { Navbar } from '../../components/Navbar'
import { BreadCrumbs } from '../../components/BreadCrumbs'

import { RequestManager } from '../../security/RequestManager'
import { Container, Layer, Content, Complement } from './styles'

interface IRecruitingFunnel {
  status: '' | '1' | '2' | '3' | '4' | '9' | 'D' | 'R'
  count: number
  layer: number
}

interface IRequest {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    recruitingFunnel: IRecruitingFunnel[]
  }
}

export const PoolFunnel = () => {
  const [layers, setLayers] = useState<IRecruitingFunnel[]>([])

  useEffect(() => {
    RequestManager.requestAPI('/talent/pool/loadProjectData', {
      projectId: Number(localStorage.getItem('TALENT_PROJECT_ID'))
    }).then((data) => {
      setLayers(
        (data as IRequest).receivedData.recruitingFunnel.map((layer, index) => {
          return {
            status: layer.status,
            count: layer.count,
            layer: index
          }
        })
      )
    })
  }, [])

  return (
    <>
      <Navbar />
      <BreadCrumbs
        path={[
          {
            label: 'project',
            route: 'project'
          },
          {
            label: 'pool',
            route: 'pool'
          }
        ]}
      />
      <Container>
        <Layer width="82%">
          <Content>
            <h3 className="title">{i18n.t('pages.poolFunnel.favorites')}</h3>
          </Content>
          <Complement>
            <div className="conclude">
              <FaUserTie size={35} />
              {layers[1]?.count > 0 ? (
                <Link to={`/pool?status=${layers[1].status}`}>
                  {layers[1].count}
                </Link>
              ) : (
                0
              )}
            </div>
          </Complement>
        </Layer>
        <Layer width="71%">
          <Content>
            <h3 className="title">{i18n.t('pages.poolFunnel.invited')}</h3>
          </Content>
          <Complement>
            <div className="conclude">
              <RiMailSendLine size={35} />
              {layers[2]?.count > 0 ? (
                <Link to={`/pool?status=${layers[2].status}`}>
                  {layers[2].count}
                </Link>
              ) : (
                0
              )}
            </div>
          </Complement>
        </Layer>
        <Layer width="61.5%">
          <Content>
            <h3 className="title">{i18n.t('pages.poolFunnel.interviews')}</h3>
          </Content>
          <Complement>
            <div className="conclude">
              <AiOutlineSchedule size={35} />
              {layers[4]?.count > 0 ? (
                <Link to={`/pool?status=${layers[4].status}`}>
                  {layers[4].count}
                </Link>
              ) : (
                0
              )}
            </div>
          </Complement>
        </Layer>
        <Layer width="53%">
          <Content>
            <h3 className="title">{i18n.t('pages.poolFunnel.hired')}</h3>
          </Content>
          <Complement>
            <div className="conclude">
              <FaHandshake size={35} />
              {layers[5]?.count > 0 ? (
                <Link
                  to={`/recruiter?project=${Number(
                    localStorage.getItem('TALENT_PROJECT_ID')
                  )}`}
                >
                  {layers[5].count}
                </Link>
              ) : (
                0
              )}
            </div>
          </Complement>
        </Layer>
      </Container>
    </>
  )
}
