import { useState, useCallback, useContext, useEffect } from 'react'
import { i18n } from '../../translate/i18n'
import { FaHome, FaPowerOff, FaEnvelope } from 'react-icons/fa'
import { FiMenu } from 'react-icons/fi'

import { AuthContext, IOrg, IUser } from '../../contexts/auth'
import { Nav, NavLink, Logo, Bars, NavMenu, Orgs } from './styles'

import BR from '../../assets/flags/brasil.png'
import EN from '../../assets/flags/eua.png'
import ES from '../../assets/flags/espanha.png'

import Company from '../../assets/img/fav1.png'
import { Tooltip } from '../Tooltip'
import { RequestManager } from '../../security/RequestManager'

interface IRequest {
  hasError: boolean
  receivedData: {
    error: number
    message?: string
    ring?: boolean
  }
}

export const Navbar = () => {
  const I18N_STORAGE_KEY = 'i18nextLng'
  const { user: userData, orgs: orgList } = useContext(AuthContext)

  const [toggle, setToggle] = useState(false)
  const [orgs, setOrgs] = useState<IOrg[]>([])
  const [user, setUser] = useState<IUser>({} as IUser)

  document.documentElement.lang =
    localStorage.getItem(I18N_STORAGE_KEY) || 'pt-BR'

  const setLanguage = useCallback(
    (language: string) => {
      localStorage.setItem(I18N_STORAGE_KEY, language)
      location.reload()
    },
    [I18N_STORAGE_KEY]
  )

  const changeOrg = useCallback(
    async (orgId: number) => {
      const response = (await RequestManager.requestAPI(
        '/talent/user/change-organization',
        {
          orgId
        }
      )) as IRequest

      if (response.receivedData.error === 0) {
        location.assign('/')
      }
    },
    [RequestManager]
  )

  useEffect(() => {
    if (userData) {
      setUser(userData)
    }
    setOrgs(orgList)
  }, [userData, orgList])

  return (
    <Nav>
      <Logo to="/">
        <img src={Company} alt="Logo Recruiter" />
      </Logo>

      {user?.profile === 'ADMIN' && (
        <Orgs
          id="org"
          onChange={(event) => changeOrg(Number(event.target.value))}
          value={user.orgId}
        >
          {orgs.map((org) => (
            <option key={org.id} value={org.id}>
              {org.name}
            </option>
          ))}
        </Orgs>
      )}

      <NavMenu visible={toggle}>
        <div className="flags">
          <Tooltip title={i18n.t('nav.flags.pt')}>
            <img
              style={{ cursor: 'pointer', marginRight: '0.5rem' }}
              width="20px"
              src={BR}
              alt="Brazil"
              onClick={() => setLanguage('pt-BR')}
            />
          </Tooltip>
          <Tooltip title={i18n.t('nav.flags.en')}>
            <img
              style={{ cursor: 'pointer', marginRight: '0.5rem' }}
              width="20px"
              src={EN}
              alt="EUA"
              onClick={() => setLanguage('en')}
            />
          </Tooltip>
          <Tooltip title={i18n.t('nav.flags.es')}>
            <img
              style={{ cursor: 'pointer', marginRight: '0.5rem' }}
              width="20px"
              src={ES}
              alt="Spanish"
              onClick={() => setLanguage('es')}
            />
          </Tooltip>
        </div>
        {user?.email && (
          <p className="mail">
            <FaEnvelope size={15} />
            {user?.email}
          </p>
        )}

        {user?.profile === 'ADMIN' && (
          <>
            <NavLink to="/" onClick={() => setToggle(!toggle)}>
              <FaHome />
              <p>Home</p>
            </NavLink>
            <NavLink to="/programs" onClick={() => setToggle(!toggle)}>
              <p>{i18n.t('nav.pages.program')}</p>
            </NavLink>
            <NavLink to="/admin/candidate" onClick={() => setToggle(!toggle)}>
              <p>{i18n.t('nav.pages.candidate')}</p>
            </NavLink>
            {/* <NavLink to="/interview" onClick={() => setToggle(!toggle)}>
              <p>{i18n.t('nav.pages.schedule')}</p>
            </NavLink> */}
          </>
        )}
        {user?.profile === 'TALNT' && (
          <>
            <NavLink to="/recruiter" onClick={() => setToggle(!toggle)}>
              <p>{i18n.t('nav.pages.hired')}</p>
            </NavLink>
            <NavLink to="/project" onClick={() => setToggle(!toggle)}>
              <p>{i18n.t('nav.pages.project')}</p>
            </NavLink>
          </>
        )}
        <button type="button" onClick={() => RequestManager.logOut()}>
          <FaPowerOff />
          {i18n.t('nav.pages.logout')}
        </button>
      </NavMenu>

      <Bars onClick={() => setToggle(!toggle)}>
        <p>{user?.firstName}</p>
        <FiMenu color="#fff" size={25} />
      </Bars>
    </Nav>
  )
}
