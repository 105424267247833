import { createGlobalStyle } from 'styled-components'

import BG from '../assets/img/dashboard_pattern.png'

export const GlobalStyle = createGlobalStyle`

  :root {
    --background: #F7F9FA;
    --red: #E52E4D;
    --green: #34af23;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #DCDCDC;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #cdcdcd;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0.5px;
    scroll-behavior: smooth;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background: url(${BG}) repeat center;
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button, select {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    color: #4b5c6b;
  }

  h1, h2, h3, h4, h5, button, li {
    user-select: none;
  }

  p, span {
    font-family: 'Roboto', sans-serif;
    color: #4b5c6b;
  }

  a {
    text-decoration: none;
    color: #4b5c6b;
  }

  button {
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  hr {
    margin: 10px 0;
  }

  svg, img {
    display: unset;
  }

  textarea:focus,
  input:focus,
  select:focus {
    outline: none;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  #loading {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: rotate;
    background: rgba(0,0,0,0.6);
    z-index: 100;
  }

  #loading .image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
