import styled, { css } from 'styled-components'

interface ICard {
  show?: boolean
  haveSkill: boolean
}

interface IColor {
  opacity: number
  type: 'green' | 'red' | 'orange' | 'lightGreen' | 'lightRed' | 'lightOrange'
}

interface IProps {
  width?: number
  active?: boolean
  toggle?: boolean
}

interface IButton {
  color: string
  borderColor: string
}

const colorTypeVariations = {
  green: css`
    background: #006400;
  `,
  red: css`
    background: #ff0d0d;
  `,
  lightGreen: css`
    background: #32cd32;
  `,
  lightRed: css`
    background: #ff4e11;
  `,
  orange: css`
    background: #ff8e15;
  `,
  lightOrange: css`
    background: #fab733;
  `
}

export const Container = styled.div`
  display: flex;
  width: 100%;

  padding: 1rem;

  .about {
    display: flex;
  }

  .rank {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1373fc;
    color: white;
    padding: 1rem 1.4rem;
    border-radius: 4px;
  }

  .points {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem 1rem;
    border-right: 1px dotted #ccc;
  }

  .numberPoints {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .textPoints {
    color: #ccc;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  .contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem 1rem;
    width: max-content;
    min-width: 200px;
    text-transform: capitalize;
  }

  .bar-back {
    width: 100%;
    height: 1.2rem;
    background-color: #efefef;
    margin: 0;
    border-radius: 2px;
  }

  .colum {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100vw;
  }

  .progress {
    display: flex;
    padding-left: 2rem;
  }

  .title-etapa {
    font-size: 0.9rem;
    color: #80868b;

    span {
      color: green;
    }
  }

  .tog {
    position: fixed;
    top: calc(100vh * 0.5);
    left: 0;
    height: 100vh;
    color: #1373fc;
    z-index: 10;
    opacity: 0.2;

    &:hover {
      opacity: 1;
    }

    svg:hover {
      cursor: pointer;
      color: #000;
    }
  }

  .tf {
    width: 2rem;
    color: #1373fc;
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }

  .titleDomain {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    text-transform: capitalize;
  }

  .df {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .hoverEffect {
    cursor: pointer;
    &:hover {
      color: #ccc !important;
    }
  }
`

export const TitleSidebar = styled.h2`
  display: flex;
  align-items: center;
  width: 100%;
  color: #1373fc;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;

  .resume {
    display: flex;
    align-items: flex-end;
    padding-top: 0.3rem;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5rem;
`

export const Domain = styled.span<IColor>`
  p {
    display: flex;
    justify-content: center;
    min-width: 4rem;
    min-height: 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    color: #fff;
    border-radius: 1rem;
    text-transform: none;
    ${(props) => colorTypeVariations[props.type]}
    opacity: ${(props) => (props.opacity <= 0.5 ? 0.5 : props.opacity)};
    text-shadow: black 0.1em 0.1em 0.2em;
  }
`

export const DomainRequired = styled.span<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  height: 0.3rem;
  width: ${(props) => props.width}%;
  color: white;
  border-radius: 1px;
  background: #4b5c6b;
  margin-bottom: 0.5rem;
`

export const Range = styled.input`
  display: flex;
  outline: 0;
  border: 0;
  width: 100%;
  max-width: 100%;
  transition: box-shadow 0.2s ease-in-out;
  background-color: #ddd !important;

  // Chrome
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    & {
      overflow: hidden;
      height: 1rem;
      -webkit-appearance: none;
      background-color: #fff;
    }
    &::-webkit-slider-runnable-track {
      height: 1rem;
      -webkit-appearance: none;
      color: #444;
      transition: box-shadow 0.2s ease-in-out;
    }
    &::-webkit-slider-thumb {
      width: 1rem;
      -webkit-appearance: none;
      height: 1rem;
      cursor: ew-resize;
      background: #fff;
      box-shadow: -540px 0 0 535px #1373fc, inset 0 0 0 40px #1373fc;
      border: 2px solid #fff;
      border-radius: 50%;
      transition: box-shadow 0.2s ease-in-out;
      position: relative;
    }
    &:active::-webkit-slider-thumb {
      background: #1373fc;
      box-shadow: -340px 0 0 335px #1373fc, inset 0 0 0 3px #1373fc;
    }

    // Firefox
    &::-moz-range-progress {
      background-color: #1373fc;
    }
    &::-moz-range-track {
      background-color: #1373fc;
    }
    // IE
    &::-ms-fill-lower {
      background-color: #1373fc;
    }
    &::-ms-fill-upper {
      background-color: #1373fc;
    }
  }
`

export const Box = styled.div`
  display: flex;
  border-radius: 5px;
`

export const BoxRadio = styled.div<IProps>`
  width: 50%;
  padding: 1rem;
  background: ${(props) => (props.active ? '#1373FC' : '#F2F2F2')};
  border: 1px solid ${(props) => (props.active ? '#1373FC' : '#DDDDDD')};
  border-left: none;

  label {
    color: ${(props) => (props.active ? '#FFF' : '#767575')} !important;
    font-weight: bold;
  }

  input[type='radio']:checked + label::after {
    content: '';
    background-color: #1373fc !important;
  }
`

export const Bars = styled.span`
  display: flex;
  background: #dddddd;
  width: 100%;
  height: 0.3rem;
`

export const Progress = styled.span<IProps>`
  background: green;
  width: ${(props) => props.width}%;
  height: 0.3rem;
`

export const Card = styled.div<ICard>`
  position: relative;
  width: 100%;
  height: ${(props) =>
    !props.show ? (props.haveSkill ? '7rem' : '5.5rem') : 'max-content'};
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 0.5rem;

  .external {
    margin-left: 0.5rem;
    color: #4b5c6b;

    &:hover {
      color: #1373fc;
    }
  }

  .toggleDomains {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    color: #ddd !important;

    &:hover {
      color: #1373fc !important;
    }
  }

  .actions-subscription {
    display: flex;
    align-items: center;
  }
`

export const Sidebar = styled.div<IProps>`
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  height: 100vh;
  flex-direction: column;
  flex-grow: 1;
  position: sticky;
  top: 1rem;

  border-radius: 4px;
  overflow: auto;

  .bg {
    background: #fff;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    padding: 1rem;
  }

  .position {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 1.2rem;
    }

    select {
      border-radius: 4px;
      min-width: 13rem;
      border: 1px solid #ddd;
      min-height: 2.4rem;
      margin-left: 0.5rem;

      &:focus-visible {
        border: 1px solid #6495ed;
        outline: none;
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  button {
    width: 48%;
    background-color: #1373fc;
    color: #fff;
    border: none;
    border-radius: 2px;
    padding: 0.3rem;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const Content = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.toggle ? '73%' : '100%')};
  position: relative;
  padding-left: 0.5rem;

  h1 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #4b5c6b;
    letter-spacing: -1px;
    margin: 0;
    margin-bottom: 0.5rem;
    padding: 0;
  }

  h4 {
    position: absolute;
    top: 0.5rem;
    right: 0;
    font-weight: 400;
  }

  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #1373fc;
      color: #fff;
      font-size: 0.8rem;
      width: 150px;
      border-radius: 20px;
      cursor: pointer;
      padding: 0.3rem 0.5rem;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`

export const Button = styled.button<IButton>`
  background: transparent;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  height: max-content;
  margin-left: 0.5rem;

  &:hover {
    background-color: ${(props) => props.color};
    color: #fff;
  }
`

export const NeedRefresh = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.8);

  .needRefresh {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #fff;
    border-radius: 4px;
    padding: 2rem;
  }

  h1 {
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0 2rem;
  }

  button {
    width: 20%;
    background-color: #1373fc;
    color: #fff;
    border: none;
    border-radius: 2px;
    padding: 0.3rem;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const Search = styled.form`
  display: flex;
  align-items: center;

  background: #f2f2f2;
  border-radius: 4px;

  padding-right: 0.5rem;

  input {
    display: flex;
    width: 100%;
    height: 2.5rem;
    border: 1px solid #f2f2f2;
    padding: 0 0.5rem;
    margin-right: 0.5rem;
    border-radius: 4px;

    &:focus-visible {
      border: 1px solid #6495ed;
      outline: none;
    }

    &::placeholder {
      color: #ddd;
    }
  }

  button {
    background: transparent;
    width: max-content;
    margin: 0;
    padding: 0;
  }

  svg {
    cursor: pointer;
    margin: 0.25rem 0;
  }
`

export const ContentPopUp = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0;
  border-radius: 4px;
  padding: 2rem;
  background-color: #fff;

  .warn {
    margin: 2rem 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1rem 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    input[type='button'] {
      border: none;
      padding: 1rem;
      margin-left: 0.5rem;
      cursor: pointer;
      border-radius: 4px;
      text-transform: uppercase;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
`
