import styled, { css } from 'styled-components'

interface IProps {
  type?: 'N' | 'A' | 'R'
}

interface IBlock {
  active: boolean
}

interface IButton {
  color: string
  borderColor: string
}

const alertTypeVariations = {
  N: css`
    border-top: 2px solid #1b1c1d;
  `,
  A: css`
    border: 2px solid #99cc20;
  `,
  R: css`
    border: 2px solid #e52e4d;
  `
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 2rem;

    h1 {
      margin-bottom: 1rem;
    }

    hr {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    p {
      margin: 1rem 0 2rem 0;
    }
  }
`

export const Content = styled.div<IProps>`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  width: 90%;
  height: auto;

  background-color: #fff;
  border-radius: 4px;
  margin: 3rem 0;
  padding: 1rem;

  ${(props) => alertTypeVariations[props.type || 'N']}
`

export const NameBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  p {
    display: flex;
    align-items: center;

    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
  }

  span {
    font-size: 0.9rem;
    font-weight: 700;
    color: #1b1c1d;
  }

  svg {
    margin-right: 0.5rem;
  }

  .edit {
    cursor: pointer;
    &:hover {
      filter: brightness(0.5);
    }
  }
`

export const ActionsBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  .notes {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  .approve-or-fail {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 30%;
    margin-left: 0.5rem;
  }
`

export const Block = styled.div<IBlock>`
  display: ${(props) => (props.active ? ' flex' : 'none')};
  flex-direction: column;
  flex-wrap: wrap;
  /* width: 100%; */
  width: calc(100% / 3);
  margin: 1rem 0;

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
  }

  span {
    color: ${(props) => (props.active ? ' #1b1c1d' : '#ddd')};
    font-size: 0.9rem;
    font-weight: 700;
  }

  .cap {
    text-transform: capitalize;
  }

  .ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const Line = styled.hr`
  display: flex;
  width: 100%;
`

export const CompleteBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem 0;

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
  }

  span {
    color: #1b1c1d;
    font-size: 0.9rem;
    font-weight: 700;
  }
`

export const Button = styled.button<IButton>`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: transparent;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin: 0.5rem 0 0;

  &:hover {
    background-color: ${(props) => props.color};
    color: #fff;
  }
`

export const TextArea = styled.textarea`
  display: flex;
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 4px;

  &:focus-visible {
    border: 1px solid #6495ed;
    outline: none;
  }
`

export const Select = styled.select`
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid #aaa;
  background: #fff;
  border-radius: 4px;

  outline: 0;

  option {
    border: none;
  }

  &:focus-visible {
    border: 1px solid #6495ed;
    outline: none;
  }
`

export const FormEdit = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding: 1rem;
  border-radius: 4px;

  .form {
    display: flex;
  }

  label {
    display: flex;
    align-items: center;
    width: 5.5rem;
    padding: 1rem 0 0.5rem;
    font-size: 16px;
  }

  input {
    width: 100%;
    padding: 0 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }

  .flr {
    display: flex;
    justify-content: flex-end;
  }

  input[type='button'] {
    background: #fff;
    border: 1px solid #4b5c6b;
    border-radius: 4px;
    padding: 0.25rem 2rem;
    cursor: pointer;
    margin-top: 1rem;
    width: max-content;

    &:hover {
      background: #4b5c6b;
      color: #fff;
    }
  }
`
