import { NavLink } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'

import { Container, Content } from './styles'

interface IBC {
  path: {
    label: string
    route: string
  }[]
}

export const BreadCrumbs = ({ path }: IBC) => {
  return (
    <Container>
      <Content>
        {path &&
          path.map((path) => (
            <li key={path.label}>
              <NavLink to={`/${path.route}`}>
                {path.label} <FaChevronRight size={12} />
              </NavLink>
            </li>
          ))}
      </Content>
    </Container>
  )
}
