import { useState, useContext } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'

import { CreateFilterContext } from '../../contexts/filter'
import { FieldsOfFilter } from '../../pages/Subscribers'

import { Container } from './styles'

interface IProps {
  field: FieldsOfFilter
  filter: string
  visible?: boolean
  filterOptions: {
    label: string
    quantity: number
  }[]
}

export const Filter = ({
  field,
  filter,
  filterOptions,
  visible = false
}: IProps) => {
  const [toggle, setToggle] = useState(visible)
  const { filteredField } = useContext(CreateFilterContext) as {
    filteredField: (
      field: FieldsOfFilter,
      option: string,
      action: 'add' | 'remove'
    ) => {}
  }

  return filterOptions.length ? (
    <Container toggle={toggle}>
      <span className="filter-title" onClick={() => setToggle(!toggle)}>
        {toggle ? <FaMinus size={12} /> : <FaPlus size={12} />} {filter}
      </span>
      <div className="filter-options">
        {filterOptions.map(
          (val) =>
            val.quantity > 0 &&
            val.label !== '' && (
              <div key={val.label}>
                <input
                  type="checkbox"
                  onChange={(event) => {
                    if (event.target.checked)
                      filteredField(field, val.label, 'add')
                    else filteredField(field, val.label, 'remove')
                  }}
                />
                <span>
                  {val.label} <span className="qtd">({val.quantity})</span>
                </span>
              </div>
            )
        )}
      </div>
    </Container>
  ) : (
    <></>
  )
}
