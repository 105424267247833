import { useState, useEffect, useCallback } from 'react'
import { i18n } from '../../translate/i18n'
import { Link } from 'react-router-dom'
import { GiSpartanHelmet, GiSandsOfTime } from 'react-icons/gi'

import { RequestManager } from '../../security/RequestManager'
import { Navbar } from '../../components/Navbar'
import { BreadCrumbs } from '../../components/BreadCrumbs'

import Users from '../../assets/img/users.svg'

import {
  Container,
  Layer,
  Content,
  Complement,
  Approve,
  Arrow,
  Row
} from './styles'
import { Tooltip } from 'src/components/Tooltip'

export interface ISteps {
  id: number
  type: '0' | '1' | '2' | '3' | '4' | '5' | '9'
  status: 'O' | 'C' | 'P' | 'I'
  candidates: {
    pending?: number
    completed?: number
    approved?: number
    rejected?: number
  }
}

export interface IFunnel {
  phase: 'S' | 'D' | 'I' | 'A'
  startDate: Date
  endDate: Date
  status: 'O' | 'C' | 'P' | 'I' | 'N'
  steps: ISteps[]
}

interface IRequestFilter {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    program: {
      title: string
      phases: IFunnel[]
    }
  }
}

export const Funnel = () => {
  const [data, setData] = useState<IFunnel[]>([])

  useEffect(() => {
    ;(async () => {
      const response = (await RequestManager.requestAPI(
        '/talent/recruiter/loadprogramdata',
        {
          programId: Number(localStorage.getItem('TALENT_PROGRAM_ID'))
        }
      )) as IRequestFilter
      if (response.receivedData.program) {
        setData(response.receivedData.program.phases)
      }
    })()
  }, [])

  const lastOfArray = useCallback(
    (
      position: number
    ): {
      id: number
      pending: number
      completed: number
      approved: number
      rejected: number
    } => {
      let id = 0
      let pending = 0
      let completed = 0
      let approved = 0
      let rejected = 0

      data[position].steps.forEach((step) => {
        if (step.type === '1') {
          id = step.id
          pending = Number(step.candidates.pending)
          completed = Number(step.candidates.completed)
          approved = Number(step.candidates.approved)
          rejected = Number(step.candidates.rejected)
        }
      })

      return { id, completed, pending, approved, rejected }
    },
    [data]
  )

  const wichStep = (type: string): string => {
    let name = ''
    switch (type) {
      case '0':
        name = i18n.t('pages.funnel.messages.subscription')
        break
      case '1':
        name = ''
        break
      case '2':
        name = i18n.t('pages.funnel.messages.assessment')
        break
      case '3':
        name = i18n.t('pages.funnel.messages.test')
        break
      case '4':
        name = 'Agogê'
        break
      case '5':
        name = i18n.t('pages.funnel.messages.interview')
        break
      case '9':
        name = i18n.t('pages.funnel.messages.approved')
        break
      default:
        break
    }

    return name
  }

  const wichColor = (type: string): string => {
    let color = ''
    switch (type) {
      case '0':
        color = ''
        break
      case '1':
        color = ''
        break
      case '2':
        color = '#FC462B'
        break
      case '3':
        color = '#1F77F8'
        break
      case '4':
        color = '#3F4348'
        break
      case '5':
        color = '#093a72'
        break
      case '9':
        color = '#99cc20'
        break
      default:
        break
    }

    return color
  }

  document.title = 'Funnel :: Talent'

  return (
    <>
      <Navbar />
      <BreadCrumbs
        path={[
          {
            label: i18n.t('breadCrumbs.label.program'),
            route: 'programs'
          }
        ]}
      />

      {data.length > 0 && (
        <Container>
          <Row style={{ marginBottom: '1rem' }}>
            <Arrow color="#B0B4A6" active={true}>
              <button type="button">
                <Link to={'/subscribers'}>
                  <span className="numberArrow">
                    {Number(data[0].steps[0].candidates.completed || 0)}
                  </span>
                  &nbsp;
                  {wichStep(data[0].steps[0].type)}
                </Link>
              </button>
            </Arrow>
            <Approve active={true}>
              <button type="button">
                <Link
                  to={
                    data[0].steps[1].candidates.approved
                      ? `/subscribers?filter=${data[0].steps[1].id},${i18n.t(
                          'filters.type.approved'
                        )}`
                      : '#'
                  }
                >
                  <span className="numberArrow">
                    {Number(data[0].steps[1].candidates.approved)}
                  </span>
                  &nbsp;
                  {wichStep(data[0].steps[1].type)}
                </Link>
              </button>
              {Number(data[0].steps[1].candidates.pending) > 0 && (
                <Link
                  to={`/subscribers?filter=${data[0].steps[1].id}, ${i18n.t(
                    'filters.type.pending'
                  )}`}
                >
                  <span className="sub">
                    {data[0].steps[1].candidates.pending}
                  </span>
                </Link>
              )}
            </Approve>
          </Row>

          <Layer width="81%" active={data[1].status !== 'I'}>
            {data[1].status !== 'I' && (
              <>
                {data[1].startDate && (
                  <p className="start">
                    {i18n.t('pages.funnel.messages.start')}:{' '}
                    <span>
                      {new Date(data[1].startDate).toLocaleDateString('pt-BR')}
                    </span>
                  </p>
                )}
                {data[1].endDate && (
                  <p className="end">
                    {i18n.t('pages.funnel.messages.end')}:{' '}
                    <span>
                      {new Date(data[1].endDate).toLocaleDateString('pt-BR')}
                    </span>
                  </p>
                )}
              </>
            )}
            <Content>
              <h3 className="title">Discovery</h3>
              {data[1].status !== 'I' && (
                <>
                  <div className="not-conclude">
                    <GiSandsOfTime size={20} />
                    <Link
                      to={`/rank?filter=${lastOfArray(1).id},${i18n.t(
                        'filters.type.pending'
                      )}`}
                    >
                      <Tooltip title={i18n.t('pages.funnel.status.pending')}>
                        {lastOfArray(1).pending}
                      </Tooltip>
                    </Link>
                  </div>
                  <div className="conclude">
                    <span className="border" />
                    <img src={Users} alt="People" width="50" />
                    {lastOfArray(1).completed > 0 ? (
                      <Link
                        to={`/rank?filter=${lastOfArray(1).id}, ${i18n.t(
                          'filters.type.completed'
                        )}`}
                      >
                        <Tooltip
                          title={i18n.t('pages.funnel.status.concluded')}
                        >
                          {lastOfArray(1).completed}
                        </Tooltip>
                      </Link>
                    ) : (
                      <Tooltip title={i18n.t('pages.funnel.status.concluded')}>
                        {lastOfArray(1).completed}
                      </Tooltip>
                    )}
                  </div>
                </>
              )}
            </Content>
            <Complement>
              {data[1].steps.map(
                (step) =>
                  step.type !== '0' &&
                  step.type !== '1' &&
                  step.type !== '9' && (
                    <div key={step.id}>
                      <Arrow
                        color={wichColor(step.type)}
                        active={
                          step.status !== 'I' &&
                          (step.candidates.completed !== null ||
                            step.candidates.pending !== null)
                        }
                      >
                        <button type="button">
                          <Link
                            to={
                              step.status !== 'I' &&
                              step.candidates.completed !== null
                                ? `/rank?filter=${step.id}, ${i18n.t(
                                    'filters.type.completed'
                                  )}`
                                : '#'
                            }
                          >
                            {Number(step.candidates.completed) > 0 && (
                              <span className="numberArrow">
                                {step.candidates.completed}
                              </span>
                            )}
                            &nbsp;
                            {wichStep(step.type)}
                          </Link>
                        </button>
                        {Number(step.candidates.pending) > 0 && (
                          <Link
                            to={
                              step.status !== 'I' &&
                              step.candidates.pending !== null
                                ? `/rank?filter=${step.id}, ${i18n.t(
                                    'filters.type.pending'
                                  )}`
                                : '#'
                            }
                          >
                            <span className="sub">
                              {step.candidates.pending}
                            </span>
                          </Link>
                        )}
                      </Arrow>
                    </div>
                  )
              )}

              {data[1].steps.map(
                (step) =>
                  step.type === '9' && (
                    <div key={step.id}>
                      <Approve
                        active={
                          step.status !== 'I' &&
                          (step.candidates.approved !== null ||
                            step.candidates.pending !== null)
                        }
                      >
                        <button type="button">
                          {Number(step.candidates.approved) > 0 ? (
                            <Link
                              to={
                                step.status !== 'I' &&
                                step.candidates.approved !== null
                                  ? `/rank?filter=${step.id}, ${i18n.t(
                                      'filters.type.approved'
                                    )}`
                                  : '#'
                              }
                            >
                              {Number(step.candidates.approved) > 0 && (
                                <span className="numberArrow">
                                  {step.candidates.approved}
                                </span>
                              )}
                              &nbsp;
                              {wichStep(step.type)}
                            </Link>
                          ) : (
                            <>
                              <span className="numberArrow">0</span>
                              &nbsp;
                              {wichStep(step.type)}
                            </>
                          )}
                        </button>
                        {Number(step.candidates.pending) > 0 && (
                          <Link
                            to={
                              step.status !== 'I' &&
                              step.candidates.pending !== null
                                ? `/rank?filter=${step.id}, ${i18n.t(
                                    'filters.type.pending'
                                  )}`
                                : '#'
                            }
                          >
                            <span className="sub">
                              {step.candidates.pending}
                            </span>
                          </Link>
                        )}
                      </Approve>
                    </div>
                  )
              )}
            </Complement>
          </Layer>

          <Layer
            width="70.5%"
            active={
              data[2].status !== 'I' &&
              (lastOfArray(2).pending > 0 || lastOfArray(2).completed > 0)
            }
          >
            {data[2].status !== 'I' && (
              <>
                {data[2].startDate && (
                  <p className="start">
                    start:{' '}
                    <span>
                      {new Date(data[2].startDate).toLocaleDateString('pt-BR')}
                    </span>
                  </p>
                )}
                {data[2].endDate && (
                  <p className="end">
                    end:{' '}
                    <span>
                      {new Date(data[2].endDate).toLocaleDateString('pt-BR')}
                    </span>
                  </p>
                )}
              </>
            )}
            <Content>
              <h3 className="title">Ignite</h3>

              {data[2].status !== 'I' &&
                (lastOfArray(2).pending > 0 ||
                  lastOfArray(2).completed > 0) && (
                  <>
                    <div className="not-conclude">
                      <GiSandsOfTime size={20} />
                      {lastOfArray(2).pending > 0 ? (
                        <Link
                          to={`/rank?filter=${lastOfArray(2).id},${i18n.t(
                            'filters.type.pending'
                          )}`}
                        >
                          <Tooltip
                            title={i18n.t('pages.funnel.status.pending')}
                          >
                            {lastOfArray(2).pending}
                          </Tooltip>
                        </Link>
                      ) : (
                        <Tooltip title={i18n.t('pages.funnel.status.pending')}>
                          {lastOfArray(2).pending}
                        </Tooltip>
                      )}
                    </div>
                    <div className="conclude">
                      <span className="border" />
                      <img src={Users} alt="People" width="50" />

                      {lastOfArray(2).completed > 0 ? (
                        <Link
                          to={`/rank?filter=${lastOfArray(2).id},${i18n.t(
                            'filters.type.completed'
                          )}`}
                        >
                          <Tooltip
                            title={i18n.t('pages.funnel.status.concluded')}
                          >
                            {lastOfArray(2).completed}
                          </Tooltip>
                        </Link>
                      ) : (
                        <Tooltip
                          title={i18n.t('pages.funnel.status.concluded')}
                        >
                          {lastOfArray(2).completed}
                        </Tooltip>
                      )}
                    </div>
                  </>
                )}
            </Content>

            <Complement>
              {data[2].steps.map(
                (step) =>
                  step.type !== '0' &&
                  step.type !== '1' &&
                  step.type !== '9' && (
                    <div key={step.id}>
                      <Arrow
                        color={wichColor(step.type)}
                        active={
                          step.status !== 'I' &&
                          (step.candidates.completed !== null ||
                            step.candidates.pending !== null)
                        }
                      >
                        <button type="button">
                          <Link
                            to={
                              step.status !== 'I'
                                ? `/rank?filter=${step.id}, ${i18n.t(
                                    'filters.type.completed'
                                  )}`
                                : '#'
                            }
                          >
                            {Number(step.candidates.completed) > 0 && (
                              <span className="numberArrow">
                                {step.candidates.completed}
                              </span>
                            )}
                            &nbsp;
                            {wichStep(step.type)}
                          </Link>
                        </button>
                        {Number(step.candidates.pending) > 0 && (
                          <Link
                            to={
                              step.status !== 'I'
                                ? `/rank?filter=${step.id}, ${i18n.t(
                                    'filters.type.pending'
                                  )}`
                                : '#'
                            }
                          >
                            <span className="sub">
                              {step.candidates.pending}
                            </span>
                          </Link>
                        )}
                      </Arrow>
                    </div>
                  )
              )}

              {data[2].steps.map(
                (step) =>
                  step.type === '9' && (
                    <div key={step.id}>
                      <Approve
                        active={
                          step.status !== 'I' &&
                          (step.candidates.approved !== null ||
                            step.candidates.pending !== null)
                        }
                      >
                        <button type="button">
                          {Number(step.candidates.approved) > 0 ? (
                            <Link
                              to={
                                step.status !== 'I' &&
                                step.candidates.approved !== null
                                  ? `/rank?filter=${step.id}, ${i18n.t(
                                      'filters.type.approved'
                                    )}`
                                  : '#'
                              }
                            >
                              {Number(step.candidates.approved) > 0 && (
                                <span className="numberArrow">
                                  {step.candidates.approved}
                                </span>
                              )}
                              &nbsp;
                              {wichStep(step.type)}
                            </Link>
                          ) : (
                            <>
                              <span className="numberArrow">0</span>
                              &nbsp;
                              {wichStep(step.type)}
                            </>
                          )}
                        </button>
                        {Number(step.candidates.pending) > 0 && (
                          <Link
                            to={
                              step.status !== 'I'
                                ? `/rank?filter=${step.id}, ${i18n.t(
                                    'filters.type.pending'
                                  )}`
                                : '#'
                            }
                          >
                            <span className="sub">
                              {step.candidates.pending}
                            </span>
                          </Link>
                        )}
                      </Approve>
                    </div>
                  )
              )}
            </Complement>
          </Layer>

          <Layer
            width="61%"
            active={
              data[3].status !== 'I' &&
              (lastOfArray(3).pending > 0 || lastOfArray(3).completed > 0)
            }
          >
            {data[3].status !== 'I' &&
              (lastOfArray(3).pending > 0 || lastOfArray(3).completed > 0) && (
                <>
                  {data[3].startDate && (
                    <p className="start">
                      start:{' '}
                      <span>
                        {new Date(data[3].startDate).toLocaleDateString(
                          'pt-BR'
                        )}
                      </span>
                    </p>
                  )}
                  {data[3].endDate && (
                    <p className="end">
                      end:{' '}
                      <span>
                        {new Date(data[3].endDate).toLocaleDateString('pt-BR')}
                      </span>
                    </p>
                  )}
                </>
              )}
            <Content>
              <h3 className="title">Accelerate</h3>
              {data[3].status !== 'I' &&
                (lastOfArray(3).pending > 0 ||
                  lastOfArray(3).completed > 0) && (
                  <>
                    <div className="not-conclude">
                      <GiSandsOfTime size={20} />
                      {lastOfArray(3).pending > 0 ? (
                        <Link
                          to={`/rank?filter=${lastOfArray(3).id},  ${i18n.t(
                            'filters.type.pending'
                          )}`}
                        >
                          <Tooltip
                            title={i18n.t('pages.funnel.status.pending')}
                          >
                            {lastOfArray(3).pending}
                          </Tooltip>
                        </Link>
                      ) : (
                        <Tooltip title={i18n.t('pages.funnel.status.pending')}>
                          {lastOfArray(3).pending}
                        </Tooltip>
                      )}
                    </div>
                    <div className="conclude">
                      <span className="border" />
                      <img src={Users} alt="People" width="50" />

                      {lastOfArray(3).completed > 0 ? (
                        <Link
                          to={`/rank?filter=${lastOfArray(3).id},${i18n.t(
                            'filters.type.completed'
                          )}`}
                        >
                          <Tooltip
                            title={i18n.t('pages.funnel.status.concluded')}
                          >
                            {lastOfArray(3).completed}
                          </Tooltip>
                        </Link>
                      ) : (
                        <Tooltip
                          title={i18n.t('pages.funnel.status.concluded')}
                        >
                          {lastOfArray(3).completed}
                        </Tooltip>
                      )}
                    </div>
                  </>
                )}
            </Content>
            <Complement>
              {data[3].steps.map(
                (step) =>
                  step.type !== '0' &&
                  step.type !== '1' &&
                  step.type !== '9' && (
                    <div key={step.id}>
                      <Arrow
                        color={wichColor(step.type)}
                        active={
                          step.status !== 'I' &&
                          (step.candidates.completed !== null ||
                            step.candidates.pending !== null)
                        }
                      >
                        <button type="button">
                          <Link
                            to={
                              step.status !== 'I'
                                ? `/rank?filter=${step.id}, ${i18n.t(
                                    'filters.type.completed'
                                  )}`
                                : '#'
                            }
                          >
                            {Number(step.candidates.completed) > 0 && (
                              <span className="numberArrow">
                                {step.candidates.completed}
                              </span>
                            )}
                            <GiSpartanHelmet size={35} />
                            &nbsp;
                            {wichStep(step.type)}
                          </Link>
                        </button>
                        {Number(step.candidates.pending) > 0 && (
                          <Link
                            to={
                              step.status !== 'I'
                                ? `/rank?filter=${step.id}, ${i18n.t(
                                    'filters.type.pending'
                                  )}`
                                : '#'
                            }
                          >
                            <span className="sub">
                              {step.candidates.pending}
                            </span>
                          </Link>
                        )}
                      </Arrow>
                    </div>
                  )
              )}

              {data[3].steps.map(
                (step) =>
                  step.type === '9' && (
                    <div key={step.id}>
                      <Approve
                        active={
                          step.status !== 'I' &&
                          (step.candidates.approved !== null ||
                            step.candidates.pending !== null)
                        }
                      >
                        <button type="button">
                          {Number(step.candidates.approved) > 0 ? (
                            <Link
                              to={
                                step.status !== 'I'
                                  ? `/rank?filter=${step.id}, ${i18n.t(
                                      'filters.type.approved'
                                    )}`
                                  : '#'
                              }
                            >
                              {Number(step.candidates.approved) > 0 && (
                                <span className="numberArrow">
                                  {step.candidates.approved}
                                </span>
                              )}
                              &nbsp;
                              {wichStep(step.type)}
                            </Link>
                          ) : (
                            <>
                              <span className="numberArrow">0</span>
                              &nbsp;
                              {wichStep(step.type)}
                            </>
                          )}
                        </button>
                        {Number(step.candidates.pending) > 0 && (
                          <Link
                            to={
                              step.status !== 'I'
                                ? `/rank?filter=${step.id}, ${i18n.t(
                                    'filters.type.pending'
                                  )}`
                                : '#'
                            }
                          >
                            <span className="sub">
                              {step.candidates.pending}
                            </span>
                          </Link>
                        )}
                      </Approve>
                    </div>
                  )
              )}
            </Complement>
          </Layer>
        </Container>
      )}
    </>
  )
}
