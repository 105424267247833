import { useState, useCallback } from 'react'
import { i18n } from '../../../translate/i18n'
import {
  FaUserCog,
  FaEnvelope,
  FaUserAlt,
  FaArrowCircleRight
} from 'react-icons/fa'
import { FcSearch } from 'react-icons/fc'

import { Navbar } from '../../../components/Navbar'
import { BreadCrumbs } from '../../../components/BreadCrumbs'
import { loading } from '../../../utils/loading'

import { RequestManager } from '../../../security/RequestManager'

import {
  Container,
  Content,
  Card,
  NameBlock,
  Block,
  CompleteBlock,
  Line,
  ContainerPopUp,
  ContentPopUp,
  Box,
  Close,
  Search
} from './styles'

interface ICandidates {
  id: number
  userId: number
  taxId: string
  emailAddress: string
  firstName: string
  lastName: string
  gender: string
  birthDate: string
  phone: string
  region: string
  city: string
  linkedinURL: string
  educationalLevel: string
  englishLevel: string
  undergradStudent: string
  course: string
  university: string
  currentSemester: string
  graduationYear: string
  currentEmployment: string
  professionalExperience: string
  developmentExperience: string
  industryExperience: string
  salesforceExperience: string
  trailheadProfileURL: string
  recommendedBy: string
  whySubscribed: string
  creationDate: number
  notes: string
  mentor: string
  lastSignonInDays: number
  status: 'A' | 'R' | 'N'

  programs: {
    orgId: number
    candidateId: number
    programId: number
    programExternalId: number
    programTitle: string
  }[]
}

interface IRequest {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    candidates: ICandidates[]
  }
}

export const Candidate = () => {
  const [candidates, setCandidates] = useState<ICandidates[]>([])
  const [candidateId, setCandidateId] = useState(0)
  const [toggle, setToggle] = useState(false)
  const [searched, setSearched] = useState(false)
  const [search, setSearch] = useState('')

  const academicList = useCallback(async () => {
    loading('flex')
    if (search !== '') {
      const resp = (await RequestManager.requestAPI(
        '/talent/admin/candidate/list',
        {
          search
        }
      )) as IRequest

      setSearched(true)

      resp.receivedData.candidates.length > 0
        ? setCandidates(
            resp.receivedData.candidates.filter(
              (candidate, index, self) =>
                index ===
                self.findIndex((element) => element.userId === candidate.userId)
            )
          )
        : setCandidates([])
    }
    loading('none')
  }, [search])

  const changeOrg = useCallback(
    async (orgId: number, candidateId: number) => {
      const response = (await RequestManager.requestAPI(
        '/talent/user/change-organization',
        {
          orgId
        }
      )) as IRequest

      if (response.receivedData.error === 0) {
        location.assign(
          `/academic/?id=${candidateId}&position=${1}&candidates=1`
        )
      }
    },
    [RequestManager]
  )

  document.title = 'Candidate :: Talent'

  return (
    <>
      <Navbar />
      <BreadCrumbs
        path={[
          {
            label: i18n.t('breadCrumbs.label.program'),
            route: 'programs'
          }
        ]}
      />

      <Container>
        <Content>
          <Search
            onSubmit={(event) => {
              event.preventDefault()
              search && academicList()
            }}
          >
            <FcSearch
              style={{ transform: 'rotate(90deg)' }}
              size={25}
              onClick={() => academicList()}
            />
            <input
              type="search"
              placeholder={i18n.t(
                'pages.admin.candidates.messages.placeholder'
              )}
              onChange={(event) => {
                setSearched(false)
                setSearch(event.target.value)
              }}
              autoFocus
            />
          </Search>

          {candidates.length > 0 && (
            <h1>
              {i18n
                .t('pages.admin.candidates.messages.found')
                .replace('[*]', String(candidates.length))}
            </h1>
          )}

          {candidates.length === 0 && searched && (
            <h1>{i18n.t('pages.admin.candidates.messages.notFound')}</h1>
          )}

          {candidates.length > 0 &&
            candidates.map((c) => (
              <Card
                key={c.id}
                onClick={() => {
                  setCandidateId(c.id)
                  setToggle(!toggle)
                }}
              >
                <div className="about">
                  <p>
                    <FaUserAlt />
                    <span className="contact">
                      {c.firstName.toLowerCase() +
                        ' ' +
                        c.lastName.toLowerCase()}
                    </span>
                  </p>
                  <p>
                    <FaEnvelope />
                    <span>{c.emailAddress}</span>
                  </p>
                </div>
                <p className="programs">
                  <FcSearch style={{ transform: 'rotate(90deg)' }} size={20} />
                  {c.programs.length}
                </p>
              </Card>
            ))}
        </Content>

        <ContainerPopUp toggle={toggle}>
          <ContentPopUp>
            {candidates.length > 0 &&
              candidates.map(
                (candidate) =>
                  candidate.id === candidateId &&
                  candidateId !== 0 && (
                    <Box key={candidate.id}>
                      <Close size={25} onClick={() => setToggle(!toggle)} />
                      <NameBlock>
                        <p>
                          <FaUserCog size={25} />
                          {candidate.firstName + ' ' + candidate.lastName}
                        </p>
                        <span>{i18n.t('pages.subscriber.fields.student')}</span>
                        <Line />
                      </NameBlock>

                      <>
                        <Block>
                          <span>{i18n.t('pages.subscriber.fields.taxId')}</span>
                          <p>{candidate.taxId ? candidate.taxId : '-'}</p>
                        </Block>
                        <Block>
                          <span>{i18n.t('pages.subscriber.fields.email')}</span>
                          <p className="ellipsis">
                            {candidate.emailAddress
                              ? candidate.emailAddress
                              : '-'}
                          </p>
                        </Block>
                        <Block>
                          <span>{i18n.t('pages.subscriber.fields.phone')}</span>
                          <p>{candidate.phone ? candidate.phone : '-'}</p>
                        </Block>

                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.gender')}
                          </span>
                          <p>
                            {candidate.gender === 'M'
                              ? i18n.t('pages.subscriber.gender.man')
                              : candidate.gender === 'F'
                              ? i18n.t('pages.subscriber.gender.woman')
                              : i18n.t('pages.subscriber.gender.other')}
                          </p>
                        </Block>
                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.birthDate')}
                          </span>
                          <data value={candidate.birthDate}>
                            {candidate.birthDate
                              ? new Date(
                                  candidate.birthDate
                                ).toLocaleDateString('pt-BR')
                              : '-'}
                          </data>
                        </Block>
                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.region')} /{' '}
                            {i18n.t('pages.subscriber.fields.city')}
                          </span>
                          <p className="cap">
                            {candidate.region && candidate.city
                              ? candidate.region + ' / ' + candidate.city
                              : '-'}
                          </p>
                        </Block>

                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.mentor')}
                          </span>
                          <p>{candidate.mentor ? candidate.mentor : '-'}</p>
                        </Block>

                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.lastSignOnInDays')}
                          </span>
                          <p>
                            {candidate.lastSignonInDays !== null
                              ? candidate.lastSignonInDays === 0
                                ? i18n.t('pages.subscriber.messages.today')
                                : candidate.lastSignonInDays === 1
                                ? i18n.t('pages.subscriber.messages.day')
                                : candidate.lastSignonInDays +
                                  ' ' +
                                  i18n.t('pages.subscriber.messages.days')
                              : '-'}
                          </p>
                        </Block>

                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.creationDate')}
                          </span>
                          <data value={candidate.creationDate}>
                            {candidate.creationDate
                              ? new Date(
                                  candidate.creationDate
                                ).toLocaleDateString('pt-BR')
                              : '-'}
                          </data>
                        </Block>
                      </>
                      <Line />
                      <>
                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.educationalLevel')}
                          </span>
                          <p>
                            {candidate.educationalLevel
                              ? candidate.educationalLevel
                              : '-'}
                          </p>
                        </Block>
                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.course')}
                          </span>
                          <p>{candidate.course ? candidate.course : '-'}</p>
                        </Block>
                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.university')}
                          </span>
                          <p>
                            {candidate.university ? candidate.university : '-'}
                          </p>
                        </Block>

                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.currentSemester')}
                          </span>
                          <p>
                            {candidate.currentSemester
                              ? candidate.currentSemester
                              : '-'}
                          </p>
                        </Block>
                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.graduationYear')}
                          </span>
                          <p>
                            {candidate.graduationYear
                              ? candidate.graduationYear
                              : '-'}
                          </p>
                        </Block>
                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.englishLevel')}
                          </span>
                          <p>
                            {candidate.englishLevel
                              ? candidate.englishLevel
                              : '-'}
                          </p>
                        </Block>

                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.linkedin')}
                          </span>
                          <p>
                            <a
                              href={candidate.linkedinURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {candidate.linkedinURL
                                ? candidate.linkedinURL.split('/')[4]
                                : '-'}
                            </a>
                          </p>
                        </Block>
                        <Block>
                          <span>
                            {i18n.t(
                              'pages.subscriber.fields.currentEmployment'
                            )}
                          </span>
                          <p>
                            {candidate.currentEmployment
                              ? candidate.currentEmployment
                              : '-'}
                          </p>
                        </Block>
                        <Block>
                          <span>
                            {i18n.t(
                              'pages.subscriber.fields.developmentExperience'
                            )}
                          </span>
                          <p>
                            {candidate.developmentExperience
                              ? candidate.developmentExperience
                              : '-'}
                          </p>
                        </Block>

                        <Block>
                          <span>
                            {i18n.t(
                              'pages.subscriber.fields.industryExperience'
                            )}
                          </span>
                          <p>
                            {candidate.industryExperience
                              ? candidate.industryExperience
                              : '-'}
                          </p>
                        </Block>
                        <Block>
                          <span>
                            {i18n.t(
                              'pages.subscriber.fields.salesforceExperience'
                            )}
                          </span>
                          <p>
                            {candidate.salesforceExperience
                              ? candidate.salesforceExperience
                              : '-'}
                          </p>
                        </Block>
                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.trailheadProfile')}
                          </span>
                          <p>
                            {candidate.trailheadProfileURL
                              ? candidate.trailheadProfileURL
                              : '-'}
                          </p>
                        </Block>

                        <Block>
                          <span>
                            {i18n.t('pages.subscriber.fields.recommendedBy')}
                          </span>
                          <p className="ellipsis">
                            {candidate.recommendedBy
                              ? candidate.recommendedBy
                              : '-'}
                          </p>
                        </Block>

                        <CompleteBlock>
                          <span>
                            {i18n.t(
                              'pages.subscriber.fields.professionalExperience'
                            )}
                          </span>

                          <p>
                            {candidate.professionalExperience
                              ? candidate.professionalExperience
                              : '-'}
                          </p>
                        </CompleteBlock>
                        <CompleteBlock>
                          <span>
                            {i18n.t('pages.subscriber.fields.whySubscribed')}
                          </span>
                          <p>
                            {candidate.whySubscribed
                              ? candidate.whySubscribed
                              : '-'}
                          </p>
                        </CompleteBlock>
                      </>
                      <Line />
                      {candidate.programs.map((c, index) => (
                        <CompleteBlock key={index}>
                          <p>
                            <span>Candidate ID:</span> {c.candidateId}
                          </p>
                          <p>
                            <span>Program:</span> {c.programTitle}
                          </p>
                          <FaArrowCircleRight
                            size={18}
                            onClick={() => {
                              changeOrg(c.orgId, c.candidateId)
                              localStorage.setItem(
                                'TALENT_PROGRAM_ID',
                                String(c.programId)
                              )
                            }}
                          />
                        </CompleteBlock>
                      ))}
                      <Line />
                      <CompleteBlock>
                        <span>{i18n.t('pages.subscriber.fields.notes')}</span>
                        <p>
                          {candidate.notes ||
                            i18n.t('pages.subscriber.messages.noNotes')}
                        </p>
                      </CompleteBlock>
                    </Box>
                  )
              )}
          </ContentPopUp>
        </ContainerPopUp>
      </Container>
    </>
  )
}
