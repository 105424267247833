import { Container, Content } from './styles'
import { VscDebugDisconnect } from 'react-icons/vsc'

export const Notfound = () => {
  return (
    <Container>
      <Content>
        <VscDebugDisconnect size={200} />
        <h1>404</h1>
      </Content>
    </Container>
  )
}
