import styled from 'styled-components'

interface IProps {
  color?: string
  width: string
}

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 89vh;
  color: #4b5c6b;
  padding: 1rem;
  user-select: none;

  .clear {
    flex-basis: 100%;
    height: 0;
  }

  .adjust {
    margin-top: -12rem;
  }
`

export const Layer = styled.div<IProps>`
  position: relative;
  display: flex;
  width: ${(props) => props.width};
  height: 10rem;
  clip-path: polygon(0% 0%, 100% 0%, 94% 100%, 6% 100%);
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    height: 8rem;
  }
`

export const Content = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  background: #3f4348;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .title {
    color: #99cc20;
    padding-right: 1rem;
    text-transform: capitalize;
    font-family: 'GothamLight';
    font-size: 1.3rem;
  }
`

export const Complement = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  background: #fff;

  .conclude {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1rem;

    color: #4b5c6b;
    font-size: 1.8rem;
    font-family: 'GothamBold', sans-serif;

    a {
      color: #4b5c6b;
    }

    .border {
      width: 1px;
      height: max-content;
      border-left: 1px dotted #646a85;
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`
