import { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import { AuthContext } from '../contexts/auth'

import { Auth } from '../pages/Auth'
import { User } from '../pages/User'
import { Academic } from '../pages/Academic'
import { Candidate } from '../pages/Admin/Candidate'
import { Project } from '../pages/Project'
import { Funnel } from '../pages/Funnel'
import { Notfound } from '../pages/404'
import { Pool } from '../pages/Pool'
import { PoolFunnel } from '../pages/PoolFunnel'
import { Programs } from '../pages/Programs'
import { Rank } from '../pages/Rank'
import { Recruiter } from '../pages/Recruiter'
import { SubscriberList } from '../pages/Subscribers'
import { Subscriber } from '../pages/Subscriber'

export const Routes = () => {
  const { signed, user } = useContext(AuthContext)

  if (!signed) return <Route component={Auth} path="/" />
  else if (user?.status === 'E')
    return <Route component={User} exact path="/" />
  else if (user?.profile === 'ADMIN') {
    return (
      <Switch>
        <Route component={Academic} path="/academic" />
        <Route component={Candidate} path="/admin/candidate" />
        <Route component={Funnel} path="/funnel" />
        <Route component={Programs} path="/programs" />
        <Route component={Programs} exact path="/" />
        <Route component={Rank} path="/rank" />
        <Route component={Subscriber} exact path="/subscriber" />
        <Route component={SubscriberList} exact path="/subscribers" />
        <Route component={Notfound} path="*" />
      </Switch>
    )
  } else if (user?.profile === 'TALNT') {
    return (
      <Switch>
        <Route component={Academic} path="/professional" />
        <Route component={Project} exact path="/" />
        <Route component={Project} exact path="/project" />
        <Route component={Pool} exact path="/pool" />
        <Route component={PoolFunnel} exact path="/pool/funnel" />
        <Route component={Recruiter} exact path="/recruiter" />
        <Route component={Notfound} path="*" />
      </Switch>
    )
  }
  return <></>
}
