import styled from 'styled-components'
import { MdClose } from 'react-icons/md'

interface IProps {
  width?: number
  active?: boolean
  toggle?: boolean
}

interface IButton {
  color: string
  borderColor: string
}

interface IPopUp {
  toggle: boolean
}

interface IError {
  error: boolean
}

export const Container = styled.div`
  display: flex;
  width: 100%;

  padding: 1rem;

  .about {
    display: flex;
  }

  .rank {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1373fc;
    color: white;
    padding: 1rem 1.4rem;
    border-radius: 4px;
  }

  .points {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem 1rem;
    border-right: 1px dotted #ccc;
  }

  .numberPoints {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .textPoints {
    color: #ccc;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  .bar-back {
    width: 100%;
    height: 1.2rem;
    background-color: #efefef;
    margin: 0;
    border-radius: 2px;
  }

  .colum {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100vw;
  }

  .progress {
    display: flex;
    padding-left: 2rem;
  }

  .title-step {
    font-size: 0.9rem;
    color: #80868b;

    span {
      color: green;
    }
  }

  .tog {
    position: fixed;
    top: calc(100vh * 0.5);
    left: 0;
    height: 100vh;
    color: #1373fc;
    z-index: 10;
    opacity: 0.2;

    &:hover {
      opacity: 1;
    }

    svg:hover {
      cursor: pointer;
      color: #000;
    }
  }

  .tf {
    width: 2rem;
    color: #1373fc;
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }

  .titleDomain {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
  }

  .df {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const Content = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.toggle ? '73%' : '100%')};
  position: relative;
  padding-left: 0.5rem;

  h1 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #4b5c6b;
    letter-spacing: -1px;
    margin: 0.5rem 0;
    padding: 0;
  }

  h4 {
    position: absolute;
    top: 0.5rem;
    right: 0;
    font-weight: 400;
  }

  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #1373fc;
      color: #fff;
      font-size: 0.8rem;
      width: 150px;
      border-radius: 20px;
      cursor: pointer;
      padding: 0.3rem 0.5rem;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`

export const TitleSidebar = styled.h2`
  color: #1373fc;
  margin: 1rem 0;
  font-size: 1.3rem;
`

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #fff;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    background: #fafafa;
  }

  .about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 0.5rem;
    }

    .contact {
      text-transform: capitalize;
    }
  }

  .programs {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 0.5rem;
  }
`

export const Sidebar = styled.div<IProps>`
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  height: 100vh;
  flex-direction: column;
  flex-grow: 1;
  position: sticky;
  top: 1rem;
  overflow: auto;

  background: #fff;
  border-radius: 4px;
  padding: 1rem;

  .position {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    h2 {
      font-size: 1.2rem;
    }

    select {
      border-radius: 4px;
      min-width: 13rem;
      border: 1px solid #ddd;
      min-height: 2.4rem;
      margin-left: 0.5rem;

      &:focus-visible {
        border: 1px solid #6495ed;
        outline: none;
      }
    }

    h4 {
      display: flex;
      align-items: flex-end;
      border-radius: 20px;
      background-color: #fff;
      height: max-content;
      padding: 0.25rem 0.5rem;
    }
  }

  .search {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
  }
`

export const Search = styled.form`
  display: flex;
  width: 100%;
  height: 3rem;
  align-items: center;

  background: #fff;
  border-radius: 8px;
  padding-right: 0.5rem;

  input {
    display: flex;
    width: 100%;
    height: 2.5rem;
    font-size: 1.3rem;
    border: none;
    border-left: 1px solid #f2f2f2;
    padding: 0 0.5rem;
    margin-left: 0.5rem;

    &:focus-visible {
      border: none;
      outline: none;
    }
  }

  input::placeholder {
    color: #ddd;
    font-size: 1rem;
  }

  svg {
    margin-left: 0.5rem;
    cursor: pointer;
  }
`

export const ContainerPopUp = styled.div<IPopUp>`
  position: fixed;
  top: 0;
  right: 0;
  display: ${(props: IProps) => (props.toggle ? 'block' : 'none')};
  width: 100%;
  height: 100vh;

  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;

  padding: 5rem 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 15;
`

export const ContentPopUp = styled.div`
  position: relative;
  display: block;
  margin: auto;
  width: max-content;
  max-width: 80%;
  min-width: 40%;

  background: var(--background);
  border-radius: 4px;
  box-shadow: 0 0 5px #000;
  padding: 1.5rem;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const NameBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  p {
    display: flex;
    align-items: center;

    text-align: justify;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
  }

  span {
    font-size: 0.9rem;
    font-weight: 700;
    color: #1b1c1d;
  }

  svg {
    margin-right: 0.5rem;
  }
`

export const ActionsBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  .notes {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  .approve-or-fail {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 30%;
    margin-left: 0.5rem;
  }
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 32.8%;
  margin: 0.25rem 0;
  padding: 0.25rem 0.5rem;

  p {
    color: rgba(0, 0, 0, 0.87);
    text-align: justify;
    font-size: 0.9rem;
  }

  span {
    color: #1b1c1d;
    font-size: 0.8rem;
    font-weight: 700;
  }

  .cap {
    text-transform: capitalize;
  }

  .ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const Line = styled.hr`
  display: flex;
  width: 100%;
  border: none;
  border-top: 1px solid #ddd;
`

export const CompleteBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin: 0.25rem 0;
  padding: 0.25rem 0.5rem;

  p {
    color: rgba(0, 0, 0, 0.87);
    text-align: justify;
    font-size: 0.9rem;
  }

  span {
    color: #1b1c1d;
    font-size: 0.8rem;
    font-weight: 700;
  }

  svg {
    position: absolute;
    top: 35%;
    right: 1%;
    cursor: pointer;
  }
`

export const Button = styled.button<IButton>`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: transparent;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin: 0.5rem 0 0;

  &:hover {
    background-color: ${(props) => props.color};
    color: #fff;
  }
`

export const TextArea = styled.textarea`
  display: flex;
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 4px;

  &:focus-visible {
    border: 1px solid #6495ed;
    outline: none;
  }
`

export const Select = styled.select`
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid #aaa;
  border-radius: 4px;

  outline: 0;

  option {
    border: none;
  }

  &:focus-visible {
    border: 1px solid #6495ed;
    outline: none;
  }
`

export const Close = styled(MdClose)`
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  color: var(--background);
  cursor: pointer;

  &:hover {
    color: var(--red);
  }
`

export const Error = styled.div<IError>`
  display: ${(props) => (props.error ? 'flex' : 'none')};
  flex-direction: column;

  .bug {
    position: absolute;
    top: 48%;
    right: 5%;
    color: '#4b5c6b' !important;
    transition: right 0.5s;

    animation: bug 20s 0.5s ease-in-out infinite;
    /* transform-origin: 50% 4px; */

    @keyframes bug {
      0% {
        right: 5%;
        transform: rotate(270deg);
      }
      50% {
        right: 50%;
        transform: rotate(270deg);
      }
      51% {
        right: 50%;
        transform: rotate(90deg);
      }
      100% {
        right: 5%;
        transform: rotate(90deg);
      }
    }

    &:hover {
      color: var(--red) !important;
    }
  }

  h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  code {
    margin: 0.5rem 0;
  }
`
