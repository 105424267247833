import styled from 'styled-components'

interface IProps {
  isError: boolean
}

export const Container = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  color: #4b5c6b;
  font-family: 'Roboto', sans-serif;
  margin: 0.25rem;

  label {
    color: ${(props) => (props.isError ? '#ff441d' : '#4b5c6b')};
    padding: 0.25rem 0;
    border-radius: 4px 0 0 4px;
  }

  input::placeholder,
  select::placeholder,
  textarea::placeholder {
    color: #ddd;
  }

  input,
  select,
  textarea {
    border: none;
  }

  textarea {
    resize: none;
    padding: 0.5rem;
  }

  input[type='text'],
  input[type='date'],
  input[type='number'],
  input[type='password'],
  textarea,
  select {
    width: 100%;
    min-width: 10rem;
    min-height: 1.8rem;
    background: #fff;
    padding-left: 0.5rem;
  }

  .group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .options-group {
    display: flex;
    width: 100%;
  }

  .options-inside-group {
    display: flex;
    gap: 1rem;
    width: 100%;
  }

  .radio-group {
    display: flex;
    align-items: center;

    input[type='radio'] {
      margin-right: 0.25rem;
      border: 1px solid ${(props) => (props.isError ? '#ff441d' : '#ddd')};
      appearance: none;

      width: 1rem;
      height: 1rem;
      padding: 2px;

      background-clip: content-box;
      border-radius: 50%;

      &:checked {
        background-color: #ff441d;
      }

      &:focus {
        outline: none !important;
      }
    }
  }

  .border {
    display: flex;
    width: 100%;
    color: #4b5c6b;
    border: 1px solid ${(props) => (props.isError ? '#ff441d' : '#ddd')} !important;
    border-radius: 0;
    outline: none;
  }

  .icon {
    cursor: pointer;
  }
`

export const Alert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
