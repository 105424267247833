import styled from 'styled-components'

import { Link } from 'react-router-dom'

interface IProps {
  color?: string
  width?: string
  pending: boolean
}

export const Container = styled.div`
  display: block;
  width: 100%;
  height: 92vh;

  .content {
    display: flex;
    flex-direction: row;
    align-self: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .layer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 31%;
    min-height: 14rem;

    margin: 0.9rem;
    border-radius: 0.55rem;
    border-top: 0.6rem solid #3f4348;
    padding: 0.5rem 1.2rem;
    background: #fff;

    .subscribers {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-bottom: 0.5rem;
      span {
        padding: 0.25rem 0.5rem;
        font-size: 0.7rem;
        border-radius: 50%;
        background: #ff441d;
        color: #fff;
      }
    }

    &:hover {
      border-top: 0.6rem solid rgba(0, 0, 0, 0.9);
    }

    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      color: #ff441d;
      letter-spacing: normal;
      text-transform: capitalize;
      font-family: 'GothamLight';
      font-size: 1.3rem;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.6rem;
        height: 1.5rem;
        font-size: 0.6rem;
        border-radius: 50%;
        background: #ffa500;
        color: #fff;
      }
    }

    p {
      padding: 0;
      margin: 0;

      .start {
        display: flex;
        flex-direction: column;
        float: left;
        font-size: 0.8rem;
        padding: 0;
        margin: 0;
        line-height: 1rem;

        span {
          color: #ccc;
        }
      }

      .end {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        float: right;
        text-align: end;
        font-size: 0.8rem;
        line-height: 1rem;

        span {
          color: #ccc;
        }
      }
    }

    p,
    span {
      user-select: none;
    }
  }

  .newProgram {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;

    &:hover {
      opacity: 0;
    }
  }

  .create {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #aaa;
    color: #fff;
    border: none;
    font-family: 'GothamLight';
    font-weight: bold;

    &:hover {
      background: #3f4348;
    }
  }

  .form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 4px;

    margin: 0;
    padding: 1rem;

    .partition {
      display: flex;
      width: 100%;
    }

    .titlePop {
      margin-bottom: 1rem;
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      button {
        border-radius: 4px;
        padding: 0.5rem 2rem;
        margin: 0.5rem 0;
        border: none;
        background: #ff441d;
        color: #fff;
        text-transform: uppercase;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  .bell {
    -webkit-animation: ring 4s 0.7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s 0.7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s 0.7s ease-in-out infinite;
    transform-origin: 50% 4px;
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotateZ(0);
    }
    1% {
      -webkit-transform: rotateZ(30deg);
    }
    3% {
      -webkit-transform: rotateZ(-28deg);
    }
    5% {
      -webkit-transform: rotateZ(34deg);
    }
    7% {
      -webkit-transform: rotateZ(-32deg);
    }
    9% {
      -webkit-transform: rotateZ(30deg);
    }
    11% {
      -webkit-transform: rotateZ(-28deg);
    }
    13% {
      -webkit-transform: rotateZ(26deg);
    }
    15% {
      -webkit-transform: rotateZ(-24deg);
    }
    17% {
      -webkit-transform: rotateZ(22deg);
    }
    19% {
      -webkit-transform: rotateZ(-20deg);
    }
    21% {
      -webkit-transform: rotateZ(18deg);
    }
    23% {
      -webkit-transform: rotateZ(-16deg);
    }
    25% {
      -webkit-transform: rotateZ(14deg);
    }
    27% {
      -webkit-transform: rotateZ(-12deg);
    }
    29% {
      -webkit-transform: rotateZ(10deg);
    }
    31% {
      -webkit-transform: rotateZ(-8deg);
    }
    33% {
      -webkit-transform: rotateZ(6deg);
    }
    35% {
      -webkit-transform: rotateZ(-4deg);
    }
    37% {
      -webkit-transform: rotateZ(2deg);
    }
    39% {
      -webkit-transform: rotateZ(-1deg);
    }
    41% {
      -webkit-transform: rotateZ(1deg);
    }

    43% {
      -webkit-transform: rotateZ(0);
    }
    100% {
      -webkit-transform: rotateZ(0);
    }
  }

  @-moz-keyframes ring {
    0% {
      -moz-transform: rotate(0);
    }
    1% {
      -moz-transform: rotate(30deg);
    }
    3% {
      -moz-transform: rotate(-28deg);
    }
    5% {
      -moz-transform: rotate(34deg);
    }
    7% {
      -moz-transform: rotate(-32deg);
    }
    9% {
      -moz-transform: rotate(30deg);
    }
    11% {
      -moz-transform: rotate(-28deg);
    }
    13% {
      -moz-transform: rotate(26deg);
    }
    15% {
      -moz-transform: rotate(-24deg);
    }
    17% {
      -moz-transform: rotate(22deg);
    }
    19% {
      -moz-transform: rotate(-20deg);
    }
    21% {
      -moz-transform: rotate(18deg);
    }
    23% {
      -moz-transform: rotate(-16deg);
    }
    25% {
      -moz-transform: rotate(14deg);
    }
    27% {
      -moz-transform: rotate(-12deg);
    }
    29% {
      -moz-transform: rotate(10deg);
    }
    31% {
      -moz-transform: rotate(-8deg);
    }
    33% {
      -moz-transform: rotate(6deg);
    }
    35% {
      -moz-transform: rotate(-4deg);
    }
    37% {
      -moz-transform: rotate(2deg);
    }
    39% {
      -moz-transform: rotate(-1deg);
    }
    41% {
      -moz-transform: rotate(1deg);
    }

    43% {
      -moz-transform: rotate(0);
    }
    100% {
      -moz-transform: rotate(0);
    }
  }

  @keyframes ring {
    0% {
      transform: rotate(0);
    }
    1% {
      transform: rotate(30deg);
    }
    3% {
      transform: rotate(-28deg);
    }
    5% {
      transform: rotate(34deg);
    }
    7% {
      transform: rotate(-32deg);
    }
    9% {
      transform: rotate(30deg);
    }
    11% {
      transform: rotate(-28deg);
    }
    13% {
      transform: rotate(26deg);
    }
    15% {
      transform: rotate(-24deg);
    }
    17% {
      transform: rotate(22deg);
    }
    19% {
      transform: rotate(-20deg);
    }
    21% {
      transform: rotate(18deg);
    }
    23% {
      transform: rotate(-16deg);
    }
    25% {
      transform: rotate(14deg);
    }
    27% {
      transform: rotate(-12deg);
    }
    29% {
      transform: rotate(10deg);
    }
    31% {
      transform: rotate(-8deg);
    }
    33% {
      transform: rotate(6deg);
    }
    35% {
      transform: rotate(-4deg);
    }
    37% {
      transform: rotate(2deg);
    }
    39% {
      transform: rotate(-1deg);
    }
    41% {
      transform: rotate(1deg);
    }

    43% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(0);
    }
  }

  @media screen and (max-width: 768px) {
    .layer {
      width: 80%;
    }
  }
`

export const Layer = styled.div<IProps>`
  display: flex;
  align-self: center;
  justify-content: center;
  width: ${(props) => props.width};
  height: 3rem;
  clip-path: polygon(0% 0%, 100% 0%, 94% 100%, 6% 100%);
  margin-bottom: 0.5rem;

  opacity: ${(props) => (props.pending ? 0.5 : 1)};
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100%;
  background: #3f4348;

  .title {
    color: #99cc20;
    text-transform: capitalize;
    font-family: 'GothamLight';
    font-size: 0.8rem;
    margin: auto !important;
  }
`

export const Complement = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  height: 3rem;

  background: #eee;

  > p {
    margin-left: 0.5rem !important;
  }
`

export const Program = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 15rem;

  border-radius: 4px;
  background: #4b5c6b;
  color: #fff;
  font-weight: bold;

  h2 {
    text-transform: capitalize;
    letter-spacing: 3px;
  }

  h2,
  h3,
  span,
  p,
  svg {
    color: #fff;
  }

  p {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
  }
`

export const Actions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  border-radius: 0 0 0.55rem 0.55rem;
  opacity: 0;
  transition: opacity 1s;

  &:hover {
    opacity: 1;
  }

  span {
    display: flex;
    flex-direction: column;
  }

  svg {
    cursor: pointer;
    color: white;
  }
`

export const Action = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-weight: bold;

  &:hover {
    filter: brightness(0.8);

    svg {
      filter: brightness(0.8);
    }
  }
`
