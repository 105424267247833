import { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { IconBaseProps } from 'react-icons'
import { FaQuestion } from 'react-icons/fa'

import { Tooltip } from '../Tooltip/index'

import { Container, Alert } from './styles'

interface IProps {
  name: string
  id?: string
  label?: string
  errorSec?: string
  isRequired?: boolean
  icon?: React.ComponentType<IconBaseProps>
}

type InputProps = JSX.IntrinsicElements['input'] & IProps

export const Input = ({
  id,
  name,
  label,
  isRequired,
  errorSec,
  icon: Icon,
  ...rest
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  const isError =
    (error !== '' && error !== undefined) ||
    (errorSec !== '' && errorSec !== undefined)

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current
    })
  }, [fieldName, registerField])

  if (label === '') return <></>

  return (
    <Container isError={isError}>
      {label && (
        <label htmlFor={id}>
          {label}
          {isRequired && '*'}:
        </label>
      )}
      <div className="group">
        <div className="border">
          <input
            id={id}
            name={fieldName}
            ref={inputRef}
            defaultValue={defaultValue}
            {...rest}
          />
          {Icon && <Icon size={25} className="icon" />}
        </div>

        {error && (
          <Alert>
            <Tooltip title={error} type="danger">
              <FaQuestion color="#E52E4D" size={20} />
              &nbsp;
            </Tooltip>
          </Alert>
        )}

        {errorSec && (
          <Alert>
            <Tooltip title={errorSec}>
              <FaQuestion color="#E52E4D" size={20} />
              &nbsp;
            </Tooltip>
          </Alert>
        )}
      </div>
    </Container>
  )
}
