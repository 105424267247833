import { useEffect, useState, createContext } from 'react'

import { RequestManager } from '../security/RequestManager'

export interface IOrg {
  id: number
  name: string
}

export interface IUser {
  id: number
  email: string
  firstName: string
  lastName: string
  orgId: number
  orgTradeName: string
  localeCD: string
  timeoutOption: number
  profile: 'ADMIN' | 'TALNT'
  status: string
}

interface IRequest {
  hasError: boolean
  receivedData: {
    error: number
    message?: string
    userData: IUser
    organizations: IOrg[]
  }
}

interface IAuthContextData {
  signed: boolean
  orgs: IOrg[]
  user: IUser | null
  signOut(): void
}

type WithChildren<T = {}> = T & { children?: React.ReactNode }

export const AuthProvider = ({ children }: WithChildren) => {
  const [user, setUser] = useState<IUser | null>({} as IUser)
  const [orgs, setOrgs] = useState<IOrg[]>([])

  useEffect(() => {
    const signIn = async () => {
      const response = (await RequestManager.requestAPI(
        '/talent/loadbasedata'
      )) as IRequest

      setUser(response.receivedData.userData)
      setOrgs(response.receivedData.organizations)
    }
    signIn()
  }, [])

  const signOut = () => {
    RequestManager.logOut()
    setUser(null)
  }

  return (
    <AuthContext.Provider value={{ signed: !!user, user, orgs, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}

export const AuthContext = createContext<IAuthContextData>(
  {} as IAuthContextData
)
