export const messages = {
  pt: {
    translations: {
      nav: {
        pages: {
          program: 'Programas',
          logout: 'Sair',
          candidate: 'Candidatos',
          schedule: 'Agenda',
          project: 'Projetos',
          hired: 'Contratados'
        },
        flags: {
          pt: 'Português',
          en: 'Inglês',
          es: 'Espanhol'
        }
      },
      breadCrumbs: {
        label: {
          program: 'Programas',
          funnel: 'Funil',
          profile: 'Perfis',
          rank: 'Rank',
          subscribers: 'Inscritos',
          project: 'Projetos'
        }
      },
      pages: {
        user: {
          title: 'Alterar senha',
          fields: {
            pwd: 'Nova senha',
            confirmPwd: 'Repetir senha',
            currentPwd: 'Senha atual'
          },
          typeError: {
            pwd: 'Insira uma nova senha',
            confirmPwd: 'Senha não confere com a nova senha',
            currentPwd: 'Insira a senha atual'
          },
          messages: {
            success: 'Senha alterada com sucesso',
            error:
              'Não foi possível alterar a senha entre em contato com o suporte'
          },
          buttons: {
            save: 'Salvar'
          }
        },
        programs: {
          titles: {
            app: 'Criar novo programa',
            close: 'Encerrado'
          },
          messages: {
            create: 'Criado',
            errorProgramCreate: 'Erro ao criar programa!',
            successProgramCreate: 'Programa criado com sucesso!'
          },
          labels: {
            add: 'Adicionar',
            program: 'Programa',
            profile: 'Perfil',
            externalId: 'IdExterno',
            target: 'Vagas',
            start: 'Início',
            end: 'Término',
            view: 'Visualizar',
            edit: 'Editar',
            subscribers: 'Inscritos',
            finish: 'Encerrar'
          },
          buttons: {
            create: 'Próximo'
          },
          tooltips: {
            add: 'Adicionar Programa',
            view: 'Visualizar Programa',
            edit: 'Editar Programa',
            subscribers: 'Aprovar Inscritos',
            finish: 'Encerrar Programa'
          },
          typeError: {
            title: 'Nome do programa necessário!',
            profile: 'Nome do programa necessário!',
            externalId: 'Nome do Id Externo necessário!',
            target: 'Quantidade de vagas necessária!',
            targetMin: 'Mínimo 1',
            startDate: 'Data de Início Necessária!',
            endDate: 'Data de Término Necessária!'
          }
        },
        profiles: {
          buttons: {
            select: 'Selecionar'
          },
          profile: {
            buttons: {
              create: 'Criar Programa'
            }
          }
        },
        funnel: {
          messages: {
            subscription: 'Inscritos',
            approved: 'Aprovados',
            test: 'Teste',
            interview: 'Entrevista',
            assessment: 'Avaliação',
            start: 'início',
            end: 'término'
          },
          status: {
            concluded: 'Concluídos',
            pending: 'Pendentes'
          }
        },
        academic: {
          titles: {
            personality: 'Personalidade',
            journey: 'Jornada'
          },
          messages: {
            points: 'pontos',
            viewMore: 'ver mais',
            viewLess: 'ver menos',
            approval: 'Aprovação',
            disapproval: 'Reprovação',
            action: 'Deseja continuar com a ação de',
            alert:
              'Atenção uma vez que a ação seja confirmada, não será possível revertê-la',
            success: 'Alteração concluída com sucesso!',
            error: 'Não foi possível concluir a ação!',
            skillsWarn: 'Deseja gerar skills para este candidato?',
            recruiterWarn: 'Deseja alterar o status do candidato?',
            noChanged:
              'Não foi possível alterar o programa, entre em contato com o suporte!',
            successChanged: 'Programa alterado com sucesso',
            successSkills: 'Skills geradas com sucesso!',
            successStatus: 'Status alterado com sucesso!'
          },
          questions: {
            phone: 'Telefone',
            email: 'Email',
            linkedIn: 'LinkedIn',
            trailHead: 'Trailhead',
            taxId: 'CPF',
            birthDate: 'Data de Nascimento',
            gender: 'Gênero',
            englishLevel: 'Nível de Inglês',
            currentEmployment: 'Está trabalhando?',
            educationalLevel: 'Escolaridade',
            developmentExperience: 'Experiência em desenvolvimento?',
            professionalExperience: 'Experiência profissional'
          },
          select: {
            options: {
              change: 'Trocar Status',
              interview: 'Entrevistas',
              hired: 'Contratado',
              rejected: 'Rejeitado'
            }
          },
          buttons: {
            challenge: 'Enviar Desafio',
            approve: 'Aprovar',
            save: 'Salvar',
            fail: 'Reprovar',
            yes: 'Sim',
            no: 'Não',
            notes: 'Comentários',
            changeProgram: 'Trocar Programa',
            generateSkill: 'Gerar Skills',
            summary: 'Resumo',
            detail: 'Detalhado',
            whatProgram: 'Para qual Programa?',
            sendEmail: 'Deseja notificar o candidato por email sobre a mudança?'
          },
          table: {
            journey: 'Jornada',
            trail: 'Trilha',
            points: 'Pontos',
            accuracy: 'Precisão',
            duration: 'Duração'
          },
          fields: {
            required: 'Campo obrigatório'
          }
        },
        rank: {
          titles: {
            ranking: 'Posição no Ranking',
            sort: 'Classificar por',
            filter: 'Filtrar por',
            finish: 'Encerrar programa'
          },
          messages: {
            filter: 'Filtrar por',
            search: 'Sua pesquisa retornou [?] inscritos',
            day: 'dias',
            points: 'Pontos',
            searchBy: 'Buscar por email ou nome',
            notFound: 'Não há inscritos para estes filtros.',
            attention: 'Atenção Rank desatualizado',
            attentionMessage:
              'Há uma nova alteração no Rank clique no botão abaixo para atualizar a página.',
            finishProgram: 'Deseja encerrar o programa [?]?',
            warnFinishProgram:
              'Está ação é irreversível e pode afetar em algumas funcionalidades do sistema como aprovação de candidatos, inscrições, etc.'
          },
          buttons: {
            save: 'Salvar',
            reset: 'Reset',
            load: 'Carregar mais...',
            close: 'Confirmar',
            cancel: 'Cancelar'
          }
        },
        subscribers: {
          messages: {
            search: 'Sua pesquisa retornou [?] inscritos',
            notSubscribers: 'Ainda não há inscritos para este programa.',
            notFound: 'Não há inscritos para estes filtros.',
            filterBy: 'Filtrar por',
            searchBy: 'Buscar por email ou nome'
          },
          cardFilters: {
            english: 'Inglês: ',
            schooling: 'Escolaridade: ',
            region: 'Região: ',
            working: 'Trabalhando: ',
            programing: 'Programação: ',
            salesforce: 'Salesforce: '
          },
          buttons: {
            load: 'Carregar mais...'
          },
          tooltips: {
            approve: 'Aprovado',
            fail: 'Reprovado',
            pending: 'Pendente'
          }
        },
        subscriber: {
          fields: {
            student: 'Candidato',
            taxId: 'CPF',
            email: 'Email',
            phone: 'Telefone',
            gender: 'Gênero',
            region: 'Região',
            city: 'Cidade',
            birthDate: 'Data de nascimento',
            program: 'Programa',
            ethnicity: 'Raça',
            withDisability: 'Possui deficiência?',
            disabilityDescription: 'Qual?',
            educationalLevel: 'Escolaridade',
            course: 'Curso',
            university: 'Universidade',
            currentSemester: 'Semestre atual',
            graduationYear: 'Ano previsto da graduação',
            englishLevel: 'Nível de Inglês',
            lastSignOnInDays: 'Última vez conectado (a)',
            mentor: 'Mentor',
            linkedin: 'LinkedIn',
            currentEmployment: 'Está Trabalhando?',
            developmentExperience: 'Experiência com Desenvolvimento',
            industryExperience: 'Experiência em Indústria',
            salesforceExperience: 'Experiência em Salesforce',
            trailheadProfile: 'Perfil no Trailhead',
            recommendedBy: 'Recomendado por',
            timeAvailability: 'Possui disponibilidade de 16 horas semanais?',
            haveComputer: 'Possui computador com internet?',
            professionalExperience: 'Experiência profissional',
            whySubscribed: 'Porque se inscreveu?',
            creationDate: 'Data de Criação',
            notes: 'Anotações',
            approveOrFail: 'Status do candidato'
          },
          buttons: {
            pending: 'Aprovação pendente',
            approve: 'Aprovado',
            fail: 'Reprovado',
            save: 'Salvar'
          },
          messages: {
            noNotes: 'Não foi adicionado anotações para este candidato!',
            errorTitle: 'Erro ao aprovar usuário',
            errorMessage: 'Contate o administrador do sistema para a correção!',
            codeMessage: 'Erro',
            success: 'Alteração concluída com sucesso!',
            error: 'Não foi possível concluir a ação!',
            days: 'Dias',
            day: 'Dia',
            today: 'Hoje'
          },
          gender: {
            man: 'Masculino',
            woman: 'Feminino',
            other: 'Outro'
          }
        },
        notFound: {
          messages: {
            warning:
              'Autenticação necessária você sera redirecionada para a página de login em instantes.'
          }
        },
        interview: {
          titles: {
            questions: 'Perguntas'
          }
        },
        admin: {
          candidates: {
            messages: {
              placeholder: 'Digite o nome ou email',
              notFound: 'Não há candidatos para esta pesquisa.',
              found: 'Sua pesquisa encontrou [*] resultados.'
            }
          }
        },
        project: {
          titles: {
            project: 'Projeto'
          },
          fields: {
            labels: {
              profile: 'Perfil',
              projectTitle: 'Título do Projeto',
              location: 'Localização',
              workModel: 'Modelo de Trabalho',
              target: 'Vagas',
              message: 'Mensagem',
              start: 'Início',
              end: 'Fim',
              create: 'Criar projeto'
            },
            placeholders: {
              location: 'Curitiba, PR',
              message:
                'Esta mensagem será enviado por padrão ao profissional selecionado.'
            },
            options: {
              workModel: {
                remote: 'Remoto',
                hybrid: 'Híbrido',
                presential: 'Presencial'
              }
            }
          },
          messages: {
            success: 'Projeto criado com sucesso',
            warning: 'Atenção'
          },
          buttons: {
            create: 'Criar'
          }
        },
        pool: {
          titles: {
            contact: 'Contato',
            sort: 'Classificar por',
            filter: 'Filtrar por'
          },
          select: {
            all: 'Todos',
            selected: 'Selecionados',
            invited: 'Convidados',
            scheduled: 'Agendados',
            interviewee: 'Entrevistados',
            hired: 'Contratados',
            declined: 'Declinados',
            released: 'Liberados'
          },
          fields: {
            labels: {
              jobTitle: 'Título da Oportunidade',
              workModel: 'Modelo de Trabalho',
              location: 'Localização',
              message: 'Mensagem'
            },
            options: {
              workModel: {
                remote: 'Remoto',
                hybrid: 'Híbrido',
                presential: 'Presencial'
              }
            },
            required: {
              jobTitle: 'O campo "Título da Oportunidade" é obrigatório!',
              workModel: 'O campo "Modelo de Trabalho" é obrigatório!',
              location: 'O campo "Localização" é obrigatório!',
              message: 'O campo "Mensagem" é obrigatório!'
            }
          },
          buttons: {
            send: 'Enviar',
            save: 'Salvar',
            reset: 'Reset'
          },
          messages: {
            filter: 'Filtrar por',
            search: 'Sua pesquisa retornou [?] inscritos',
            day: 'dias',
            points: 'Pontos',
            searchBy: 'Buscar por email ou nome',
            notFound: 'Não há inscritos para estes filtros.',
            attention: 'Atenção Rank desatualizado',
            attentionMessage:
              'Há uma nova alteração no Rank clique no botão abaixo para atualizar a página.',
            finishProgram: 'Deseja encerrar o programa [?]?',
            warnFinishProgram:
              'Está ação é irreversível e pode afetar em algumas funcionalidades do sistema como aprovação de candidatos, inscrições, etc.',
            updateAt: 'atualizado à [?]'
          },
          notify: {
            sentEmail: 'Email enviado!',
            notSentEmail:
              'Não foi possível enviar o Email, entre em contato com o ADMIN!'
          },
          tooltip: {
            video: 'Vídeo',
            remote: 'Remoto',
            presential: 'Presencial',
            contractMode: 'PJ',
            invite: 'Convidar'
          }
        },
        poolFunnel: {
          favorites: 'Favoritos',
          invited: 'Convidados',
          inProgress: 'Em Progresso',
          interviews: 'Entrevistas',
          hired: 'Contratados'
        },
        recruiter: {
          filters: {
            project: 'Projeto',
            startDate: 'Início',
            endDate: 'Fim'
          },
          labels: {
            all: 'Todos',
            project: 'Projeto',
            name: 'Nome',
            email: 'Email',
            recruiter: 'Recrutador',
            hiredDate: 'Contratado',
            total: 'Total'
          }
        }
      },
      filters: {
        fields: {
          salesforceExperience: 'Possui conhecimento em Salesforce?',
          developmentExperience: 'Possui experiência em desenvolvimento?',
          englishLevel: 'Nível de inglês',
          educationalLevel: 'Escolaridade',
          region: 'Estado',
          gender: 'Gênero',
          currentEmployment: 'Está trabalhando atualmente?',
          ethnicity: 'Raça',
          withDisability: 'Possui deficiência?'
        },
        phaseName: {
          subscription: 'Inscrições',
          discovery: 'Discovery',
          ignite: 'Ignite',
          accelerate: 'Accelerate'
        },
        stepName: {
          training: 'treinamento',
          assessment: 'avaliação',
          exam: 'teste',
          agoge: 'Agogê',
          interview: 'entrevista',
          approval: 'aprovação'
        },
        type: {
          completed: 'completas',
          approved: 'aprovadas',
          pending: 'pendentes',
          rejected: 'rejeitadas'
        }
      },
      calendar: {
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        list: 'Lista',
        save: 'Salvar',
        ctrlZ: 'Desfazer',
        allDay: 'Dia todo',
        available: 'Disponível',
        messages: {
          title: 'Disponibilizar Agenda',
          description:
            'Isso fará com que os candidatos podem marcar a entrevista nos períodos disponibilizados!'
        },
        buttons: {
          save: 'Publicar Agenda',
          cancel: 'Cancelar'
        }
      },
      person: {
        male: 'Masculino',
        female: 'Feminino',
        other: 'Outro'
      },
      personality: {
        opennessToExperience: 'Abertura à Experiência',
        agreeableness: 'Afabilidade',
        emotionality: 'Emotividade',
        conscientiousness: 'Conscienciosidade',
        extroversion: 'Extroversão',
        honestyHumility: 'Honestidade/Humildade',
        description: {
          opennessToExperience:
            'Pessoas com pontuações muito altas na escala de Abertura à Experiência tornam-se absortas na beleza da arte e da natureza, são curiosas sobre vários domínios do conhecimento, usam sua imaginação livremente na vida cotidiana e se interessam por ideias ou pessoas incomuns. Por outro lado, pessoas com pontuações muito baixas nesta escala não se impressionam com a maioria das obras de arte, sentem pouca curiosidade intelectual, evitam buscas criativas e sentem pouca atração por ideias que podem parecer radicais ou não convencionais.',
          agreeableness:
            'Pessoas com pontuações muito altas na escala de Afabilidade perdoam os erros que sofreram, são tolerantes ao julgar os outros, estão dispostas a transigir e cooperar com os outros e podem facilmente controlar seu temperamento. Por outro lado, pessoas com pontuações muito baixas nesta escala guardam rancor daqueles que as prejudicaram, são bastante críticas das deficiências dos outros, são teimosas em defender seu ponto de vista e sentem raiva prontamente em resposta a maus-tratos.',
          extroversion:
            'Pessoas com pontuações muito altas na escala de Extroversão se sentem positivamente sobre si mesmas, se sentem confiantes ao liderar ou se dirigir a grupos de pessoas, gostam de encontros sociais e interações e experimentam sentimentos positivos de entusiasmo e energia. Por outro lado, as pessoas com pontuações muito baixas nesta escala consideram-se impopulares, sentem-se constrangidas quando são o centro da atenção social, são indiferentes às atividades sociais e sentem-se menos animadas e otimistas do que os outros.',
          conscientiousness:
            'Pessoas com pontuações muito altas na escala de Conscienciosidade organizam seu tempo e seu ambiente físico, trabalham de forma disciplinada em direção a seus objetivos, buscam precisão e perfeição em suas tarefas e deliberam cuidadosamente ao tomar decisões. Por outro lado, pessoas com pontuações muito baixas nesta escala tendem a não se preocupar com ambientes ou horários organizados, evitar tarefas difíceis ou objetivos desafiadores, ficar satisfeitas com o trabalho que contém alguns erros e tomar decisões por impulso ou com pouca reflexão.',
          emotionality:
            'Pessoas com pontuações muito altas na escala de Emotividade experimentam medo de perigos físicos, experimentam ansiedade em resposta ao estresse da vida, sentem necessidade de apoio emocional de outras pessoas e sentem empatia e ligações sentimentais com outras pessoas. Por outro lado, pessoas com pontuações muito baixas nesta escala não se intimidam com a perspectiva de dano físico, sentem-se pouco preocupadas mesmo em situações estressantes, têm pouca necessidade de compartilhar suas preocupações com os outros e sentem-se emocionalmente distantes dos outros.',
          honestyHumility:
            'Pessoas com pontuações muito altas na escala Honestidade-Humildade evitam manipular os outros para ganho pessoal, sentem pouca tentação de quebrar regras, não se interessam por riquezas e luxos pródigos e não sentem nenhum direito especial a um status social elevado. Por outro lado, pessoas com pontuações muito baixas nesta escala lisonjeiam os outros para conseguirem o que desejam, são inclinadas a quebrar as regras para lucro pessoal, são motivadas por ganhos materiais e têm um forte senso de auto-importância.'
        },
        sub: {
          flexibility: {
            name: 'Flexibilidade',
            description:
              'A escala de Flexibilidade avalia a disposição de alguém em se comprometer e cooperar com os outros. Pessoas com pontuação baixa são vistas como teimosas e estão dispostas a discutir, enquanto aquelas com pontuação alta evitam discussões e acatam as sugestões dos outros, mesmo quando estas não forem razoáveis.'
          },
          forgiveness: {
            name: 'Piedade',
            description:
              'A escala da Piedade avalia a disposição de alguém de sentir confiança e simpatia por aqueles que podem ter causado um dano. Pessoas com resultado baixo tendem a "guardar rancor" daqueles que os ofenderam, enquanto aqueles com resultado alto geralmente estão prontos para confiar nos outros novamente e para restabelecer relações amigáveis ​​depois de terem sido maltratados.'
          },
          gentleness: {
            name: 'Gentileza',
            description:
              'A escala de Gentileza avalia a tendência de ser brando e leniente no trato com outras pessoas. Indivíduos com pontuação baixa tendem a ser críticos em suas avaliações dos outros, enquanto aqueles com pontuação alta relutam em julgar os outros com severidade.'
          },
          patience: {
            name: 'Paciência',
            description:
              'A escala de Paciência avalia a tendência de permanecer calmo em vez de ficar com raiva. Pessoas com pontuação baixa tendem a perder a paciência rapidamente, enquanto pessoas com pontuação alta têm um alto limiar para sentir ou expressar raiva.'
          },
          diligence: {
            name: 'Diligência',
            description:
              'A escala Diligência avalia a tendência de trabalhar duro. Os pontuadores baixos têm pouca autodisciplina e não são fortemente motivados para alcançar resultados, ao passo que os pontuadores altos têm uma forte "ética de trabalho" e estão dispostos a se esforçar.'
          },
          organization: {
            name: 'Organização',
            description:
              'A escala Organização avalia a tendência de buscar ordem, principalmente no ambiente físico. Pessoas com pontuação baixa tendem a ser desleixadas e desordenadas, enquanto as com pontuação alta mantêm as coisas organizadas e preferem uma abordagem estruturada para as tarefas.'
          },
          perfectionism: {
            name: 'Perfeccionismo',
            description:
              'A escala de Perfeccionismo avalia a tendência de ser minucioso e preocupado com os detalhes. Pessoas com pontuação baixa toleram alguns erros em seu trabalho e tendem a negligenciar os detalhes, enquanto pessoas com pontuação alta verificam cuidadosamente os erros e melhorias potenciais.'
          },
          prudence: {
            name: 'Prudência',
            description:
              'A escala de Prudência avalia a tendência de deliberar cuidadosamente e inibir impulsos. Pessoas com pontuação baixa agem por impulso e tendem a não considerar as consequências, enquanto pessoas com pontuação alta consideram suas opções com cuidado e tendem a ser cautelosos e auto controlados.'
          },
          anxiety: {
            name: 'Ansiedade',
            description:
              'A escala de Ansiedade avalia a tendência de se preocupar em uma variedade de contextos. Pessoas com pontuação baixa sentem pouco estresse em resposta às dificuldades, enquanto pessoas com pontuação alta tendem a se preocupar mesmo com problemas relativamente menores.'
          },
          dependence: {
            name: 'Dependência',
            description:
              'A escala de Dependência avalia a necessidade de apoio emocional de outras pessoas. Pessoas com resultado baixo se sentem autoconfiantes e capazes de lidar com problemas sem qualquer ajuda ou conselho, enquanto aqueles com resultado alto desejam compartilhar suas dificuldades com aqueles que lhe darão encorajamento e conforto.'
          },
          fearfulness: {
            name: 'Medo',
            description:
              'A escala de Medo avalia a tendência de sentir medo. Pessoas com pontuação baixa sentem pouco medo de lesões e são relativamente fortes, corajosos e insensíveis à dor física, enquanto pessoas com pontuação alta são fortemente inclinadas a evitar lesões físicas.'
          },
          sentimentality: {
            name: 'Sentimentalismo',
            description:
              'A escala de Sentimentalismo avalia a tendência de sentir fortes laços emocionais com outras pessoas. Pessoas com pontuação baixa sentem pouca emoção ao se despedir ou em reação às preocupações dos outros, enquanto as pessoas com pontuação alta sentem fortes ligações emocionais e uma sensibilidade empática aos sentimentos dos outros.'
          },
          liveliness: {
            name: 'Vivacidade',
            description:
              'A escala de Vivacidade avalia o entusiasmo e a energia típicos de uma pessoa. Pessoas com pontuação baixa tendem a não se sentir especialmente alegres ou dinâmicas, enquanto aquelas com pontuação alta geralmente experimentam uma sensação de otimismo e alto astral.'
          },
          sociability: {
            name: 'Sociabilidade',
            description:
              'A escala de Sociabilidade avalia a tendência de gostar de conversas, interação social e festas. Pessoas com pontuação baixa geralmente preferem atividades solitárias e não procuram conversa, enquanto pessoas com pontuação alta gostam de conversar, visitar e comemorar com outras pessoas.'
          },
          socialBoldness: {
            name: 'Ousadia Social',
            description:
              'A escala de Ousadia Social avalia o conforto ou a confiança de uma pessoa em uma variedade de situações sociais. Pessoas com pontuação baixa se sentem tímidas ou constrangidas em posições de liderança ou quando falam em público, enquanto pessoas com pontuação alta estão dispostas a abordar estranhos e falar abertamente em ambientes de grupo.'
          },
          socialSelfEsteem: {
            name: 'Autoestima Social',
            description:
              'A escala de Autoestima Social avalia a tendência de ter uma autoestima positiva, principalmente em contextos sociais. Pessoas com pontuação alta geralmente estão satisfeitas consigo mesmas e consideram-se possuidoras de qualidades agradáveis, enquanto aquelas com pontuação baixa tendem a ter um senso de inutilidade pessoal e a se ver como impopulares.'
          },
          fairness: {
            name: 'Justiça',
            description:
              'A escala de Justiça avalia a tendência de evitar fraude e corrupção. Pessoas com pontuação baixa estão dispostas a ganhar trapaceando ou roubando, enquanto aquelas com pontuação alta não querem tirar vantagem de outros indivíduos ou da sociedade em geral.'
          },
          greedAvoidance: {
            name: 'Aversão à Ganância',
            description:
              'A escala Aversão à Ganância avalia a tendência de não ter interesse em possuir riqueza pródiga, bens de luxo e sinais de status social elevado. Pessoas com resultado baixo desejam desfrutar e exibir riqueza e privilégios, enquanto aqueles com resultado alto não são especialmente motivados por considerações monetárias ou de status social.'
          },
          modesty: {
            name: 'Modéstia',
            description:
              'A escala Modéstia avalia uma tendência a ser modesto e despretensioso. Pessoas com resultado baixo se consideram superiores e com direito a privilégios que os outros não têm, enquanto aqueles com resultado alto se consideram pessoas comuns, sem qualquer direito a tratamento especial.'
          },
          sincerity: {
            name: 'Sinceridade',
            description:
              'A escala de Sinceridade avalia a tendência de ser genuíno nas relações interpessoais. Pessoas com resultado baixo lisonjeiam os outros ou fingem gostar deles para obter favores, enquanto aqueles com resultado alto não querem manipular os outros.'
          },
          aestheticAppreciation: {
            name: 'Apreciação Estética',
            description:
              'A escala de Apreciação Estética avalia o prazer da beleza na arte e na natureza. Pessoas com pontuação baixa tendem a não se deixar absorver por obras de arte ou maravilhas naturais, enquanto pessoas com pontuação alta têm um forte apreço por várias formas de arte e maravilhas naturais.'
          },
          creativity: {
            name: 'Criatividade',
            description:
              'A escala de Criatividade avalia a preferência de uma pessoa por inovação e experimento. Pessoas com pontuação baixa têm pouca inclinação para o pensamento original, enquanto pessoas com pontuação alta buscam ativamente novas soluções para os problemas e se expressam na arte.'
          },
          inquisitiveness: {
            name: 'Curiosidade',
            description:
              'A escala de Curiosidade avalia a tendência de buscar informações e experiências com o mundo natural e humano. Pessoas com pontuação baixa têm pouca curiosidade sobre as ciências naturais ou sociais, enquanto pessoas com pontuação alta leem muito e se interessam por viagens.'
          },
          unconventionality: {
            name: 'Não convencional',
            description:
              'A escala de Não Convencional avalia a tendência de aceitar o incomum. Pessoas com pontuação baixa evitam pessoas excêntricas ou inconformadas, enquanto pessoas com pontuação alta são receptivas a ideias que podem parecer estranhas ou radicais.'
          }
        }
      }
    }
  }
}
