import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

interface IToggle {
  visible: boolean
}

export const Nav = styled.nav`
  display: flex;

  width: 100%;
  height: 50px;

  background: #1b1c1d;
  border-bottom: 4px solid #1b1c1d;

  user-select: none;

  z-index: 10;
`

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;

  img {
    width: 100%;
    max-width: 70px;
  }
`

export const Bars = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 50px;

  background: #1b1c1d;
  padding: 0 1rem;

  cursor: pointer;

  p {
    color: #fafafa;
    font-size: 1rem;
    margin-right: 1rem;
  }

  &:hover {
    filter: brightness(0.8);
  }
`

export const NavMenu = styled.div<IToggle>`
  position: absolute;
  top: 0;
  right: 0;
  display: ${(IToggle) => (IToggle.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  width: max-content;
  height: max-content;

  background: #1b1c1d;
  border: 1px solid #f7f9fa;
  box-shadow: 1px 1px 6px #696969;
  border-radius: 4px;
  margin-top: 3rem;
  margin-right: 1rem;
  z-index: 1000;

  .flags {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    padding: 0.5rem 0;
    border-bottom: 1px solid #fff;
  }

  .mail {
    display: flex;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-bottom: 1px solid #fff;
  }

  button {
    display: flex;

    margin: 0.5rem 0;
    border: none;
    background: transparent;
    color: #f5f5f5;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1rem;

    &:hover {
      filter: brightness(0.8);
      color: red;
    }
  }

  svg {
    margin-right: 0.5rem;
  }

  p {
    color: #fafafa;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
    padding: 0;
  }
`

export const NavLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  margin: 0.25rem 0;

  color: #fafafa;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 4px solid transparent;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    filter: brightness(0.8);
  }
`

export const Orgs = styled.select`
  position: absolute;
  top: 0.7rem;
  right: 47%;

  font-family: 'Roboto';
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;

  &:focus-visible {
    border: none;
    outline: none;
  }
`
