import { useRef, useCallback } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { toast, TypeOptions } from 'react-toastify'
import { i18n } from '../../translate/i18n'

import { RequestManager } from 'src/security/RequestManager'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { Input } from '../../components/Form/Input'
import { Container, Content } from './styles'

export const User = () => {
  const formRef = useRef<FormHandles>(null)

  const notify = useCallback(
    (text: string, type: TypeOptions, autoClose: number) => {
      toast(text, {
        type,
        autoClose,
        onClose: () => {}
      })
    },
    []
  )

  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})

      const schema = Yup.object().shape({
        currentPassword: Yup.string().required(
          i18n.t('pages.user.typeError.pwd')
        ),
        password: Yup.string().required(i18n.t('pages.user.typeError.pwd')),
        confirmPassword: Yup.string().when('password', (password, field) =>
          password
            ? field
                .required(i18n.t('pages.user.typeError.pwd'))
                .oneOf([Yup.ref('password')])
            : field
        )
      })

      await schema.validate(data, {
        abortEarly: false
      })

      RequestManager.requestAPI('/change-password', {
        oldPassword: data.currentPassword,
        newPassword: data.password
      })
        .then(() => {
          notify(i18n.t('pages.user.message.success'), 'success', 2000)
          formRef.current?.reset()
          location.reload()
        })
        .catch((err) => {
          notify(err, 'error', 2000)
        })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current?.setErrors(errors)
      } else notify(i18n.t('pages.user.messages.error'), 'warning', 2000)
    }
  }, [])

  return (
    <Container>
      <Content>
        <h1>{i18n.t('pages.user.title')}</h1>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <div className="partition">
            <Input
              type="password"
              name="currentPassword"
              label={i18n.t('pages.user.fields.currentPwd')}
              isRequired={true}
            />
            <Input
              type="password"
              name="password"
              label={i18n.t('pages.user.fields.pwd')}
              isRequired={true}
            />
            <Input
              type="password"
              name="confirmPassword"
              label={i18n.t('pages.user.fields.confirmPwd')}
              isRequired={true}
            />
          </div>
          <div className="btn">
            <input
              type="submit"
              value={String(i18n.t('pages.user.buttons.save'))}
            />
          </div>
        </Form>
      </Content>
    </Container>
  )
}
