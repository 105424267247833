import styled from 'styled-components'
interface IProps {
  toggle: boolean
}

export const Container = styled.div<IProps>`
  border-top: 1px solid #ddd;
  padding: 0.5rem 0;

  .filter-title {
    font-weight: bold;
    font-size: 0.9rem;
    cursor: pointer;
  }

  .filter-options {
    display: ${(props) => (props.toggle ? 'flex' : 'none')};
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem 1rem;

    span {
      padding-left: 0.5rem;
      font-size: 0.9rem;
      text-transform: capitalize;

      .qtd {
        padding: 0;
        color: #aaa;
        font-size: 0.8rem;
      }
    }
  }
`
