import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface IProps {
  color?: string
  width?: string
  active: boolean
}

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 89vh;
  color: #4b5c6b;
  padding: 1rem;
  user-select: none;

  .clear {
    flex-basis: 100%;
    height: 0;
  }

  .adjust {
    margin-top: -12rem;
  }
`

export const Layer = styled.div<IProps>`
  position: relative;
  display: flex;
  width: ${(props) => props.width};
  height: 10rem;
  clip-path: polygon(0% 0%, 100% 0%, 94% 100%, 6% 100%);
  margin-bottom: 1rem;
  opacity: ${(props) => (props.active ? 1 : 0.6)};

  @media screen and (max-width: 768px) {
    height: 8rem;
  }

  .start {
    position: absolute;
    bottom: 0;
    left: 6.5%;
    color: #fff;
    z-index: 100;
    font-size: 0.7rem;

    span {
      color: #fff;
      font-size: 0.6rem;
    }
  }

  .end {
    position: absolute;
    bottom: 0;
    right: 6.5%;
    color: #3f4348;
    font-size: 0.7rem;

    span {
      color: #3f4348;
      font-size: 0.6rem;
    }
  }
`

export const Content = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  background: #3f4348;

  .title {
    color: #99cc20;
    padding-top: 0.5rem;
    padding-left: 2rem;
    text-transform: capitalize;
    font-family: 'GothamLight';
    font-size: 1.8rem;
  }

  .conclude {
    position: absolute;
    bottom: 1rem;
    right: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #fff;
    font-size: 2.5rem;
    font-family: 'GothamBold', sans-serif;

    a {
      color: #fff;
    }

    .border {
      width: 1px;
      height: 4rem;
      border-left: 1px dotted #646a85;
      margin-bottom: 1rem;
    }
  }

  .not-conclude {
    position: absolute;
    bottom: 1rem;
    left: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #646a85;
    font-family: 'GothamMedium', sans-serif;
    font-size: 1.3rem;

    a {
      color: #646a85;
    }

    svg {
      @-webkit-keyframes rotation {
        0%,
        40% {
          -webkit-transform: rotate(0deg);
        }
        60%,
        100% {
          -webkit-transform: rotate(180deg);
        }
      }
      @keyframes rotation {
        0%,
        40% {
          transform: rotate(0deg);
        }
        60%,
        100% {
          transform: rotate(180deg);
        }
      }
      -webkit-animation: rotation 6s infinite linear;
      animation: rotation 6s infinite linear;
    }
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 1rem;
    }

    .conclude {
      font-size: 1rem;
    }

    .not-conclude {
      left: 3rem;
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 1400px) {
    .not-conclude {
      left: 6rem;
      font-size: 1rem;
    }
  }
`

export const Complement = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  background: #fff;

  @media screen and (max-width: 768px) {
    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`

export const Approve = styled.div<IProps>`
  position: relative;
  display: flex;
  width: max-content;
  height: 3rem;
  min-width: 11rem;

  opacity: ${(props) => (props.active ? 1 : 0.6)};

  margin: 0.5rem;

  button {
    display: flex;
    align-items: center;
    padding-right: 1.2rem;

    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);

    font-family: 'GothamMedium', sans-serif;
    background: #99cc20;
    border: 1px solid #99cc20;
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    cursor: auto;
    color: #fff;

    a {
      display: flex;
      align-items: center;
      color: #fff;
    }
  }

  .sub {
    position: absolute;
    top: -0.8rem;
    right: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;

    color: #99cc20;
    border: 2px solid #99cc20;
    border-radius: 50%;
    background-color: #ffffff;
    font-size: 0.7rem;
    font-family: 'GothamMedium', sans-serif;
    z-index: 200;
  }

  .numberArrow {
    margin-left: 0.5rem;
    padding: 0.3rem 0.6rem;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    color: #fff;
    font-family: 'GothamMedium', sans-serif;
  }

  @media screen and (max-width: 768px) {
    min-width: 9.3rem;

    button {
      font-size: 0.8rem;
    }

    .sub {
      display: none;
    }

    .numberArrow {
      padding: 0.3rem;
    }
  }
`

export const Arrow = styled.div<IProps>`
  position: relative;
  display: flex;
  width: max-content;
  height: 3rem;
  min-width: 11rem;

  opacity: ${(props) => (props.active ? 1 : 0.6)};

  margin: 0.5rem;

  button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    font-family: 'GothamMedium', sans-serif;
    padding-right: 1.2rem;
    background: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};

    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);

    font-size: 0.8rem;
    cursor: auto;

    a {
      display: flex;
      align-items: center;
      color: #fff;
    }
  }

  .sub {
    position: absolute;
    top: -0.8rem;
    right: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;

    color: ${(props) => props.color};
    border: 2px solid ${(props) => props.color};
    border-radius: 50%;
    background-color: #ffffff;
    font-size: 0.7rem;
    font-family: 'GothamMedium', sans-serif;
    letter-spacing: 0;
  }

  .numberArrow {
    margin-left: 0.5rem;
    padding: 0.3rem 0.6rem;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    color: #fff;
    font-family: 'GothamMedium', sans-serif;
  }

  @media screen and (max-width: 768px) {
    min-width: 9.3rem;

    button {
      font-size: 0.8rem;
    }

    .sub {
      display: none;
    }

    .numberArrow {
      padding: 0.3rem;
    }
  }
`

export const Table = styled.table`
  margin: 1rem;
  font-family: 'GothamLight', sans-serif;
`

export const Circle = styled(Link)`
  background: #eee;
  border-radius: 50%;
  padding: 0.3rem;
  margin: 0 0.3rem;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
