import { createContext } from 'react'
import { FieldsOfFilter } from '../pages/Subscribers'

interface ICreateFilterContext {
  filteredField(
    field: FieldsOfFilter,
    option: string,
    action: 'add' | 'remove'
  ): void
}

export const CreateFilterContext = createContext<ICreateFilterContext>({
  filteredField: () => {}
})
