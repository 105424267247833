import { ReactNode } from 'react'

import { Container } from './styles'

interface IProps {
  children: ReactNode
  index: number
  haveSkill: boolean
}

export const CardCandidate = ({ children, index, haveSkill }: IProps) => {
  return (
    <Container role="Box" haveSkill={haveSkill}>
      {children}
    </Container>
  )
}
