export const messages = {
  en: {
    translations: {
      nav: {
        pages: {
          program: 'Programs',
          logout: 'Logout',
          candidate: 'Candidates',
          schedule: 'Schedule',
          project: 'Projects',
          hired: 'Hired'
        },
        flags: {
          pt: 'Portuguese',
          en: 'English',
          es: 'Spanish'
        }
      },
      breadCrumbs: {
        label: {
          program: 'Program',
          funnel: 'Funnel',
          rank: 'Rank',
          subscribers: 'Subscribers',
          project: 'Projects'
        }
      },
      pages: {
        user: {
          title: 'Change password',
          fields: {
            pwd: 'New password',
            confirmPwd: 'Repeat password',
            currentPwd: 'Current password'
          },
          typeError: {
            pwd: 'Enter a new password',
            confirmPwd: 'Password does not match new password',
            currentPwd: 'Enter current password'
          },
          messages: {
            success: 'Password changed successfully',
            error: 'Unable to change password contact support'
          },
          buttons: {
            save: 'Save'
          }
        },
        programs: {
          titles: {
            app: 'Create new program',
            close: 'Closed'
          },
          messages: {
            create: 'Created',
            errorProgramCreate: 'Error creating program!',
            successProgramCreate: 'Program created successfully!'
          },
          labels: {
            add: 'Add',
            program: 'Program',
            profile: 'Profile',
            externalId: 'ExternalId',
            target: 'Target',
            start: 'Starts',
            end: 'Finishes',
            view: 'To View',
            edit: 'To Edit',
            subscribers: 'Subscribers',
            finish: 'Finish'
          },
          buttons: {
            create: 'Create'
          },
          tooltips: {
            add: 'Add New Program',
            view: 'View Program',
            edit: 'Edit Program',
            subscribers: 'Approve subscribers',
            finish: 'Finish Program'
          },
          typeError: {
            title: 'Required program name!',
            externalId: 'Required externalId name!',
            profile: 'Required profile name!',
            target: 'Number of vacancies required!',
            targetMin: 'Min 1',
            startDate: 'Start Date Required!',
            endDate: 'End Date Required!'
          }
        },
        profiles: {
          buttons: {
            select: 'Choose'
          },
          profile: {
            buttons: {
              create: 'Create Program'
            }
          }
        },
        funnel: {
          messages: {
            subscription: 'Subscriptions',
            approved: 'Approved',
            test: 'Test',
            interview: 'Interview',
            assessment: 'Assessment',
            start: 'start',
            end: 'end'
          },
          status: {
            concluded: 'Concluded',
            pending: 'Pending'
          }
        },
        academic: {
          titles: {
            personality: 'Personality',
            journey: 'Journey'
          },
          messages: {
            points: 'points',
            viewMore: 'more',
            viewLess: 'less',
            approval: 'Approval',
            disapproval: 'Disapproval',
            action: 'Do you want to continue with the action of',
            alert:
              'Attention once the action is confirmed, it will not be possible to reverse it.',
            success: 'Change completed successfully!',
            error: 'Unable to complete action!',
            skillsWarn: 'Do you want to generate skills for this candidate?',
            recruiterWarn: "Do you want to change the candidate's status?",
            noChanged: 'Unable to change program, contact support!',
            successChanged: 'Unable to change program, contact support!',
            successSkills: 'Skills generated successfully!',
            successStatus: 'Status changed successfully!'
          },
          questions: {
            phone: 'Phone',
            email: 'Email',
            linkedIn: 'LinkedIn',
            trailHead: 'Profile Trailhead',
            taxId: 'Tax id',
            birthDate: 'Birth Date',
            gender: 'Gender',
            englishLevel: 'English Level',
            currentEmployment: 'Are you currently working?',
            educationalLevel: 'Schooling',
            developmentExperience: 'Development experience?',
            professionalExperience: 'Professional experience'
          },
          select: {
            options: {
              change: 'Change Status',
              interview: 'Interviews',
              hired: 'Hired',
              rejected: 'Rejected'
            }
          },
          buttons: {
            challenge: 'Send Challenge',
            approve: 'Approve',
            fail: 'Fail',
            save: 'Save',
            yes: 'Yes',
            no: 'No',
            notes: 'Comments',
            changeProgram: 'Change Program',
            generateSkill: 'Generate Skills',
            summary: 'Summary',
            detail: 'Detail',
            whatProgram: 'What Program?',
            sendEmail:
              'Do you want to notify the candidate by email of the change?'
          },
          table: {
            journey: 'Journey',
            trail: 'Trail',
            points: 'Points',
            accuracy: 'Accuracy',
            duration: 'Duration'
          },
          fields: {
            required: 'Required field'
          }
        },
        rank: {
          titles: {
            ranking: 'Ranking Position',
            sort: 'Sort by',
            filter: 'Filter by',
            finish: 'End program'
          },
          messages: {
            filter: 'Filter by',
            search: 'Your search returned [?] entries',
            day: 'days',
            points: 'Points',
            searchBy: 'Search by email or name',
            notFound: 'There are no subscribers for these filters.',
            attention: 'Attention outdated Rank',
            attentionMessage:
              'There is a new rank change click on the button below to refresh the page.',
            finishProgram: 'Do you want to terminate the program [?]?',
            warnFinishProgram:
              'This action is irreversible and may affect some system features such as candidate approval, registration, etc.'
          },
          buttons: {
            save: 'Save',
            reset: 'Reset',
            load: 'Load more...',
            close: 'Confirm',
            cancel: 'Cancel'
          }
        },
        subscribers: {
          messages: {
            search: 'Your search returned [?] entries',
            notSubscribers: 'There are no subscribers for this program yet.',
            notFound: 'There are no subscribers for these filters.',
            filterBy: 'Filter by',
            searchBy: 'Search by email or name'
          },
          cardFilters: {
            english: 'English: ',
            schooling: 'Schooling: ',
            region: 'Region: ',
            working: 'Working: ',
            programing: 'Programing: ',
            salesforce: 'Salesforce: '
          },
          buttons: {
            load: 'Load more...'
          },
          tooltips: {
            approve: 'Approved',
            fail: 'Disapproved',
            pending: 'Pending'
          }
        },
        subscriber: {
          fields: {
            student: 'Candidate',
            taxId: 'Tax Id',
            email: 'Email',
            phone: 'Phone',
            gender: 'Gender',
            region: 'Region',
            city: 'City',
            birthDate: 'Birth Date',
            program: 'Program',
            ethnicity: 'Ethnicity',
            withDisability: 'Do you have a disability?',
            disabilityDescription: 'What?',
            educationalLevel: 'Schooling',
            course: 'Course',
            university: 'University',
            currentSemester: 'Current semester',
            graduationYear: 'Expected year of graduation',
            englishLevel: 'English level',
            lastSignOnInDays: 'Last Time Connected',
            mentor: 'Mentor',
            linkedin: 'LinkedIn',
            currentEmployment: 'Is working?',
            developmentExperience: 'Development experience',
            industryExperience: 'Industry experience',
            salesforceExperience: 'Salesforce experience',
            trailheadProfile: 'Trailhead profile',
            recommendedBy: 'Recommended By',
            timeAvailability: 'Do you have availability of 16 hours a week?',
            haveComputer: 'Do you have a computer with internet?',
            professionalExperience: 'Professional experience',
            whySubscribed: 'Why did you sign up?',
            creationDate: 'Creation Date',
            notes: 'Notes',
            approveOrFail: 'Approve or Fail'
          },
          buttons: {
            pending: 'Pending approval',
            approve: 'Approve',
            fail: 'Fail',
            save: 'Save'
          },
          messages: {
            noNotes: 'No annotations have been added for this candidate!',
            errorTitle: 'Error approving user',
            errorMessage: 'Contact your system administrator for the fix!',
            codeMessage: 'Error',
            success: 'Change completed successfully!',
            error: 'Unable to complete action!',
            days: 'Days',
            day: 'Day',
            today: 'Today'
          },
          gender: {
            man: 'Male',
            woman: 'Female',
            other: 'Other'
          }
        },
        notFound: {
          messages: {
            warning:
              'Authentication required you will be redirected to the login page in a moment.'
          }
        },
        interview: {
          titles: {
            questions: 'Questions'
          }
        },
        admin: {
          candidates: {
            messages: {
              placeholder: 'Type a name or an email',
              notFound: 'No results were found for this search.',
              found: 'Your search found [*] results.'
            }
          }
        },
        project: {
          titles: {
            project: 'Project'
          },
          fields: {
            labels: {
              profile: 'Profile',
              projectTitle: 'Project Title',
              location: 'Location',
              workModel: 'Work Model',
              target: 'Target',
              message: 'Message',
              start: 'Start',
              end: 'End',
              create: 'Create project'
            },
            placeholders: {
              location: 'Albany, NY',
              message:
                'This message will be sent by default to the selected professional.'
            },
            options: {
              workModel: {
                remote: 'Remote',
                hybrid: 'Hybrid',
                presential: 'Presential'
              }
            }
          },
          messages: {
            success: 'Project created successfully',
            warning: 'Warning'
          },
          buttons: {
            create: 'Create'
          }
        },
        pool: {
          titles: {
            contact: 'Contact',
            sort: 'Sort by',
            filter: 'Filter by'
          },
          select: {
            all: 'All',
            selected: 'Selected',
            invited: 'Invited',
            scheduled: 'Scheduled',
            interviewee: 'Interviewee',
            hired: 'Hired',
            declined: 'Declined',
            released: 'Released'
          },
          fields: {
            labels: {
              jobTitle: 'Job Title',
              workModel: 'Work Model',
              location: 'Location',
              message: 'Message'
            },
            options: {
              workModel: {
                remote: 'Remote',
                hybrid: 'Hybrid',
                presential: 'Presential'
              }
            },
            required: {
              jobTitle: 'The field "Job Title" is required!',
              workModel: 'The field "Work Model" is required!',
              location: 'The field "Location" is required!',
              message: 'The field "Message" is required!'
            }
          },
          buttons: {
            send: 'Send',
            save: 'Save',
            reset: 'Reset'
          },
          messages: {
            filter: 'Filter by',
            points: 'Points',
            notFound: 'There are no professionals for these filters.',
            attention: 'Attention outdated Rank',
            attentionMessage:
              'There is a new rank change click on the button below to refresh the page.',
            updateAt: 'update to [?] ago'
          },
          notify: {
            sentEmail: 'Email sent!',
            notSentEmail: 'Unable to send Email, contact the ADMIN!'
          },
          tooltip: {
            video: 'Video',
            remote: 'Remote',
            presential: 'Presential',
            contractMode: 'PJ',
            invite: 'Invite'
          }
        },
        poolFunnel: {
          favorites: 'Favorites',
          invited: 'Invited',
          inProgress: 'In Progress',
          interviews: 'Interviews',
          hired: 'Hired'
        },
        recruiter: {
          filters: {
            project: 'Project',
            startDate: 'Start',
            endDate: 'End'
          },
          labels: {
            all: 'All',
            project: 'Project',
            name: 'Name',
            email: 'Email',
            recruiter: 'Recruiter',
            hiredDate: 'Hired',
            total: 'Total'
          }
        }
      },
      filters: {
        fields: {
          salesforceExperience: 'Do you have experience with Salesforce?',
          developmentExperience: 'Do you have development experience?',
          englishLevel: 'English level',
          educationalLevel: 'Schooling',
          region: 'Region',
          gender: 'Gender',
          currentEmployment: 'Are you currently working?',
          ethnicity: 'Ethnicity',
          withDisability: 'Do you have a disability?'
        },
        phaseName: {
          subscription: 'Subscription',
          discovery: 'Discovery',
          ignite: 'Ignite',
          accelerate: 'Accelerate'
        },
        stepName: {
          training: 'training',
          assessment: 'assessment',
          exam: 'exam',
          agoge: 'agogê',
          interview: 'interview',
          approval: 'approval'
        },
        type: {
          completed: 'completed',
          approved: 'approved',
          pending: 'pending',
          rejected: 'rejected'
        }
      },
      calendar: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List',
        save: 'Save',
        ctrlZ: 'Undo',
        allDay: 'All day',
        available: 'Available',
        messages: {
          title: 'Provide Schedule',
          description:
            'This will make it so that candidates can schedule the interview at the times available!'
        },
        buttons: {
          save: 'Publish Schedule',
          cancel: 'Cancel'
        }
      },
      person: {
        male: 'Male',
        female: 'Female',
        other: 'Other'
      },
      personality: {
        opennessToExperience: 'Openness to experience',
        agreeableness: 'Agreeableness',
        emotionality: 'Emotionality',
        conscientiousness: 'Conscientiousness',
        extroversion: 'Extraversion',
        honestyHumility: 'Honesty/Humility',
        description: {
          opennessToExperience:
            'Persons with very high scores on the Openness to Experience scale become absorbed in the beauty of art and nature, are inquisitive about various domains of knowledge, use their imagination freely in everyday life, and take an interest in unusual ideas or people. Conversely, persons with very low scores on this scale are rather unimpressed by most works of art, feel little intellectual curiosity, avoid creative pursuits, and feel little attraction toward ideas that may seem radical or unconventional.',
          agreeableness:
            "Persons with very high scores on the Agreeableness scale forgive the wrongs that they suffered, are lenient in judging others, are willing to compromise and cooperate with others, and can easily control their temper. Conversely, persons with very low scores on this scale hold grudges against those who have harmed them, are rather critical of others' shortcomings, are stubborn in defending their point of view, and feel anger readily in response to mistreatment.",
          extroversion:
            'Persons with very high scores on the Extraversion scale feel positively about themselves, feel confident when leading or addressing groups of people, enjoy social gatherings and interactions, and experience positive feelings of enthusiasm and energy. Conversely, persons with very low scores on this scale consider themselves unpopular, feel awkward when they are the center of social attention, are indifferent to social activities, and feel less lively and optimistic than others do.',
          conscientiousness:
            'Persons with very high scores on the Conscientiousness scale organize their time and their physical surroundings, work in a disciplined way toward their goals, strive for accuracy and perfection in their tasks, and deliberate carefully when making decisions. Conversely, persons with very low scores on this scale tend to be unconcerned with orderly surroundings or schedules, avoid difficult tasks or challenging goals, are satisfied with work that contains some errors, and make decisions on impulse or with little reflection.',
          emotionality:
            "Persons with very high scores on the Emotionality scale experience fear of physical dangers, experience anxiety in response to life's stresses, feel a need for emotional support from others, and feel empathy and sentimental attachments with others. Conversely, persons with very low scores on this scale are not deterred by the prospect of physical harm, feel little worry even in stressful situations, have little need to share their concerns with others, and feel emotionally detached from others.",
          honestyHumility:
            'Persons with very high scores on the Honesty-Humility scale avoid manipulating others for personal gain, feel little temptation to break rules, are uninterested in lavish wealth and luxuries, and feel no special entitlement to elevated social status. Conversely, persons with very low scores on this scale will flatter others to get what they want, are inclined to break rules for personal profit, are motivated by material gain, and feel a strong sense of self-importance.'
        },
        sub: {
          flexibility: {
            name: 'Flexibility',
            description:
              "The Flexibility scale assesses one's willingness to compromise and cooperate with others. Low scorers are seen as stubborn and are willing to argue, whereas high scorers avoid arguments and accommodate others' suggestions, even when these may be unreasonable."
          },
          forgiveness: {
            name: 'Forgiveness',
            description:
              "The forgiveness scale assesses one's willingness to feel trust and liking toward those who may have caused one harm. Low scorers tend hold a grudge against those who have offended them, whereas high scorers are usually ready to trust others again and to re-establish friendly relations after having been treated badly."
          },
          gentleness: {
            name: 'Gentleness',
            description:
              'The Gentleness scale assesses a tendency to be mild and lenient in dealings with other people. Low scorers tend to be critical in their evaluations of others, whereas high scorers are reluctant to judge others harshly.'
          },
          patience: {
            name: 'Patience',
            description:
              'The Patience scale assesses a tendency to remain calm rather than to become angry. Low scorers tend to lose their tempers quickly, whereas high scorers have a high threshold for feeling or expressing anger.'
          },
          diligence: {
            name: 'Diligence',
            description:
              'The Diligence scale assesses a tendency to work hard. Low scorers have little self-discipline and are not strongly motivated to achieve, whereas high scorers have a strong "work ethic" and are willing to exert themselves.'
          },
          organization: {
            name: 'Organization',
            description:
              "The Organization scale assesses a tendency to seek order, particularly in one's physical surroundings. Low scorers tend to be sloppy and haphazard, whereas high scorers keep things tidy and prefer a structured approach to tasks."
          },
          perfectionism: {
            name: 'Perfectionism',
            description:
              'The Perfectionism scale assesses a tendency to be thorough and concerned with details. Low scorers tolerate some errors in their work and tend to neglect details, whereas high scorers check carefully for mistakes and potential improvements.'
          },
          prudence: {
            name: 'Prudence',
            description:
              'The Prudence scale assesses a tendency to deliberate carefully and to inhibit impulses. Low scorers act on impulse and tend not to consider consequences, whereas high scorers consider their options carefully and tend to be cautious and self-controlled.'
          },
          anxiety: {
            name: 'Anxiety',
            description:
              'The Anxiety scale assesses a tendency to worry in a variety of contexts. Low scorers feel little stress in response to difficulties, whereas high scorers tend to become preoccupied even by relatively minor problems.'
          },
          dependence: {
            name: 'Dependence',
            description:
              "The Dependence scale assesses one's need for emotional support from others. Low scorers feel self-assured and able to deal with problems without any help or advice, whereas high scorers want to share their difficulties with those who will provide encouragement and comfort."
          },
          fearfulness: {
            name: 'Fearfulness',
            description:
              'The Fearfulness scale assesses a tendency to experience fear. Low scorers feel little fear of injury and are relatively tough, brave, and insensitive to physical pain, whereas high scorers are strongly inclined to avoid physical harm.'
          },
          sentimentality: {
            name: 'Sentimentality',
            description:
              'The Sentimentality scale assesses a tendency to feel strong emotional bonds with others. Low scorers feel little emotion when saying good-bye or in reaction to the concerns of others, whereas high scorers feel strong emotional attachments and an empathic sensitivity to the feelings of others.'
          },
          liveliness: {
            name: 'Liveliness',
            description:
              "The Liveliness scale assesses one's typical enthusiasm and energy. Low scorers tend not to feel especially cheerful or dynamic, whereas high scorers usually experience a sense of optimism and high spirits."
          },
          sociability: {
            name: 'Sociability',
            description:
              'The Sentimentality scale assesses a tendency to feel strong emotional bonds with others. Low scorers feel little emotion when saying good-bye or in reaction to the concerns of others, whereas high scorers feel strong emotional attachments and an empathic sensitivity to the feelings of others.'
          },
          socialBoldness: {
            name: 'Social Boldness',
            description:
              "The Social Boldness scale assesses one's comfort or confidence within a variety of social situations. Low scorers feel shy or awkward in positions of leadership or when speaking in public, whereas high scorers are willing to approach strangers and are willing to speak up within group settings."
          },
          socialSelfEsteem: {
            name: 'Social Self-Esteem',
            description:
              'The Social Self-Esteem scale assesses a tendency to have positive self-regard, particularly in social contexts. High scorers are generally satisfied with themselves and consider themselves to have likable qualities, whereas low scorers tend to have a sense of personal worthlessness and to see themselves as unpopular.'
          },
          fairness: {
            name: 'Fairness',
            description:
              'The Fairness scale assesses a tendency to avoid fraud and corruption. Low scorers are willing to gain by cheating or stealing, whereas high scorers are unwilling to take advantage of other individuals or of society at large.'
          },
          greedAvoidance: {
            name: 'Greed Avoidance',
            description:
              'The Greed Avoidance scale assesses a tendency to be uninterested in possessing lavish wealth, luxury goods, and signs of high social status. Low scorers want to enjoy and to display wealth and privilege, whereas high scorers are not especially motivated by monetary or social-status considerations.'
          },
          modesty: {
            name: 'Modesty',
            description:
              'The Modesty scale assesses a tendency to be modest and unassuming. Low scorers consider themselves as superior and as entitled to privileges that others do not have, whereas high scorers view themselves as ordinary people without any claim to special treatment.'
          },
          sincerity: {
            name: 'Sincerity',
            description:
              'The Sincerity scale assesses a tendency to be genuine in interpersonal relations. Low scorers will flatter others or pretend to like them in order to obtain favors, whereas high scorers are unwilling to manipulate others.'
          },
          aestheticAppreciation: {
            name: 'Aesthetic Appreciation',
            description:
              "The Aesthetic Appreciation scale assesses one's enjoyment of beauty in art and in nature. Low scorers tend not to become absorbed in works of art or in natural wonders, whereas high scorers have a strong appreciation of various art forms and of natural wonders."
          },
          creativity: {
            name: 'Creativity',
            description:
              "The Creativity scale assesses one's preference for innovation and experiment. Low scorers have little inclination for original thought, whereas high scorers actively seek new solutions to problems and express themselves in art."
          },
          inquisitiveness: {
            name: 'Inquisitiveness',
            description:
              'The Inquisitiveness scale assesses a tendency to seek information about, and experience with, the natural and human world. Low scorers have little curiosity about the natural or social sciences, whereas high scorers read widely and are interested in travel.'
          },
          unconventionality: {
            name: 'Unconventionality',
            description:
              'The Unconventionality scale assesses a tendency to accept the unusual. Low scorers avoid eccentric or nonconforming persons, whereas high scorers are receptive to ideas that might seem strange or radical.'
          }
        }
      }
    }
  }
}
