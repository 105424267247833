import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  height: max-content;
  margin: 30px 0;
  background: #fff;
  border-radius: 4px;
  padding: 2rem;
  overflow-y: auto;
  overflow-x: hidden;

  label {
    padding: 0.5rem 0.25rem;
    color: #4b5c6b;
    font-size: 1rem;
    font-family: 'Roboto';
  }

  .field-select {
    width: max-content;
    padding: 0.15rem 0.25rem;
    background: #4b5c6b;
    color: #fff;
    border: none;
    border-radius: 0.15rem;
    font-size: 1rem;
    font-family: 'Roboto';
    margin-right: 1rem;

    &:focus {
      outline: none;
    }
  }

  input[type='date'] {
    width: max-content;
    padding: 0.1rem 0.25rem;
    background: #4b5c6b;
    color: #fff;
    border: none;
    border-radius: 0.15rem;
    font-size: 1rem;
    font-family: 'Roboto';
    margin-right: 1rem;

    &:focus {
      outline: none;
    }
    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }
`

export const Table = styled.table`
  width: 100%;
  margin-top: 2rem;
  border-collapse: collapse;
  overflow: auto;

  thead,
  tfoot {
    color: #4b5c6b;
    font-size: 1.2rem;
  }

  th {
    text-align: left;
    padding: 0 0.25rem;
  }

  tbody > tr {
    border-top: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;
    color: #111;

    &:hover {
      background: #eee;
    }
  }

  tbody > tr > td {
    padding: 0.5rem 0.25rem;
    font-size: 0.9rem;

    .flex {
      display: flex;
      justify-content: space-between;
      margin-right: 0.5rem;

      svg:hover {
        cursor: pointer;
      }
    }
  }
`
