import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  background: #fff;
  border-bottom: 1px solid #cdcdcd;
  padding: 0.1rem 0;
`

export const Content = styled.ul`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  margin: 0;
  padding-left: 0.4rem;

  li {
    padding-right: 10px;
    list-style: none;

    a {
      text-decoration: none;
      color: #969696;

      &:hover {
        color: #2929ff;
      }
    }
  }
`
