import { MdClose } from 'react-icons/md'

import styled from 'styled-components'

interface IProps {
  toggle: boolean
}

interface IPropsContent {
  width?: string
}

export const Container = styled.div<IProps>`
  background: rgba(0, 0, 0, 0.8);
  display: ${(props: IProps) => (props.toggle ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
`

export const Content = styled.div<IPropsContent>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props: IPropsContent) => (props.width ? props.width : '50%')};
  height: max-content;
  min-height: 10rem;
  margin: 30px 0;
  background: var(--background);
  border-radius: 4px;
  box-shadow: 0 0 5px #000;

  ul {
    cursor: default;
  }
`

export const Close = styled(MdClose)`
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  color: var(--background);
  cursor: pointer;

  &:hover {
    color: var(--red);
  }
`
