import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { AuthProvider } from './contexts/auth'
import { Routes } from './routes/routes'

import { GlobalStyle } from './styles/global'

import GlobalFonts from './assets/fonts/globalFonts'

import { Loading } from './utils/loading'

export const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ToastContainer autoClose={2000} />

        <Loading />
        <Routes />
        <GlobalStyle />
        <GlobalFonts />
      </BrowserRouter>
    </AuthProvider>
  )
}
