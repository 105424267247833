import { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { IconBaseProps } from 'react-icons'
import { FaQuestion } from 'react-icons/fa'

import { Tooltip } from '../Tooltip/index'

import { Container, Alert } from './styles'

interface IProps {
  name: string
  id?: string
  options: {
    value: string
    label: string
  }[]
  label?: string
  errorSec?: string
  isRequired?: boolean
  icon?: React.ComponentType<IconBaseProps>
}

type InputProps = JSX.IntrinsicElements['input'] & IProps

export const Radio = ({
  id,
  name,
  options,
  label,
  isRequired,
  errorSec,
  icon: Icon,
  ...rest
}: InputProps) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, defaultValue, registerField, error } = useField(name)
  const isError =
    (error !== '' && error !== undefined) ||
    (errorSec !== '' && errorSec !== undefined)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (ref) => {
        if (!ref || ref.length < 1) {
          return ''
        }
        const item = ref?.find((item: any) => item.checked)
        if (!item) {
          return ''
        }
        return item?.value
      },
      clearValue: (ref: any) => {
        ref.forEach((item: any) => (item.checked = false))
      }
    })
  }, [fieldName, registerField])

  const addRefItem = (ref: any) => {
    if (!!ref && !inputRefs.current.includes(ref)) {
      inputRefs.current.push(ref)
    }
  }

  return (
    <Container isError={isError}>
      {label && (
        <label htmlFor={id}>
          {label}
          {isRequired && '*'}:{Icon && <Icon size={15} />}
        </label>
      )}
      <div className="options-group">
        <div className="options-inside-group">
          {options?.map((option) => (
            <div key={option.value} className="radio-group">
              <input
                id={id}
                type="radio"
                name={fieldName}
                ref={addRefItem}
                value={option.value}
                defaultValue={defaultValue}
                {...rest}
              />
              <label htmlFor={option.label}>{option.label}</label>
            </div>
          ))}
        </div>

        {error && (
          <Alert>
            <Tooltip title={error} type="danger">
              <FaQuestion color="#E52E4D" size={20} />
              &nbsp;
            </Tooltip>
          </Alert>
        )}

        {errorSec && (
          <Alert>
            <Tooltip title={errorSec}>
              <FaQuestion color="#E52E4D" size={20} />
              &nbsp;
            </Tooltip>
          </Alert>
        )}
      </div>
    </Container>
  )
}
