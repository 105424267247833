import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 31%;
  min-height: 14rem;

  margin: 0.9rem;
  border-radius: 0.55rem;
  border-top: 0.6rem solid #3f4348;
  padding: 0.5rem 1.2rem;
  background: #fff;

  .title {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;

    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .target-title {
    font-weight: 700;
  }

  .target {
    font-weight: 400;
    border-radius: 50%;
    background: #3f4348;
    color: #fff;
    padding: 0.5rem;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 700;
    margin-top: 0.5rem;
  }

  .dates {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;

    padding: 0 1rem 0.5rem;
  }

  .start {
    display: flex;
    flex-direction: column;
    float: left;
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    line-height: 1rem;

    span {
      color: #ccc;
    }
  }

  .end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: right;
    text-align: end;
    font-size: 0.8rem;
    line-height: 1rem;

    span {
      color: #ccc;
    }
  }

  .d-f {
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      color: #3f4348;
      font-size: 0.9rem;
    }
  }
`

export const Actions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  border-radius: 0 0 0.55rem 0.55rem;
  opacity: 0;
  transition: opacity 1s;

  &:hover {
    opacity: 1;
  }

  span {
    display: flex;
    flex-direction: column;
  }

  svg {
    cursor: pointer;
    color: white;
  }
`

export const Action = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-weight: bold;

  &:hover {
    filter: brightness(0.8);

    svg {
      filter: brightness(0.8);
    }
  }
`

export const ContentPopUp = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;

  width: 100%;
  border-radius: 4px;

  h2 {
    text-align: center;
    margin-top: 1rem;
  }

  hr {
    border: none;
    margin: 0;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    margin: 1rem 0;
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 1rem;
    margin: 1rem 0;

    input {
      border-radius: 4px;
      padding: 0.5rem 2rem;
      border: none;
      background: #ff441d;
      color: #fff;
      text-transform: uppercase;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .partition {
    display: flex;
    width: 100%;
    padding: 0 1rem;
  }
`
