import { createGlobalStyle } from 'styled-components'

import GothamRoundedBold from './GothamRoundedBold_21016.ttf'
import GothamRoundedBook from './GothamRoundedBook_21018.ttf'
import GothamRoundedLight from './GothamRoundedLight_21020.ttf'
import GothamRoundedMedium from './GothamRoundedMedium_21022.ttf'

export default createGlobalStyle`
  @font-face {
    font-family: 'GothamBold';
    src: local('GothamBold'),
    url(${GothamRoundedBold}) format('truetype');
  }

  @font-face {
    font-family: 'GothamBook';
    src: local('GothamBook'),
    url(${GothamRoundedBook}) format('truetype');
  }

  @font-face {
    font-family: 'GothamLight';
    src: local('GothamLight'),
    url(${GothamRoundedLight}) format('truetype');
  }

  @font-face {
    font-family: 'GothamMedium';
    src: local('GothamMedium'),
    url(${GothamRoundedMedium}) format('truetype');
  }
`
