import { useState, useEffect, useCallback, useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { i18n } from '../../translate/i18n'
import { FaUserCog, FaEdit } from 'react-icons/fa'
import { toast, TypeOptions } from 'react-toastify'

import {
  Container,
  Content,
  Block,
  NameBlock,
  Line,
  Button,
  Select,
  ActionsBlock,
  CompleteBlock,
  TextArea,
  FormEdit
} from './styles'

import { Navbar } from '../../components/Navbar'
import { BreadCrumbs } from '../../components/BreadCrumbs'
import { RequestManager } from '../../security/RequestManager'
import { AuthContext, IUser } from '../../contexts/auth'
import PopUp, { IPropsExport } from '../../components/PopUp'

export interface ISubscriber {
  id: number
  taxId: string
  emailAddress: string
  firstName: string
  lastName: string
  gender: string
  ethnicity: string
  withDisability: string
  disabilityDescription: string
  birthDate: string
  phone: string
  region: string
  city: string
  linkedinURL: string
  educationalLevel: string
  englishLevel: string
  undergradStudent: string
  course: string
  university: string
  currentSemester: string
  graduationYear: string
  currentEmployment: string
  professionalExperience: string
  developmentExperience: string
  industryExperience: string
  salesforceExperience: string
  trailheadProfileURL: string
  recommendedBy: string
  whySubscribed: string
  timeAvailability: string
  haveComputer: string
  creationDate: string
  notes: string
  status: 'A' | 'R' | 'P' | 'N'
}

interface IRequest {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    subscriber: ISubscriber
    programTitle: string
  }
}

export const Subscriber = () => {
  const query = useLocation().search
  const id = Number(new URLSearchParams(query).get('id'))
  const programId = Number(localStorage.getItem('TALENT_PROGRAM_ID'))
  const count = Number(new URLSearchParams(query).get('count'))

  const modalRef = useRef<IPropsExport>(null)
  const modalRefEdit = useRef<IPropsExport>(null)

  const history = useHistory()

  const { user: userData } = useContext(AuthContext)
  const [user, setUser] = useState<IUser>({} as IUser)

  const [data, setData] = useState<ISubscriber>({} as ISubscriber)
  const [programTitle, setProgramTitle] = useState('')
  const [status, setStatus] = useState('N' as 'N' | 'A' | 'R')
  const [notes, setNotes] = useState('')
  const [error, setError] = useState('')
  const [linkedIn, setLinkedIn] = useState('')
  const [email, setEmail] = useState('')

  const subscriberView = useCallback(async () => {
    const response = (await RequestManager.requestAPI(
      '/talent/subscriber/view',
      {
        id,
        programId
      }
    )) as IRequest

    setData(response.receivedData.subscriber)
    setProgramTitle(response.receivedData.programTitle)
    setNotes(response.receivedData.subscriber.notes || '')
    setEmail(response.receivedData.subscriber.emailAddress)
    setLinkedIn(response.receivedData.subscriber.linkedinURL)
  }, [id, programId])

  useEffect(() => {
    subscriberView()
    userData && setUser(userData)
  }, [])

  const changeStatus = useCallback(
    async (id: number) => {
      if (status === 'A' || status === 'R' || status === 'N') {
        const response = (await RequestManager.requestAPI(
          '/talent/subscriber/set-status',
          {
            id,
            status,
            emailAddress: email,
            linkedinURL: linkedIn,
            notes
          }
        )) as IRequest

        if (response.receivedData.message) {
          setError(response.receivedData.message)
          notify(i18n.t('pages.subscriber.messages.error'), 'error', 2000)
          modalRef.current?.openModal()
        } else if (!response.hasError) {
          notify(i18n.t('pages.subscriber.messages.success'), 'success', 2000)
          history.push(
            count === 1
              ? '/subscribers'
              : `/subscribers?filter=0,${i18n.t('filters.type.pending')}`
          )
        }
      }
    },
    [notes, status, id, email, linkedIn]
  )

  const notify = useCallback(
    (text: string, type: TypeOptions, autoClose: number) => {
      toast(text, {
        type,
        autoClose,
        onClose: () => {}
      })
    },
    []
  )

  document.title = `${data.firstName} :: Talent`

  return (
    <>
      <Navbar />
      <BreadCrumbs
        path={[
          {
            label: i18n.t('breadCrumbs.label.program'),
            route: 'programs'
          },
          {
            label: programTitle,
            route: 'subscribers'
          }
        ]}
      />
      <Container>
        {data.firstName && (
          <Content type={status}>
            <NameBlock>
              <div>
                <p>
                  <FaUserCog size={25} />
                  {data && data.firstName + ' ' + data.lastName}
                </p>
                <span>{i18n.t('pages.subscriber.fields.student')}</span>
              </div>
              {data.status === 'N' && (
                <FaEdit
                  className="edit"
                  size={25}
                  color="#ccc"
                  onClick={() => modalRefEdit.current?.openModal()}
                />
              )}
            </NameBlock>
            <Line />
            <>
              <Block active={!!data.taxId}>
                <span>{i18n.t('pages.subscriber.fields.taxId')}</span>
                <p>{data.taxId}</p>
              </Block>
              <Block active={!!email}>
                <span>{i18n.t('pages.subscriber.fields.email')}</span>
                <a href={`mailto:${email}`}>
                  {email.length > 25
                    ? email.substring(0, email.indexOf('@') + 3) + '...'
                    : email}
                </a>
              </Block>
              <Block active={!!linkedIn}>
                <span>{i18n.t('pages.subscriber.fields.linkedin')}</span>
                <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                  {linkedIn.length > 43
                    ? linkedIn.substring(0, 40) + '...'
                    : linkedIn}
                </a>
              </Block>
              <Block active={!!data.phone}>
                <span>{i18n.t('pages.subscriber.fields.phone')}</span>
                <p>{data.phone}</p>
              </Block>

              <Block active={!!data.gender}>
                <span>{i18n.t('pages.subscriber.fields.gender')}</span>
                <p>
                  {data.gender === 'M'
                    ? i18n.t('pages.subscriber.gender.man')
                    : data.gender === 'F'
                    ? i18n.t('pages.subscriber.gender.woman')
                    : i18n.t('pages.subscriber.gender.other')}
                </p>
              </Block>
              <Block active={!!data.birthDate}>
                <span>{i18n.t('pages.subscriber.fields.birthDate')}</span>
                <p>{new Date(data.birthDate).toLocaleDateString('pt-BR')}</p>
              </Block>
              <Block active={!!data.region}>
                <span>
                  {i18n.t('pages.subscriber.fields.region')} /{' '}
                  {i18n.t('pages.subscriber.fields.city')}
                </span>
                <p className="cap">
                  {data.region.toUpperCase() + ' / ' + data.city.toLowerCase()}
                </p>
              </Block>

              <Block active={!!data.ethnicity}>
                <span>{i18n.t('pages.subscriber.fields.ethnicity')}</span>
                <p>{data.ethnicity}</p>
              </Block>
              <Block active={!!data.withDisability}>
                <span>{i18n.t('pages.subscriber.fields.withDisability')}</span>
                <p>{data.withDisability}</p>
              </Block>
              <Block active={!!data.disabilityDescription}>
                <span>
                  {i18n.t('pages.subscriber.fields.disabilityDescription')}
                </span>
                <p>{data.disabilityDescription}</p>
              </Block>
            </>
            <Line />
            <>
              <Block active={!!data.educationalLevel}>
                <span>
                  {i18n.t('pages.subscriber.fields.educationalLevel')}
                </span>
                <p>{data.educationalLevel}</p>
              </Block>
              <Block active={!!data.course}>
                <span>{i18n.t('pages.subscriber.fields.course')}</span>
                <p>{data.course}</p>
              </Block>
              <Block active={!!data.university}>
                <span>{i18n.t('pages.subscriber.fields.university')}</span>
                <p>{data.university}</p>
              </Block>

              <Block active={!!data.currentSemester}>
                <span>{i18n.t('pages.subscriber.fields.currentSemester')}</span>
                <p>{data.currentSemester}</p>
              </Block>
              <Block active={!!data.graduationYear}>
                <span>{i18n.t('pages.subscriber.fields.graduationYear')}</span>
                <p>{data.graduationYear}</p>
              </Block>
              <Block active={!!data.englishLevel}>
                <span>{i18n.t('pages.subscriber.fields.englishLevel')}</span>
                <p>{data.englishLevel}</p>
              </Block>

              <Block active={!!data.currentEmployment}>
                <span>
                  {i18n.t('pages.subscriber.fields.currentEmployment')}
                </span>
                <p>{data.currentEmployment}</p>
              </Block>
              <Block active={!!data.developmentExperience}>
                <span>
                  {i18n.t('pages.subscriber.fields.developmentExperience')}
                </span>
                <p>{data.developmentExperience}</p>
              </Block>

              <Block active={!!data.industryExperience}>
                <span>
                  {i18n.t('pages.subscriber.fields.industryExperience')}
                </span>
                <p>{data.industryExperience}</p>
              </Block>
              <Block active={!!data.salesforceExperience}>
                <span>
                  {i18n.t('pages.subscriber.fields.salesforceExperience')}
                </span>
                <p>{data.salesforceExperience}</p>
              </Block>
              <Block active={!!data.trailheadProfileURL}>
                <span>
                  {i18n.t('pages.subscriber.fields.trailheadProfile')}
                </span>
                <a
                  href={data.trailheadProfileURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.trailheadProfileURL.replace(/(^\w+:|^)\/\//, '')}
                </a>
              </Block>

              <Block active={!!data.recommendedBy}>
                <span>{i18n.t('pages.subscriber.fields.recommendedBy')}</span>
                <p className="ellipsis">{data.recommendedBy}</p>
              </Block>
              <Block active={!!data.timeAvailability}>
                <span>
                  {i18n.t('pages.subscriber.fields.timeAvailability')}
                </span>
                <p>{data.timeAvailability}</p>
              </Block>
              <Block active={!!data.haveComputer}>
                <span>{i18n.t('pages.subscriber.fields.haveComputer')}</span>
                <p>{data.haveComputer}</p>
              </Block>

              <CompleteBlock>
                <span>
                  {i18n.t('pages.subscriber.fields.professionalExperience')}
                </span>

                <p>{data.professionalExperience}</p>
              </CompleteBlock>
              <CompleteBlock>
                <span>{i18n.t('pages.subscriber.fields.whySubscribed')}</span>
                <p>{data.whySubscribed}</p>
              </CompleteBlock>
              <Block active={!!data.creationDate}>
                <span>{i18n.t('pages.subscriber.fields.creationDate')}</span>
                <p>{new Date(data.creationDate).toLocaleDateString('pt-BR')}</p>
              </Block>
            </>
            <Line />
            <>
              {data.status === 'N' ? (
                <ActionsBlock>
                  <div className="notes">
                    <span>{i18n.t('pages.subscriber.fields.notes')}</span>
                    <TextArea
                      name="notes"
                      rows={5}
                      value={notes}
                      onChange={(event) => setNotes(event.target.value)}
                    />
                  </div>

                  <div className="approve-or-fail">
                    <span>
                      {i18n.t('pages.subscriber.fields.approveOrFail')}
                    </span>
                    <Select
                      name="action"
                      onChange={(event) =>
                        setStatus(event.currentTarget.value as 'A' | 'R' | 'N')
                      }
                    >
                      <option
                        value="N"
                        label={i18n.t('pages.subscriber.buttons.pending')}
                      />
                      <option
                        value="A"
                        label={i18n.t('pages.subscriber.buttons.approve')}
                      />
                      <option
                        value="R"
                        label={i18n.t('pages.subscriber.buttons.fail')}
                      />
                    </Select>

                    <Button
                      className="btn"
                      type="button"
                      color="#000"
                      borderColor="#000"
                      onClick={() => changeStatus(data.id)}
                    >
                      {i18n.t('pages.subscriber.buttons.save')}
                    </Button>
                  </div>
                </ActionsBlock>
              ) : (
                <CompleteBlock>
                  <span>{i18n.t('pages.subscriber.fields.notes')}</span>
                  <p>
                    {data.notes || i18n.t('pages.subscriber.messages.noNotes')}
                  </p>
                </CompleteBlock>
              )}
            </>
          </Content>
        )}

        <PopUp ref={modalRef}>
          <div className="error">
            <h1>{i18n.t('pages.subscriber.messages.errorTitle')}</h1>
            <p>{i18n.t('pages.subscriber.messages.errorMessage')}</p>
            {user?.profile === 'ADMIN' && (
              <>
                <hr />
                <code>
                  {i18n.t('pages.subscriber.messages.codeMessage')}: {error}
                </code>
                <hr />
              </>
            )}
          </div>
        </PopUp>
      </Container>

      <PopUp ref={modalRefEdit}>
        <FormEdit>
          <h2>Editar Inscrição</h2>
          <hr />
          <div className="form">
            <label htmlFor="email">
              {i18n.t('pages.subscriber.fields.email')}:
            </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="form">
            <label htmlFor="linkedIn">
              {i18n.t('pages.subscriber.fields.linkedin')}:
            </label>
            <input
              type="url"
              name="linkedin"
              value={linkedIn}
              onChange={(event) => setLinkedIn(event.target.value)}
            />
          </div>
          <hr />
          <div className="flr">
            <input
              type="button"
              value="Save"
              onClick={() => modalRefEdit.current?.openModal()}
            />
          </div>
        </FormEdit>
      </PopUp>
    </>
  )
}
