import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #4b5c6b;
  padding: 0 1rem;

  #chart {
    width: 30vw !important;
  }
`
