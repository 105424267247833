import { useRef, useEffect } from 'react'
import { Chart, ChartData, registerables } from 'chart.js'

import { Container } from './styles'

interface IProps {
  data: ChartData
}

export const RadarChart = ({ data }: IProps) => {
  const chartRef = useRef<Chart | null>(null)

  Chart.register(...registerables)

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return
    const ctx = canvas.getContext('2d')

    if (ctx) {
      chartRef.current?.destroy()
      chartRef.current = new Chart(ctx, {
        type: 'radar',
        data: data,
        options: {
          scales: {
            r: {
              ticks: {
                precision: 0
              },
              suggestedMin: 0,
              suggestedMax: 5,
              beginAtZero: true
            }
          },
          responsive: true,
          elements: {
            line: {
              borderWidth: 3
            }
          },
          plugins: {
            legend: {
              display: false
              // position: 'bottom',
              // align: 'start',

              // labels: {
              //   textAlign: 'left'
              // }
            }
          }
        }
      })
    }
  }

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data
      chartRef.current.update()
    }
  }, [data])

  return (
    <Container>
      <canvas ref={canvasCallback} id="chart"></canvas>
    </Container>
  )
}
