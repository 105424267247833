import { useRef, useCallback, useState, useEffect } from 'react'
import { MdAddCircle } from 'react-icons/md'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { toast, TypeOptions } from 'react-toastify'

import PopUp, { IPropsExport } from '../../components/PopUp'
import { Navbar } from '../../components/Navbar'
import { i18n } from '../../translate/i18n'
import { Tooltip } from '../../components/Tooltip'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { Input } from '../../components/Form/Input'
import { Select } from '../../components/Form/Select'
import { TextArea } from '../../components/Form/TextArea'

import { Container, Card, Actions, Action, ContentPopUp } from './styles'
import { RequestManager } from 'src/security/RequestManager'
import { getTime } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

interface IProject {
  id: number
  profileTitle: string
  workModel: string
  jobLocation: string
  jobTitle: string
  profileId: number
  startDate: number
  endDate: number
  cacheDate: number
  target: number
}

interface IRequest {
  error: number
  project: IProject[]
}

export const Project = () => {
  const modalRef = useRef<IPropsExport>(null)
  const formRef = useRef<FormHandles>(null)

  const [projects, setProjects] = useState<IProject[]>([])

  const notify = useCallback(
    (text: string, type: TypeOptions, autoClose: number) => {
      toast(text, {
        type,
        autoClose,
        onClose: () => {}
      })
    },
    []
  )

  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({})

      const schema = Yup.object().shape({
        profile: Yup.string().required('The field profile is required'),
        jobTitle: Yup.string().required('The field title is required'),
        workModel: Yup.string().required('The field work model is required'),
        location: Yup.string().required('The field location model is required'),
        message: Yup.string().required('The field message model is required'),
        target: Yup.number()
          .typeError('The field target is required')
          .min(1, i18n.t('pages.programs.typeError.targetMin')),
        startDate: Yup.date()
          .default(() => {
            return new Date()
          })
          .typeError('The field start is required'),
        endDate: Yup.date()
          .default(() => {
            return new Date()
          })
          .typeError('The field end is required')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      RequestManager.requestAPI('/talent/project/create', {
        profile: Number(data.profile),
        jobTitle: data.jobTitle,
        location: data.location,
        workModel: data.workModel,
        target: Number(data.target),
        invitationMessage: data.message,
        startDate: getTime(utcToZonedTime(new Date(data.startDate), 'UTC')),
        endDate: getTime(utcToZonedTime(new Date(data.endDate), 'UTC'))
      })
        .then(() => {
          notify(i18n.t('pages.project.messages.success'), 'success', 2000)
          formRef.current?.reset()
          location.reload()
        })
        .catch((err) => {
          notify(err, 'error', 2000)
        })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        notify(i18n.t('pages.project.messages.warning'), 'warning', 2000)
        formRef.current?.setErrors(errors)
      }
    }
  }, [])

  useEffect(() => {
    RequestManager.requestAPI('/talent/project/list', {})
      .then((data) => {
        setProjects((data.receivedData as IRequest).project)
      })
      .catch((err) => {
        notify(err, 'error', 2000)
      })
  }, [])

  document.title = 'Company :: Talent'

  return (
    <>
      <Navbar />

      <Container>
        <Card>
          <div className="layer">
            <div className="newProgram">
              <MdAddCircle size={35} />
            </div>
            <Actions>
              <Tooltip title={i18n.t('pages.project.fields.labels.create')}>
                <Action to="#" onClick={() => modalRef.current?.openModal()}>
                  <MdAddCircle size={35} />
                </Action>
              </Tooltip>
            </Actions>
          </div>
        </Card>

        {projects.length > 0 &&
          projects.map((project) => (
            <Card key={project.id}>
              <h2 className="title">{project.jobTitle}</h2>

              <div className="d-f">
                <p>{i18n.t('pages.project.fields.labels.projectTitle')}:</p>
                <span>{project.jobLocation}</span>
              </div>
              <div className="d-f">
                <p>{i18n.t('pages.project.fields.labels.workModel')}:</p>
                <span>
                  {project.workModel === 'R'
                    ? i18n.t('pages.project.fields.options.workModel.remote')
                    : project.workModel === 'P'
                    ? i18n.t(
                        'pages.project.fields.options.workModel.presential'
                      )
                    : i18n.t('pages.project.fields.options.workModel.hybrid')}
                </span>
              </div>
              <div className="d-f">
                <p>{i18n.t('pages.project.fields.labels.target')}:</p>
                <span>{project.target}</span>
              </div>

              {/* <p className="target-title">Target</p>
              <span className="target">{project.target}</span> */}

              <div className="dates">
                <span className="start">
                  <span>{i18n.t('pages.project.fields.labels.start')}:</span>{' '}
                  <data value={project.startDate}>
                    {new Date(project.startDate).toLocaleDateString('pt-BR')}
                  </data>
                </span>
                <span className="end">
                  <span>{i18n.t('pages.project.fields.labels.end')}:</span>{' '}
                  <data value={project.endDate}>
                    {new Date(project.endDate).toLocaleDateString('pt-BR')}
                  </data>
                </span>
              </div>

              <Actions>
                <Tooltip title="Pool">
                  <Action
                    to="/pool"
                    onClick={() =>
                      localStorage.setItem(
                        'TALENT_PROJECT_ID',
                        String(project.id)
                      )
                    }
                  >
                    <FaExternalLinkAlt size={25} />
                  </Action>
                </Tooltip>
              </Actions>
            </Card>
          ))}
      </Container>

      <PopUp ref={modalRef}>
        <ContentPopUp>
          <h2>{i18n.t('pages.project.titles.project')}</h2>

          <Form onSubmit={handleSubmit} ref={formRef}>
            <div className="partition">
              <Select
                label={i18n.t('pages.project.fields.labels.profile')}
                name="profile"
                defaultValue=""
                optionsObj={[
                  {
                    value: '1',
                    label: 'Salesforce Developer'
                  },
                  {
                    value: '2',
                    label: 'Business Analyst'
                  }
                ]}
                isRequired={true}
              />
            </div>
            <div className="partition">
              <Input
                type="text"
                name="jobTitle"
                label={i18n.t('pages.project.fields.labels.projectTitle')}
                isRequired={true}
              />
              <Input
                type="text"
                name="location"
                label={i18n.t('pages.project.fields.labels.location')}
                placeholder={i18n.t(
                  'pages.project.fields.placeholders.location'
                )}
                isRequired={true}
              />
            </div>
            <div className="partition">
              <Select
                label={i18n.t('pages.project.fields.labels.workModel')}
                name="workModel"
                defaultValue=""
                optionsObj={[
                  {
                    value: 'R',
                    label: i18n.t(
                      'pages.project.fields.options.workModel.remote'
                    )
                  },
                  {
                    value: 'P',
                    label: i18n.t(
                      'pages.project.fields.options.workModel.presential'
                    )
                  },
                  {
                    value: 'H',
                    label: i18n.t(
                      'pages.project.fields.options.workModel.hybrid'
                    )
                  }
                ]}
                isRequired={true}
              />
              <Input
                type="number"
                name="target"
                label={i18n.t('pages.project.fields.labels.target')}
                min={1}
                isRequired={true}
              />
            </div>
            <div className="partition">
              <TextArea
                label={i18n.t('pages.project.fields.labels.message')}
                name="message"
                rows={6}
                isRequired={true}
                placeholder={i18n.t(
                  'pages.project.fields.placeholders.message'
                )}
              />
            </div>
            <div className="partition">
              <Input
                type="date"
                name="startDate"
                label={i18n.t('pages.project.fields.labels.start')}
                isRequired={true}
              />
              <Input
                type="date"
                name="endDate"
                label={i18n.t('pages.project.fields.labels.end')}
                isRequired={true}
              />
            </div>
            <div className="btn">
              <input
                type="submit"
                value={String(i18n.t('pages.project.buttons.create'))}
              />
            </div>
          </Form>
        </ContentPopUp>
      </PopUp>
    </>
  )
}
