import styled, { css } from 'styled-components'

import { animated } from 'react-spring'

interface IProps {
  type: 'info' | 'warning' | 'danger'
  position: 'top' | 'bottom' | 'left' | 'right'
}

const ToolTipVariations = {
  info: css`
    background: #000;
  `,

  warning: css`
    background: orange;
  `,

  danger: css`
    background: var(--red);
  `
}

const ToolTipPositionsVariations = {
  top: css`
    bottom: calc(100% + 10px);
    left: 50%;
  `,

  bottom: css`
    top: calc(100% - 30px);
    left: 50%;
  `,

  left: css`
    bottom: 0;
    left: calc(100% - 4rem);
  `,

  right: css`
    bottom: 0;
    right: calc(100% - 4rem);
  `
}

export const Container = styled(animated.div)<IProps>`
  display: flex;
  position: relative;
  text-transform: uppercase;
  z-index: 10;
  box-sizing: content-box;

  svg {
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }

  span {
    width: max-content !important;
    height: max-content !important;
    position: absolute;
    box-sizing: content-box;

    ${(props) => ToolTipVariations[props.type || 'info']}
    ${(props) => ToolTipPositionsVariations[props.position || 'top']}

    transform: translateX(-50%);
    opacity: 0;
    padding: 0.5rem;
    border-radius: 3px;
    font-size: 0.7rem !important;
    color: #ffffff;
    font-weight: bold;
    transition: opacity 0.4s;
    visibility: hidden;
    border: none !important;

    &::before {
      content: '';
      border: none !important;
      border: 6px 6px 0 6px solid #e52e4d !important;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-65%);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`
