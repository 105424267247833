import styled from 'styled-components'

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 40%;
  height: 50%;
  margin: 30px 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px #000;

  h1 {
    margin: 1rem 0 2rem;
  }

  input[type='password'] {
    width: 30rem;
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 1rem;
    margin: 1rem 0;

    input {
      border-radius: 4px;
      padding: 0.5rem 2rem;
      border: none;
      background: #ff441d;
      color: #fff;
      text-transform: uppercase;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .partition {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
  }
`
