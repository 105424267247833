import { useState, useEffect, useCallback, useRef } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { toast, TypeOptions } from 'react-toastify'
import { Link, useLocation, useHistory } from 'react-router-dom'

import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaSyncAlt
} from 'react-icons/fa'
import { BiFilterAlt, BiRefresh } from 'react-icons/bi'
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdStar,
  MdStarBorder
} from 'react-icons/md'
import { VscLinkExternal, VscSettings } from 'react-icons/vsc'
import { RiMailSendLine } from 'react-icons/ri'
import { FiClock } from 'react-icons/fi'
import { AiOutlineSchedule } from 'react-icons/ai'

import produce from 'immer'

import { getValidationErrors } from '../../utils/getValidationErrors'
import { Input } from '../../components/Form/Input'
import { TextArea } from '../../components/Form/TextArea'
import { Select } from '../../components/Form/Select'
import { i18n } from '../../translate/i18n'
import { Navbar } from '../../components/Navbar'
import { BreadCrumbs } from '../../components/BreadCrumbs'
import { RequestManager } from '../../security/RequestManager'
import Funnel from '../../assets/img/funnel.svg'

import {
  Container,
  TitleSidebar,
  Skills,
  Domain,
  DomainRequired,
  Range,
  Sidebar,
  Content,
  NeedRefresh,
  ContentSchedule,
  Update,
  TitleRank,
  Rank
} from './styles'

import { Filter } from '../../components/Filter'
import { CreateFilterContext } from '../../contexts/filter'
import { Tooltip } from '../../components/Tooltip'
import { CardCandidate as Card } from '../../components/CardCandidate'
import PopUp, { IPropsExport } from '../../components/PopUp'

interface IProfessional {
  id: number
  rank: number
  nick: string
  region: string
  city: string
  score: number
  status: string
  recruitingStatus: StatusValues
  isProgramComplete: boolean
  favorite: boolean
  domains: {
    id: number
    title: string
    tag: string
    skillLevel: number
    sourceWeight: number
  }[]
}

interface IDomains {
  id: number
  type: string
  tag: string
  title: string
  isRequired: boolean
  domainLevel: number
  description: string
  avgDomainLevel: number
  maxDomainLevel: number
  minDomainLevel: number
  skills: {
    id: number
    title: string
    description: string
    skillLevel: number
    avgSkillLevel: number
    maxSkillLevel: number
    minSkillLevel: number
  }[]
}

interface IRequest {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    filters: IFilter
    project: {
      id: number
      profileId: number
      orgId: number
      talentPoolOrgId: number
      title: string
      location: string
      workModel: string
      invitationMessage: string
      needRefresh: false
      timeRefresh: string
      skillVersion: number
      startDate: number
      endDate: number
      status: 'O' | 'C'
    }
    domains: IDomains[]
    recruitingFunnel: IRecruitingFunnel[]
  }
}

interface IRequestCandidate {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string

    candidates: IProfessional[]
    hasMore: boolean
    needRefresh: boolean
    skillVersion: number
  }
}

interface IRecruitingFunnel {
  status: StatusValues
  count: number
}

export interface IFilterSelect {
  value: string
  label: string
}

interface IFilter {
  currentEmployment: {
    options: {
      option: string
      count: number
    }[]
  }
  englishLevel: {
    options: {
      option: string
      count: number
    }[]
  }
  developmentExperience: {
    options: {
      option: string
      count: number
    }[]
  }
  ethnicity: {
    options: {
      option: string
      count: number
    }[]
  }
  gender: {
    options: {
      option: string
      count: number
    }[]
  }
  salesforceExperience: {
    options: {
      option: string
      count: number
    }[]
  }
  withDisability: {
    options: {
      option: string
      count: number
    }[]
  }
  region: {
    options: {
      option: string
      count: number
    }[]
  }
  educationalLevel: {
    options: {
      option: string
      count: number
    }[]
  }
}

interface IFilterData {
  field: FieldsOfFilter
  filter: string
  visible?: boolean
  filterOptions: {
    label: string
    quantity: number
  }[]
}

interface IDomainOld {
  domainId: number
  domainTitle: string
  domainLevel: number
}

interface ISkillUp {
  skillId: number
  skillLevel: number
}

type StatusValues = '' | '1' | '2' | '3' | '4' | '9' | 'D' | 'R'

type FieldsOfFilter =
  | 'currentEmployment'
  | 'englishLevel'
  | 'developmentExperience'
  | 'ethnicity'
  | 'gender'
  | 'salesforceExperience'
  | 'withDisability'
  | 'region'
  | 'educationalLevel'

export const Pool = () => {
  const formRef = useRef<FormHandles>(null)
  const scheduleModalRef = useRef<IPropsExport>(null)

  const history = useHistory()
  const query = useLocation().search
  const statusFunnel = new URLSearchParams(query).get('status')
  const projectId = Number(localStorage.getItem('TALENT_PROJECT_ID'))

  const [disableReset, setDisableReset] = useState(true)
  const [disableSave, setDisableSave] = useState(true)
  const [visible, setVisible] = useState(true)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [loadFilters, setLoadFilters] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [change, setChange] = useState(false)

  const [skillVersion, setSkillVersion] = useState(0)
  const [profileId, setProfileId] = useState(0)
  const [candidateId, setCandidateId] = useState(0)

  const [profileTitle, setProgramTitle] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [jobLocation, setJobLocation] = useState('')
  const [workModel, setWorkModel] = useState('')
  const [invitationMessage, setInvitationMessage] = useState('')
  const [filter, setFilter] = useState('')
  const [timeRefresh, setTimeRefresh] = useState('')
  const [status, setStatus] = useState('')

  const [fieldFilter, setFieldFilter] = useState<IFilter>({} as IFilter)
  const [projectDomains, setProjectDomains] = useState<IDomains[]>([])
  const [domainOld, setDomainOld] = useState<IDomainOld[]>([])
  const [dataFilter, setDataFilter] = useState<IFilterData[]>([])
  const [professional, setProfessional] = useState<IProfessional[]>([])
  const [favorites, setFavorites] = useState<Number[]>([])
  const [skillUp, setSkillUp] = useState<ISkillUp[]>([])
  const [fieldFilterFunnel, setFieldFilterFunnel] = useState<
    IRecruitingFunnel[]
  >([])

  const [modals, setModals] = useState({
    toggleSort: true,
    toggleFilter: false
  })
  const [configs, setConfigs] = useState({
    offset: 0,
    limit: 100
  })
  const [filtered, setFiltered] = useState(
    [] as {
      name: string
      options: string[]
    }[]
  )

  // functions start

  const notify = useCallback(
    (text: string, type: TypeOptions, autoClose: number) => {
      toast(text, {
        type,
        autoClose,
        onClose: () => {}
      })
    },
    []
  )

  const filterBy = useCallback(() => {
    RequestManager.requestAPI('/talent/pool/filter', {
      projectId,
      status
    }).then((data) => {
      const response = (data as IRequest).receivedData
      setFieldFilter(response.filters)
      setLoadFilters(true)
    })
  }, [projectId, status])

  if (loadFilters) {
    setDataFilter([
      {
        field: 'salesforceExperience',
        filter: i18n.t('filters.fields.salesforceExperience'),
        filterOptions:
          fieldFilter.salesforceExperience?.options &&
          fieldFilter.salesforceExperience.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          })
      },
      {
        field: 'developmentExperience',
        filter: i18n.t('filters.fields.developmentExperience'),
        filterOptions:
          fieldFilter.developmentExperience?.options &&
          fieldFilter.developmentExperience.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          })
      },
      {
        field: 'englishLevel',
        filter: i18n.t('filters.fields.englishLevel'),
        filterOptions:
          fieldFilter.englishLevel?.options &&
          fieldFilter.englishLevel.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          })
      },
      {
        field: 'educationalLevel',
        filter: i18n.t('filters.fields.educationalLevel'),
        filterOptions:
          fieldFilter.educationalLevel?.options &&
          fieldFilter.educationalLevel.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          })
      },
      {
        field: 'region',
        filter: i18n.t('filters.fields.region'),
        filterOptions:
          fieldFilter.region?.options &&
          fieldFilter.region.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          })
      },
      {
        field: 'gender',
        filter: i18n.t('filters.fields.gender'),
        filterOptions:
          fieldFilter.gender?.options &&
          fieldFilter.gender.options.map((filter) => {
            return {
              label:
                filter.option === 'M'
                  ? i18n.t('person.male')
                  : filter.option === 'F'
                  ? i18n.t('person.female')
                  : i18n.t('person.other'),
              quantity: filter.count
            }
          })
      },
      {
        field: 'currentEmployment',
        filter: i18n.t('filters.fields.currentEmployment'),
        filterOptions:
          fieldFilter.currentEmployment?.options &&
          fieldFilter.currentEmployment.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          })
      }
    ])
    setLoadFilters(false)
  }

  const filteredField = useCallback(
    (field: FieldsOfFilter, option: string, action: 'add' | 'remove') => {
      if (
        [
          i18n.t('person.male'),
          i18n.t('person.female'),
          i18n.t('person.other')
        ].some((any) => any === option)
      ) {
        switch (option) {
          case i18n.t('person.male'):
            option = 'M'
            break
          case i18n.t('person.female'):
            option = 'F'
            break
          case i18n.t('person.other'):
            option = 'O'
            break

          default:
            break
        }
      }
      if (filtered.length === 0) {
        setFiltered(
          produce(filtered, (draft) => {
            draft.push({ name: field, options: [option] })
          })
        )
      } else {
        let index = 0
        if (action === 'add') {
          setFiltered(
            produce(filtered, (draft) => {
              if (action === 'add') {
                if (draft.some((any) => any.name === field)) {
                  draft.forEach((any, indexDraft) => {
                    if (any.name === field) {
                      index = indexDraft
                      return indexDraft
                    }
                  })
                  draft[index].options.push(option)
                } else draft.push({ name: field, options: [option] })
              }
            })
          )
        } else {
          setFiltered(
            produce(filtered, (draft) => {
              draft.map((drf, index) => {
                if (drf.name === field) {
                  if (drf.options.length > 1)
                    draft[index].options = draft[index].options.filter(
                      (fil) => fil !== option
                    )
                  else {
                    draft[index].options = []
                  }
                }
                return drf
              })
            })
          )
        }
      }

      setChange(true)
    },
    [filtered]
  )

  const loadMore = useCallback(
    (filters?: { name: string; options: string[] }[]) => {
      setConfigs({
        limit: 100,
        offset: configs.offset + 100
      })

      RequestManager.requestAPI('/talent/pool/list', {
        limit: configs.limit,
        offset: configs.offset + 100,
        filters,
        status,
        profileId
      }).then((data) => {
        const response = (data as IRequestCandidate).receivedData

        setProfessional((oldArray) => [
          ...oldArray,
          ...response.candidates.map((candidate) => {
            candidate.favorite = candidate.recruitingStatus === '1'
            if (candidate.domains.length > 0) {
              candidate.domains.map((domain) => {
                domain.skillLevel = domain.skillLevel * 10
                return domain
              })
            }
            return candidate
          })
        ])
      })
    },
    [configs, skillVersion, status, profileId]
  )

  const professionalList = useCallback(
    (filters?: { name: string; options: string[] }[]) => {
      RequestManager.requestAPI('/talent/pool/list', {
        filters,
        status,
        profileId
      }).then((data) => {
        const response = (data as IRequestCandidate).receivedData

        setProfessional(
          response.candidates.map((candidate, index) => {
            candidate.rank = index + 1
            // index === 0
            //   ? (candidate.rank = 1)
            //   : (candidate.rank =
            //       response.candidates[index - 1].score === candidate.score
            //         ? response.candidates[index - 1].rank
            //         : response.candidates[index - 1].rank + 1)

            candidate.favorite = candidate.recruitingStatus === '1'
            if (candidate.domains.length > 0) {
              candidate.domains.map((domain) => {
                domain.skillLevel = domain.skillLevel * 10
                return domain
              })
            }
            return candidate
          })
        )

        setFavorites(
          response.candidates
            .filter((candidate) => candidate.recruitingStatus === '1')
            .map((candidate) => candidate.id)
        )

        setSkillVersion(response.skillVersion)
        setNeedRefresh(response.needRefresh)
        setHasMore(response.hasMore)
      })
    },
    [favorites, status, profileId]
  )

  const updateDomains = useCallback(
    (action = 'refresh') => {
      RequestManager.requestAPI('/talent/pool/update', {
        projectId,
        action,
        skills: skillUp.map((sk) => {
          return {
            skillId: sk.skillId,
            skillLevel: sk.skillLevel.toFixed(2)
          }
        })
      })

      action === 'reset' &&
        setProjectDomains(
          projectDomains.map((domain) => {
            domain.domainLevel = domain.avgDomainLevel
            domain.skills.map((skill) => {
              skill.skillLevel = skill.avgSkillLevel
              return skill
            })
            return domain
          })
        )
    },
    [projectDomains, skillUp]
  )

  const whatColor = useCallback(
    (id: number, skillLevel: number) => {
      const dividend = projectDomains.find(
        (domain) => domain.id === id
      )?.domainLevel
      const difference = dividend
        ? Number((skillLevel / dividend).toFixed(2))
        : 0

      if (difference >= 1.1) return 'green'
      if (difference >= 1) return 'lightGreen'
      if (difference > 0.93) return 'lightOrange'
      if (difference > 0.86) return 'orange'
      if (difference > 0.8) return 'lightRed'
      return 'red'
    },
    [projectDomains]
  )

  const whatLabel = useCallback((status: StatusValues) => {
    let label = ''
    switch (status) {
      case '':
        label = i18n.t('pages.pool.select.all')
        break
      case '1':
        label = i18n.t('pages.pool.select.selected')
        break
      case '2':
        label = i18n.t('pages.pool.select.invited')
        break
      case '3':
        label = i18n.t('pages.pool.select.scheduled')
        break
      case '4':
        label = i18n.t('pages.pool.select.interviewee')
        break
      case '9':
        label = i18n.t('pages.pool.select.hired')
        break
      case 'D':
        label = i18n.t('pages.pool.select.declined')
        break
      case 'R':
        label = i18n.t('pages.pool.select.released')
        break

      default:
        break
    }
    return label
  }, [])

  const changeRecruitingStatus = useCallback(
    (candidateId: number, status: StatusValues = '') => {
      RequestManager.requestAPI('/talent/pool/candidate-set-status', {
        projectId,
        candidateId,
        status
      })
    },
    [projectId]
  )

  const update = useCallback(() => {
    RequestManager.requestAPI('/talent/pool/update', {
      projectId,
      action: ''
    }).then(() => location.reload())
  }, [])

  // functions end

  useEffect(() => {
    RequestManager.requestAPI('/talent/pool/loadProjectData', {
      projectId
    }).then((data) => {
      const response = (data as IRequest).receivedData
      setProgramTitle(response.project.title)
      setProfileId(response.project.profileId)
      setFieldFilterFunnel(response.recruitingFunnel)
      setProjectDomains(
        response.domains
          .filter((domain) => domain.skills.length > 0)
          .map((domain) => {
            domain.domainLevel =
              (domain.skills
                .map((skill) => skill.skillLevel)
                .reduce((a, b) => a + b) /
                domain.skills.length) *
              10
            domain.avgDomainLevel =
              (domain.skills
                .map((skill) => skill.avgSkillLevel)
                .reduce((a, b) => a + b) /
                domain.skills.length) *
              10
            domain.avgDomainLevel =
              (domain.skills
                .map((skill) => skill.avgSkillLevel)
                .reduce((a, b) => a + b) /
                domain.skills.length) *
              10
            domain.maxDomainLevel =
              domain.skills
                .map((skill) => skill.maxSkillLevel)
                .reduce((a, b) => (a >= b ? a : b)) * 10
            domain.minDomainLevel =
              domain.skills
                .map((skill) => skill.minSkillLevel)
                .reduce((a, b) => (a <= b ? a : b)) * 10

            return domain
          })
      )

      setDomainOld(
        response.domains
          .filter((domain) => domain.skills.length > 0)
          .map((domain) => {
            return {
              domainId: domain.id,
              domainTitle: domain.title,
              domainLevel:
                (domain.skills
                  .map((skill) => skill.skillLevel)
                  .reduce((a, b) => a + b) /
                  domain.skills.length) *
                10
            }
          })
      )

      const dataSkill: ISkillUp[] = []

      response.domains
        .filter((domain) => domain.skills.length > 0)
        .map((domain): ISkillUp[] => {
          domain.skills.map((skill) => {
            dataSkill.push({
              skillId: skill.id,
              skillLevel: skill.skillLevel
            })
            return skill
          })
          return dataSkill
        })

      setJobTitle(response.project.title)
      setJobLocation(response.project.location)
      setInvitationMessage(response.project.invitationMessage)
      setWorkModel(response.project.workModel)

      setSkillUp(dataSkill)
      if (response.project.needRefresh) update()

      setTimeRefresh(response.project.timeRefresh)

      setChange(true)
    })

    if (
      statusFunnel !== null &&
      ['', '1', '2', '3', '4', '9', 'D', 'R'].some((st) => st === statusFunnel)
    ) {
      setFilter(statusFunnel)
      setStatus(statusFunnel)
    }
  }, [])

  useEffect(() => {
    if (change) {
      professionalList(filtered)
      filterBy()
      setChange(false)
    }
  }, [change, fieldFilterFunnel])

  useEffect(() => {
    setDisableReset(
      projectDomains
        .map((domain) => domain.avgDomainLevel !== domain.domainLevel)
        .every((element) => element === false)
    )
  }, [projectDomains])

  const handleSubmit = useCallback(
    async (data: {
      jobTitle: string
      location: string
      workModel: string
      message: string
    }) => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          jobTitle: Yup.string().required(
            i18n.t('pages.pool.fields.required.jobTitle')
          ),
          location: Yup.string().required(
            i18n.t('pages.pool.fields.required.location')
          ),
          workModel: Yup.string().required(
            i18n.t('pages.pool.fields.required.workModel')
          ),
          message: Yup.string().required(
            i18n.t('pages.pool.fields.required.message')
          )
        })

        await schema.validate(data, {
          abortEarly: false
        })

        RequestManager.requestAPI('/talent/pool/candidate-invite', {
          projectId,
          candidateId,
          jobTitle: data.jobTitle,
          location: data.location,
          workModel: data.workModel,
          message: data.message
        })
          .then(() => {
            scheduleModalRef.current?.openModal()
            notify(i18n.t('pages.pool.notify.sentEmail'), 'success', 2000)
            formRef.current?.reset()

            setTimeout(() => {
              location.reload()
            }, 2000)
          })
          .catch(() => {
            notify(i18n.t('pages.pool.notify.notSentEmail'), 'error', 2000)
          })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        }
      }
    },
    [profileId, candidateId]
  )

  document.title = 'Pool :: Talent'

  return (
    <CreateFilterContext.Provider value={{ filteredField }}>
      <Navbar />
      <BreadCrumbs
        path={[
          {
            label: i18n.t('breadCrumbs.label.project'),
            route: 'project'
          },
          {
            label: profileTitle,
            route: 'pool'
          }
        ]}
      />

      <Container>
        <div className="tog">
          {!visible && (
            <FaChevronCircleRight
              size={20}
              onClick={() => setVisible(!visible)}
            />
          )}
        </div>

        <Sidebar toggle={visible}>
          <div className="bg">
            <div className="position">
              <Link to="/pool/funnel">
                <img src={Funnel} alt="Funnel" width="34" />
              </Link>
              <select
                name="filter"
                value={filter}
                onChange={(option) => {
                  setChange(true)
                  setStatus(option.target.value)
                  setFilter(option.target.value)
                  option.target.value === '9' &&
                    history.push(
                      `/recruiter?project=${Number(
                        localStorage.getItem('TALENT_PROJECT_ID')
                      )}`
                    )
                  statusFunnel !== null && history.push('/pool')
                }}
              >
                {fieldFilterFunnel
                  ?.filter((element) => +element.count > 0)
                  .map((element, index) => (
                    <option key={index} value={element.status}>
                      {whatLabel(element.status)}
                      &nbsp;&nbsp;
                      {`(#${element.count})`}
                    </option>
                  ))}
              </select>
              <FaChevronCircleLeft
                className="tf"
                size={25}
                onClick={() => setVisible(!visible)}
              />
            </div>
          </div>
          <div className="bg">
            <div className="df">
              <TitleSidebar>
                {i18n.t('pages.pool.titles.sort')}
                {!modals.toggleSort ? (
                  <MdKeyboardArrowDown
                    size={25}
                    className="resume"
                    onClick={() =>
                      setModals({
                        ...modals,
                        toggleSort: !modals.toggleSort
                      })
                    }
                  />
                ) : (
                  <MdKeyboardArrowUp
                    size={25}
                    className="resume"
                    onClick={() =>
                      setModals({
                        ...modals,
                        toggleSort: !modals.toggleSort
                      })
                    }
                  />
                )}
              </TitleSidebar>

              <VscSettings
                size={20}
                color="#1373fc"
                className="hoverEffect"
                onClick={() =>
                  setModals({
                    ...modals,
                    toggleSort: !modals.toggleSort
                  })
                }
              />
            </div>

            {projectDomains.length > 0 &&
              modals.toggleSort &&
              projectDomains.map(
                (domain, index) =>
                  domain.title.toUpperCase() !== 'PERSONALITY' &&
                  domain.domainLevel > 0 && (
                    <div key={index}>
                      <p className="titleDomain">
                        {domain.title.toLowerCase()}
                      </p>
                      <Range
                        name={domain.title}
                        type="range"
                        min="1"
                        max="100"
                        step="1"
                        defaultValue={domain.domainLevel}
                        onChange={(event) => {
                          setProjectDomains(
                            projectDomains.map((dom) => {
                              if (dom.title === event.target.name) {
                                dom.domainLevel = Number(event.target.value)
                              }
                              return dom
                            })
                          )
                          setDisableSave(
                            projectDomains
                              .map((dom) => {
                                return (
                                  dom.domainLevel ===
                                  (dom.skills
                                    .map((skill) => skill.skillLevel)
                                    .reduce((a, b) => a + b) /
                                    dom.skills.length) *
                                    10
                                )
                              })
                              .every((any) => any === true)
                          )

                          setSkillUp(
                            skillUp.map((skill) => {
                              if (
                                domain.title === event.target.name &&
                                domain.skills.some(
                                  (skillDomain) =>
                                    skillDomain.id === skill.skillId
                                )
                              ) {
                                skill.skillLevel =
                                  (Number(event.target.value) /
                                    domainOld.find(
                                      (old) => old.domainId === domain.id
                                    )!.domainLevel) *
                                  skill.skillLevel
                              }
                              return skill
                            })
                          )
                        }}
                      />
                      <DomainRequired width={domain.avgDomainLevel} />
                    </div>
                  )
              )}

            {modals.toggleSort && (
              <div className="buttons">
                <button
                  type="button"
                  disabled={disableSave}
                  onClick={() => updateDomains('save')}
                >
                  {i18n.t('pages.pool.buttons.save')}
                </button>
                <button
                  type="button"
                  disabled={disableReset}
                  onClick={() => updateDomains('reset')}
                >
                  {i18n.t('pages.pool.buttons.reset')}
                </button>
              </div>
            )}
          </div>
          <div className="bg">
            <div className="df">
              <TitleSidebar>
                {i18n.t('pages.pool.titles.filter')}
                {!modals.toggleFilter ? (
                  <MdKeyboardArrowDown
                    size={25}
                    className="resume"
                    onClick={() =>
                      setModals({
                        ...modals,
                        toggleFilter: !modals.toggleFilter
                      })
                    }
                  />
                ) : (
                  <MdKeyboardArrowUp
                    size={25}
                    className="resume"
                    onClick={() =>
                      setModals({
                        ...modals,
                        toggleFilter: !modals.toggleFilter
                      })
                    }
                  />
                )}
              </TitleSidebar>
              <BiFilterAlt
                size={20}
                color="#1373fc"
                className="hoverEffect"
                onClick={() =>
                  setModals({
                    ...modals,
                    toggleFilter: !modals.toggleFilter
                  })
                }
              />
            </div>
            {dataFilter.length > 0 &&
              modals.toggleFilter &&
              dataFilter.map((fil, index) => (
                <Filter
                  key={index}
                  visible={fil?.visible}
                  field={fil.field}
                  filter={fil.filter}
                  filterOptions={fil.filterOptions || []}
                />
              ))}
          </div>
        </Sidebar>

        <Content toggle={visible}>
          {professional.length > 0 &&
            professional.filter(
              (prof) =>
                prof.recruitingStatus === '3' || prof.recruitingStatus === '4'
            ).length > 0 && <TitleRank>In Progress</TitleRank>}

          {professional.length > 0 &&
            professional
              .filter(
                (prof) =>
                  prof.recruitingStatus === '3' || prof.recruitingStatus === '4'
              )
              .map((prof, index) => (
                <Card
                  key={prof.id}
                  index={index}
                  haveSkill={prof.domains.length > 0}
                >
                  <AiOutlineSchedule className="waiting" size={20} />

                  <div className="description">
                    <p className="about">
                      <Rank isComplete={prof.isProgramComplete}>
                        {prof.rank}º
                      </Rank>
                      <span className="points">
                        {prof.score && (
                          <>
                            <span className="numberPoints">{prof.score}</span>
                            <span className="textPoints">
                              {i18n.t('pages.pool.messages.points')}
                            </span>
                          </>
                        )}
                      </span>
                      <span className="contact">
                        <span>
                          {prof.nick}
                          <Link
                            to={`/professional/?id=${prof.id}&position=${
                              index + 1
                            }&candidates=${1}`}
                            target="_blank"
                          >
                            <VscLinkExternal className="external" size={15} />
                          </Link>
                        </span>
                        <span>
                          {prof.city.toLowerCase()} / {prof.region}
                        </span>
                      </span>
                    </p>
                  </div>

                  <Skills>
                    {prof.domains &&
                      prof.domains.map((d) => (
                        <Domain
                          key={d.id}
                          type={whatColor(d.id, d.skillLevel)}
                          opacity={Number(d.sourceWeight)}
                        >
                          <Tooltip title={d.title}>
                            <p>{d.tag}</p>
                          </Tooltip>
                        </Domain>
                      ))}
                  </Skills>
                </Card>
              ))}

          {professional.length > 0 &&
            professional.filter(
              (prof) =>
                prof.recruitingStatus === '3' || prof.recruitingStatus === '4'
            ).length > 0 && <div className="interested"></div>}

          <TitleRank>
            Talent Pool
            <span>
              {i18n
                .t('pages.pool.messages.updateAt')
                .replace('[?]', timeRefresh)}
              <Update onClick={update}>
                <FaSyncAlt size={16} color="#fff" />
              </Update>
            </span>
          </TitleRank>
          {professional.length > 0 &&
            professional
              .filter(
                (prof) =>
                  prof.recruitingStatus !== '3' && prof.recruitingStatus !== '4'
              )
              .map((prof, index) => (
                <Card
                  key={prof.id}
                  index={index}
                  haveSkill={prof.domains.length > 0}
                >
                  {['', '1'].some(
                    (status) => status === prof.recruitingStatus
                  ) && (
                    <>
                      {prof.favorite ? (
                        <MdStar
                          className="star-full"
                          size={20}
                          onClick={() => {
                            setProfessional(
                              professional.map((candidate) => {
                                if (candidate.id === prof.id)
                                  candidate.favorite = !prof.favorite
                                return candidate
                              })
                            )
                            setFavorites(
                              favorites.filter(
                                (favorite) => favorite !== prof.id
                              )
                            )
                            changeRecruitingStatus(prof.id)
                          }}
                        />
                      ) : (
                        <MdStarBorder
                          className="star"
                          size={20}
                          onClick={() => {
                            setProfessional(
                              professional.map((candidate) => {
                                if (candidate.id === prof.id)
                                  candidate.favorite = !prof.favorite
                                return candidate
                              })
                            )
                            setFavorites([...favorites, prof.id])
                            changeRecruitingStatus(prof.id, '1')
                          }}
                        />
                      )}
                    </>
                  )}
                  {prof.recruitingStatus === '2' && (
                    <FiClock className="waiting" size={20} />
                  )}
                  <div className="description">
                    <p className="about">
                      <Rank isComplete={prof.isProgramComplete}>
                        {prof.rank}º
                      </Rank>
                      <span className="points">
                        {prof.score && (
                          <>
                            <span className="numberPoints">{prof.score}</span>
                            <span className="textPoints">
                              {i18n.t('pages.pool.messages.points')}
                            </span>
                          </>
                        )}
                      </span>
                      <span className="contact">
                        <span>
                          {prof.nick}
                          <Link
                            to={`/professional/?id=${prof.id}&position=${
                              index + 1
                            }&candidates=${1}`}
                            target="_blank"
                          >
                            <VscLinkExternal className="external" size={15} />
                          </Link>
                        </span>
                        <span>
                          {prof.city.toLowerCase()} / {prof.region}
                        </span>
                      </span>
                    </p>
                  </div>

                  {['', '1'].some(
                    (status) => status === prof.recruitingStatus
                  ) && (
                    <div className="schedule">
                      <Tooltip
                        title={i18n.t('pages.pool.tooltip.invite')}
                        position="left"
                      >
                        <RiMailSendLine
                          onClick={() => {
                            setCandidateId(prof.id)
                            scheduleModalRef.current?.openModal()
                          }}
                          size={20}
                        />
                      </Tooltip>
                    </div>
                  )}

                  <Skills>
                    {prof.domains &&
                      prof.domains.map((d) => (
                        <Domain
                          key={d.id}
                          type={whatColor(d.id, d.skillLevel)}
                          opacity={Number(d.sourceWeight)}
                        >
                          <Tooltip title={d.title}>
                            <p>{d.tag}</p>
                          </Tooltip>
                        </Domain>
                      ))}
                  </Skills>
                </Card>
              ))}

          {hasMore && (
            <div className="load-more">
              <span onClick={() => loadMore(filtered)}>
                {i18n.t('pages.pool.buttons.load')}
              </span>
            </div>
          )}
        </Content>
      </Container>

      {needRefresh && (
        <NeedRefresh>
          <div className="needRefresh">
            <h1>{i18n.t('pages.pool.messages.attention')}</h1>
            <p>{i18n.t('pages.pool.messages.attentionMessage')}</p>
            <button type="button" onClick={() => location.reload()}>
              <BiRefresh size={25} />
            </button>
          </div>
        </NeedRefresh>
      )}

      <PopUp ref={scheduleModalRef}>
        <ContentSchedule>
          <Form ref={formRef} onSubmit={handleSubmit} className="form">
            <h2>{i18n.t('pages.pool.titles.contact')}</h2>
            <Input
              type="text"
              name="jobTitle"
              label={i18n.t('pages.pool.fields.labels.jobTitle')}
              isRequired={true}
              value={jobTitle}
              onChange={(event) => setJobTitle(event.target.value)}
            />
            <div className="partition">
              <Input
                type="text"
                name="location"
                label={i18n.t('pages.pool.fields.labels.location')}
                isRequired={true}
                value={jobLocation}
                onChange={(event) => setJobLocation(event.target.value)}
              />

              <Select
                label={i18n.t('pages.pool.fields.labels.workModel')}
                name="workModel"
                optionsObj={[
                  {
                    value: 'H',
                    label: i18n.t('pages.pool.fields.options.workModel.hybrid')
                  },
                  {
                    value: 'P',
                    label: i18n.t(
                      'pages.pool.fields.options.workModel.presential'
                    )
                  },
                  {
                    value: 'R',
                    label: i18n.t('pages.pool.fields.options.workModel.remote')
                  }
                ]}
                isRequired={true}
                value={workModel}
                onChange={(event) => setWorkModel(event.target.value)}
              />
            </div>

            <TextArea
              name="message"
              label={i18n.t('pages.pool.fields.labels.message')}
              isRequired={true}
              value={invitationMessage}
              onChange={(event) => setInvitationMessage(event.target.value)}
              rows={10}
            />
            <div className="btn">
              <input
                type="submit"
                value={String(i18n.t('pages.pool.buttons.send'))}
              />
            </div>
          </Form>
        </ContentSchedule>
      </PopUp>
    </CreateFilterContext.Provider>
  )
}
