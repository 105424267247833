import React from 'react'

import { Container } from './styles'
type WithChildren<T = {}> = T & { children?: React.ReactNode }

type ITooltipProps = WithChildren<{
  title: string
  type?: 'info' | 'warning' | 'danger'
  position?: 'top' | 'bottom' | 'left' | 'right'
}>

export const Tooltip = ({
  title,
  children,
  type = 'info',
  position = 'top'
}: ITooltipProps) => {
  return (
    <Container type={type} position={position}>
      {children}
      <span>{title}</span>
    </Container>
  )
}
