import cookie from 'react-cookies'
import { CookieSerializeOptions } from 'cookie'

import { API } from '../services/api'

import { loading } from '../utils/loading'

interface IRequest {
  hasError: boolean
  receivedData: object
}

export class RequestManager {
  static sessionId = null
  static accessToken = null

  static async requestAPI(url: string, dataObject?: object): Promise<IRequest> {
    try {
      loading('flex')
      this.validateSessionCookie()

      const { data, status } = await API.post(
        process.env.REACT_APP_API_DOMAIN + url,
        dataObject,
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'session-id': RequestManager.sessionId,
            'access-token': RequestManager.accessToken
          }
        }
      )

      loading('none')

      if (status !== 200) {
        return { hasError: true, receivedData: {} }
      } else if (data.error) {
        return { hasError: true, receivedData: data }
      }

      return { hasError: false, receivedData: data }
    } catch (error) {
      loading('none')
      return { hasError: true, receivedData: { error } }
    }
  }

  static validateSessionCookie(): void {
    this.sessionId = cookie.load('session-id')
    this.accessToken = cookie.load('access-token')
    if (this.sessionId === undefined || this.accessToken === undefined) {
      this.goBackToLogin()
    }
  }

  static goBackToLogin(): void {
    localStorage.clear()
    cookie.remove('session-id', RequestManager.cookieConfig)
    cookie.remove('access-token', RequestManager.cookieConfig)
    window.location.href = process.env.REACT_APP_LOGIN_DOMAIN || ''
  }

  static logOut(): void {
    this.requestAPI('/logout')
    this.goBackToLogin()
  }

  static cookieConfig: CookieSerializeOptions = {
    path: '/',
    domain: process.env.NODE_ENV === 'production' ? 'pragma.school' : undefined
  }
}
