import Load from '../assets/img/loading.svg'

interface IProps {
  styled?: string
}

export const loading = (load: string): void => {
  document.getElementById('loading')?.setAttribute('style', `display:${load};`)
}

export const Loading = (styled: IProps) => {
  return (
    <div id="loading" style={{ display: `${styled}` }}>
      <img className="image" src={Load} alt="loading" />
    </div>
  )
}
