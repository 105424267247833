import { useState, useEffect, useCallback, useRef, useContext } from 'react'
// import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { i18n } from '../../translate/i18n'
import { IoMdCheckboxOutline, IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { RiMailSendLine } from 'react-icons/ri'
import {
  FaLinkedin,
  FaRegComments,
  FaWhatsapp,
  FaEnvelope,
  FaHandshake,
  FaChartBar,
  FaMapSigns,
  FaCogs
} from 'react-icons/fa'
import {
  MdClose,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdBackHand
} from 'react-icons/md'
import { GiBrain } from 'react-icons/gi'
import { toast, TypeOptions } from 'react-toastify'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { Navbar } from '../../components/Navbar'
import { RadarChart } from '../../components/Chart/Radar'
import { BarChart } from '../../components/Chart/Bar'
import { Tooltip } from '../../components/Tooltip'
import { RequestManager } from '../../security/RequestManager'
import PopUp, { IPropsExport } from 'src/components/PopUp'
import { AuthContext, IUser } from '../../contexts/auth'
import TrailHead from '../../assets/img/trailHead.svg'
import Default from '../../assets/img/default.png'
import { Select } from 'src/components/Form/Select'
import { Radio } from 'src/components/Form/Radio'
import { getValidationErrors } from '../../utils/getValidationErrors'

import {
  Container,
  Info,
  Title,
  PersonalInfo,
  Skills,
  SubTitle,
  ActionSkills,
  TableSkills,
  TDSkills,
  Circle,
  Chart,
  Journey,
  Card,
  FullCard,
  ContentPopUp,
  Action,
  Close,
  Details,
  ButtonsCategory,
  Admin,
  Line
} from './styles'

export interface ICandidate {
  userId: number
  id: number
  taxId: string
  emailAddress: string
  nick: string
  firstName: string
  lastName: string
  gender: string
  ethnicity: string
  withDisability: string
  disabilityDescription: string
  birthDate: string
  phone: string
  region: string
  city: string
  linkedinURL: string
  educationalLevel: string
  englishLevel: string
  undergradStudent: string
  course: string
  university: string
  currentSemester: string
  graduationYear: string
  currentEmployment: string
  professionalExperience: string
  developmentExperience: string
  industryExperience: string
  salesforceExperience: string
  trailheadProfileURL: string
  recommendedBy: string
  whySubscribed: string
  timeAvailability: string
  haveComputer: string
  creationDate: string
  notes: string
  status: 'A' | 'R' | 'P' | 'N'
  skills: string[]
  programStepId: number
  hasApprovalPending: boolean
  isRestricted: boolean
  recruitingStatus: '2' | '3' | '4' | 'R' | '9' | 'D'
  recruiterNotes: string
}

export interface ITrail {
  id: number
  title: string
  estimatedTime: number
  points: number
  score: number
  isComplete: boolean
  lastUpdateInDays: number
  estimatedHour: number
  estimatedMin: number
}

export interface IJourney {
  id: number
  title: string
  phase: string
  estimatedTime: number
  executedTime: number
  imagePath: string
  points: number
  score: number
  isComplete: boolean
  lastUpdateInDays: number
  progress: number
  estimatedHour: number
  estimatedMin: number
  executedHour: number
  executedMin: number

  trails: ITrail[]
}

interface IPersonality {
  category:
    | 'Openness to experience'
    | 'Emotionality'
    | 'Honesty/Humility'
    | 'Agreeableness'
    | 'Conscientiousness'
    | 'Extraversion'
  title: string
  level: number
  color: string
}

interface ISkills {
  id: number
  title: string
  skillPointsRequired: number
  candidateDomainPoints: number
  assessment: number
  journey: number
  exam: number
  interview: number
  project: number
}

interface IProgram {
  id: number
  externalId: string
  title: string
  startDate: number
  endDate: number
  status: 'C' | 'O' | 'P'
  target: number
  isDeleted: boolean
  subscribers: number
  phases: {
    candidates: number
    phase: 'A' | 'D' | 'I'
    status: 'C' | 'O' | 'P' | 'I'
  }[]
}

interface IPrograms {
  programs: IProgram[]
}

interface IDomains {
  id: number
  title: string
  candidateDomainPoints: number
  domainPointsRequired: number
  assessment: number
  journey: number
  exam: number
  interview: number
  project: number
  skills: ISkills[]
}

interface IRequest {
  error?: number
  message?: string
  candidate: ICandidate
  programTitle: string
  journeys: IJourney[]
  domains: IDomains[]
  personality: IPersonality[]
}

export const Academic = () => {
  const query = useLocation().search
  const id = Number(new URLSearchParams(query).get('id'))
  const position = Number(new URLSearchParams(query).get('position'))
  const totalCandidates = Number(new URLSearchParams(query).get('candidates'))
  const projectId = Number(localStorage.getItem('TALENT_PROJECT_ID'))

  const formRef = useRef<FormHandles>(null)
  const formRefAction = useRef<FormHandles>(null)
  const modalRef = useRef<IPropsExport>(null)
  const modalRefAdmin = useRef<IPropsExport>(null)
  const modalRefRecruiter = useRef<IPropsExport>(null)

  const [user, setUser] = useState<IUser>({} as IUser)
  const { user: userData } = useContext(AuthContext)

  const [active, setActive] = useState(true)
  const [activeChart, setActiveChart] = useState(true)
  const [trailToggle, setTrailToggle] = useState(false)
  const [journeyId, setJourneyId] = useState(0)
  const [programTitle, setProgramTitle] = useState('')
  const [action, setAction] = useState('')
  const [adminAction, setAdminAction] = useState('')
  const [recruiterNotes, setRecruiterNotes] = useState('')

  const [category, setCategory] = useState<
    | 'Openness to experience'
    | 'Emotionality'
    | 'Honesty/Humility'
    | 'Agreeableness'
    | 'Conscientiousness'
    | 'Extraversion'
  >('Openness to experience')

  const [newStatus, setNewStatus] = useState('' as 'A' | 'R')
  const [newRecruitingStatus, setNewRecruitingStatus] = useState(
    '' as '4' | '9' | 'R'
  )
  const [userCandidateData, setUserCandidateData] = useState<ICandidate>(
    {} as ICandidate
  )
  const [journeys, setJourneys] = useState<IJourney[]>([])
  const [personality, setPersonality] = useState<IPersonality[]>([])
  const [domains, setDomains] = useState<IDomains[]>([])
  const [program, setProgram] = useState<IProgram[]>([])
  const [skills, setSkills] = useState<ISkills[]>([])
  const [opennessToExperience, setOpennessToExperience] = useState(0)
  const [agreeableness, setAgreeableness] = useState(0)
  const [emotionality, setEmotionality] = useState(0)
  const [conscientiousness, setConscientiousness] = useState(0)
  const [extroversion, setExtroversion] = useState(0)
  const [honestyHumility, setHonestyHumility] = useState(0)

  const [modals, setModals] = useState({
    toggleInfo: true,
    toggleSkills: true,
    togglePersonality: true,
    toggleJourney: true
  })

  const NumberFormat = new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: 1
  })

  const colorCategory = useCallback((category) => {
    let color = ''
    switch (category) {
      case 'Agreeableness':
        color = '#e88ba0'
        break
      case 'Conscientiousness':
        color = '#74419e'
        break
      case 'Emotionality':
        color = '#f4cc53'
        break
      case 'Extraversion':
        color = '#e5264f'
        break
      case 'Honesty/Humility':
        color = '#5ba63d'
        break
      case 'Openness to experience':
        color = '#2891cb'
        break
      default:
        break
    }

    return color
  }, [])

  useEffect(() => {
    userData && setUser(userData)
  }, [userData])

  if (user === undefined) return <></>

  const path =
    user.profile === 'TALNT'
      ? '/talent/pool/candidate-view'
      : '/talent/candidate/view'

  useEffect(() => {
    if (['TALNT', 'ADMIN'].some((profile) => profile === user.profile)) {
      RequestManager.requestAPI(path, {
        id,
        projectId
      }).then((data) => {
        if (!data.hasError) {
          const response = data.receivedData as IRequest

          setUserCandidateData(response.candidate)
          setRecruiterNotes(response.candidate.recruiterNotes)

          setProgramTitle(response.programTitle)
          setJourneys(
            response.journeys.map((journey) => {
              journey.progress = Math.round(
                (journey.executedTime / journey.estimatedTime) * 100
              )

              journey.estimatedHour = Math.trunc(journey.estimatedTime / 60)
              journey.estimatedMin = Math.round(
                ((journey.estimatedTime / 60) % 1) * 60
              )
              journey.executedHour = Math.trunc(journey.executedTime / 60)
              journey.executedMin = Math.round(
                ((journey.executedTime / 60) % 1) * 60
              )
              journey.trails = journey.trails.map((trail) => {
                trail.estimatedHour = Math.trunc(trail.estimatedTime / 60)
                trail.estimatedMin = Math.round(
                  ((trail.estimatedTime / 60) % 1) * 60
                )
                return trail
              })

              return journey
            })
          )

          setPersonality(
            response.personality
              .slice(0)
              .sort((a, b) =>
                a.category > b.category ? 1 : a.category < b.category ? -1 : 0
              )
              .map((item) => {
                item.color = colorCategory(item.category)
                return item
              })
          )

          setDomains(
            response.domains.filter(
              (domain) => domain.candidateDomainPoints > 0
            )
          )

          const arrayTemp: ISkills[] = []

          response.domains.filter((domain) =>
            domain.skills.map((element) => arrayTemp.push(element))
          )

          setSkills(
            arrayTemp.sort((a, b) =>
              a.title > b.title ? 1 : a.title < b.title ? -1 : 0
            )
          )

          setModals({
            ...modals,
            toggleSkills:
              response.domains.filter(
                (domain) => domain.candidateDomainPoints > 0
              ).length > 0,
            togglePersonality: response.personality.length > 0
          })
        }
      })

      RequestManager.requestAPI('/talent/program/list').then((data) => {
        setProgram((data.receivedData as IPrograms).programs)
      })
    }
  }, [user])

  useEffect(() => {
    if (personality.length) {
      setOpennessToExperience(
        personality
          .map((personal) =>
            personal.category === 'Openness to experience' ? personal.level : 0
          )
          .reduce((a, b) => a + b) / 10
      )
      setAgreeableness(
        personality
          .map((personal) =>
            personal.category === 'Agreeableness' ? personal.level : 0
          )
          .reduce((a, b) => a + b) / 10
      )
      setConscientiousness(
        personality
          .map((personal) =>
            personal.category === 'Conscientiousness' ? personal.level : 0
          )
          .reduce((a, b) => a + b) / 10
      )
      setEmotionality(
        personality
          .map((personal) =>
            personal.category === 'Emotionality' ? personal.level : 0
          )
          .reduce((a, b) => a + b) / 10
      )
      setExtroversion(
        personality
          .map((personal) =>
            personal.category === 'Extraversion' ? personal.level : 0
          )
          .reduce((a, b) => a + b) / 10
      )
      setHonestyHumility(
        personality
          .map((personal) =>
            personal.category === 'Honesty/Humility' ? personal.level : 0
          )
          .reduce((a, b) => a + b) / 10
      )
    }
  }, [personality])

  const personalityData = {
    labels: [
      i18n.t('personality.opennessToExperience'),
      i18n.t('personality.agreeableness'),
      i18n.t('personality.extroversion'),
      i18n.t('personality.conscientiousness'),
      i18n.t('personality.emotionality'),
      i18n.t('personality.honestyHumility')
    ],
    datasets: [
      {
        label: userCandidateData.firstName || '',
        data: [
          opennessToExperience,
          agreeableness,
          extroversion,
          conscientiousness,
          emotionality,
          honestyHumility
        ],
        backgroundColor: '#e5264f88',
        borderColor: '#e5264f88',
        borderWidth: 2,
        pointBackgroundColor: '#fff',
        pointBorderColor: '#e5264f88',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(54, 162, 235)'
      }
    ]
  }

  const personalityDetailData = {
    labels: [
      i18n.t('personality.sub.flexibility.name'),
      i18n.t('personality.sub.forgiveness.name'),
      i18n.t('personality.sub.gentleness.name'),
      i18n.t('personality.sub.patience.name'),
      i18n.t('personality.sub.diligence.name'),
      i18n.t('personality.sub.organization.name'),
      i18n.t('personality.sub.perfectionism.name'),
      i18n.t('personality.sub.prudence.name'),
      i18n.t('personality.sub.anxiety.name'),
      i18n.t('personality.sub.dependence.name'),
      i18n.t('personality.sub.fearfulness.name'),
      i18n.t('personality.sub.sentimentality.name'),
      i18n.t('personality.sub.liveliness.name'),
      i18n.t('personality.sub.sociability.name'),
      i18n.t('personality.sub.socialBoldness.name'),
      i18n.t('personality.sub.socialSelfEsteem.name'),
      i18n.t('personality.sub.fairness.name'),
      i18n.t('personality.sub.greedAvoidance.name'),
      i18n.t('personality.sub.modesty.name'),
      i18n.t('personality.sub.sincerity.name'),
      i18n.t('personality.sub.aestheticAppreciation.name'),
      i18n.t('personality.sub.creativity.name'),
      i18n.t('personality.sub.inquisitiveness.name'),
      i18n.t('personality.sub.unconventionality.name')
    ],
    datasets: [
      {
        label: userCandidateData.firstName || '',
        data: personality.map((personality) => personality.level),
        backgroundColor: personality.map((personality) => personality.color),
        borderColor: personality.map((personality) => personality.color),
        borderWidth: 2
      }
    ]
  }

  const notify = useCallback(
    (text: string, type: TypeOptions, autoClose: number) => {
      toast(text, {
        type,
        autoClose,
        onClose: () => {}
      })
    },
    []
  )

  const translateCategory = useCallback((category) => {
    let translate = ''

    switch (category) {
      case 'Agreeableness':
        translate = i18n.t('personality.agreeableness')
        break
      case 'Conscientiousness':
        translate = i18n.t('personality.conscientiousness')
        break
      case 'Emotionality':
        translate = i18n.t('personality.emotionality')
        break
      case 'Extraversion':
        translate = i18n.t('personality.extroversion')
        break
      case 'Honesty/Humility':
        translate = i18n.t('personality.honestyHumility')
        break
      case 'Openness to experience':
        translate = i18n.t('personality.opennessToExperience')
        break
      default:
        break
    }

    return translate
  }, [])

  const phaseName = (phase: string) => {
    let name = ''

    switch (phase) {
      case 'D':
        name = 'Discovery'
        break
      case 'I':
        name = 'Ignite'
        break
      case 'A':
        name = 'Accelerate'
        break
      default:
        break
    }

    return name
  }

  const generateSkills = useCallback(() => {
    RequestManager.requestAPI('/talent/candidate/skill-refresh', {
      candidateId: id
    })
      .then(() => {
        notify(i18n.t('pages.academic.messages.successSkills'), 'success', 2000)
        location.reload()
      })
      .catch((err) => {
        notify(err, 'error', 2000)
      })
  }, [id])

  const urlLinkedIn = useCallback((url: string) => {
    let urlModified = url.replace('mwlite/', '')

    switch (urlModified.split('/').length - 1) {
      case 5:
        urlModified = urlModified.split('/')[4]
        break
      case 4:
        urlModified = urlModified.split('/')[4]
        break

      default:
        break
    }
    return urlModified
  }, [])

  const handleSubmit = useCallback(
    async (data: { program: string; sendMail: string }) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          program: Yup.string().required(
            i18n.t('pages.academic.fields.required')
          ),
          sendMail: Yup.string().required(
            i18n.t('pages.academic.fields.required')
          )
        })

        await schema.validate(data, {
          abortEarly: false
        })

        RequestManager.requestAPI('/talent/candidate/change-program', {
          id,
          newProgramId: Number(data.program),
          sendMail: data.sendMail === '1'
        })
          .then(() => {
            notify(
              i18n.t('pages.academic.messages.successChanged'),
              'success',
              2000
            )
            formRef.current?.reset()
            location.reload()
          })
          .catch((err) => {
            notify(err, 'error', 2000)
          })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)
        }

        notify(i18n.t('pages.academic.messages.noChanged'), 'error', 2000)
      }
    },
    [id]
  )

  const setStatus = useCallback(
    async (data: { sendMail: string }) => {
      try {
        formRefAction.current?.setErrors({})

        const schema = Yup.object().shape({
          sendMail: Yup.string().required(
            i18n.t('pages.academic.fields.required')
          )
        })

        await schema.validate(data, {
          abortEarly: false
        })

        RequestManager.requestAPI('/talent/candidate/set-step-status', {
          id: userCandidateData.id,
          programStepId: userCandidateData.programStepId,
          status: newStatus,
          sendMail: data.sendMail === '1',
          notes: ''
        })
          .then((data) => {
            data.hasError
              ? notify(i18n.t('pages.academic.messages.error'), 'error', 2000)
              : notify(
                  i18n.t('pages.academic.messages.success'),
                  'success',
                  2000
                )

            !data.hasError &&
              setTimeout(() => {
                location.reload()
              }, 2000)
          })
          .catch((err) => {
            notify(err, 'error', 2000)
          })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRefAction.current?.setErrors(errors)
        }

        notify(i18n.t('pages.academic.messages.noChanged'), 'error', 2000)
      }
    },
    [userCandidateData, newStatus]
  )

  const setRecruiterStatus = useCallback(
    (candidateId: number) => {
      RequestManager.requestAPI('/talent/pool/candidate-set-status', {
        projectId,
        candidateId,
        status: newRecruitingStatus,
        notes: recruiterNotes
      })
        .then((data) => {
          data.hasError
            ? notify(i18n.t('pages.academic.messages.error'), 'error', 2000)
            : notify(
                i18n.t('pages.academic.messages.successStatus'),
                'success',
                2000
              )

          !data.hasError &&
            setTimeout(() => {
              location.reload()
            }, 2000)
        })
        .catch((err) => {
          notify(err, 'error', 2000)
        })
    },
    [projectId, newRecruitingStatus, recruiterNotes]
  )

  document.title = 'Academic :: Talent'

  return (
    <>
      <Navbar />

      {userCandidateData.id > 0 && (
        <Container>
          <Info>
            <Title>
              {programTitle}
              <span className="ranking">
                {position}°/<span>{totalCandidates}</span>
              </span>
            </Title>

            {user.profile === 'ADMIN' && (
              <Admin>
                <SubTitle>
                  <span>
                    <FaCogs size={20} />
                  </span>
                  Admin
                </SubTitle>
                <div className="admin-buttons">
                  <button
                    type="button"
                    onClick={() => {
                      modalRefAdmin.current?.openModal()
                      setAdminAction('change')
                    }}
                  >
                    {i18n.t('pages.academic.buttons.changeProgram')}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      modalRefAdmin.current?.openModal()
                      setAdminAction('generateSkill')
                    }}
                  >
                    {i18n.t('pages.academic.buttons.generateSkill')}
                  </button>
                </div>
              </Admin>
            )}

            <PersonalInfo resume={modals.toggleInfo}>
              <div className="block">
                <h2>
                  {userCandidateData.nick}{' '}
                  <span className="admin-info">
                    {user.profile === 'ADMIN'
                      ? '( U: ' +
                        userCandidateData.userId +
                        ', C: ' +
                        userCandidateData.id +
                        ' )'
                      : ''}
                  </span>
                  <br />
                  <span>{`${userCandidateData.city} - ${userCandidateData.region}`}</span>
                </h2>
                {!userCandidateData.isRestricted && (
                  <div className="contact">
                    <a
                      href={`https://api.whatsapp.com/send?phone=55${userCandidateData.phone.replace(
                        /[^0-9]/g,
                        ''
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaWhatsapp size={20} color="#34af23" />
                    </a>
                    <a
                      href={`mailto:${userCandidateData.emailAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaEnvelope size={23} color="#e52e4d" />
                    </a>
                    {userCandidateData.linkedinURL && (
                      <a
                        href={userCandidateData.linkedinURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin size={20} color="#0a66c2" />
                      </a>
                    )}
                    {userCandidateData.trailheadProfileURL && (
                      <a
                        href={userCandidateData.trailheadProfileURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={TrailHead} alt="TrailHead" width={20} />
                      </a>
                    )}
                  </div>
                )}
              </div>

              <div className="modal">
                <div className="block">
                  {!userCandidateData.isRestricted && (
                    <>
                      <div className="column">
                        <p className="question">
                          {i18n.t('pages.academic.questions.phone')}
                        </p>
                        <p className="answer">{userCandidateData.phone}</p>
                      </div>

                      <div className="column">
                        <p className="question">
                          {i18n.t('pages.academic.questions.email')}
                        </p>
                        <p className="answer">
                          {userCandidateData.emailAddress.length > 25
                            ? userCandidateData.emailAddress.substring(
                                0,
                                userCandidateData.emailAddress.indexOf('@') + 3
                              ) + '...'
                            : userCandidateData.emailAddress}
                        </p>
                      </div>

                      {userCandidateData.linkedinURL && (
                        <div className="column">
                          <p className="question">
                            {i18n.t('pages.academic.questions.linkedIn')}
                          </p>
                          <p className="answer">
                            {urlLinkedIn(userCandidateData.linkedinURL)}
                          </p>
                        </div>
                      )}

                      {userCandidateData.trailheadProfileURL && (
                        <div className="column">
                          <p className="question">
                            {i18n.t('pages.academic.questions.trailHead')}
                          </p>
                          <p className="answer">
                            {
                              userCandidateData.trailheadProfileURL.split(
                                '/'
                              )[4]
                            }
                          </p>
                        </div>
                      )}
                    </>
                  )}
                  <hr />
                  {!userCandidateData.isRestricted && (
                    <div className="column">
                      <p className="question">
                        {i18n.t('pages.academic.questions.taxId')}
                      </p>
                      <p className="answer">{userCandidateData.taxId}</p>
                    </div>
                  )}
                  <div className="column">
                    <p className="question">
                      {i18n.t('pages.academic.questions.birthDate')}
                    </p>
                    <p className="answer">
                      {new Date(userCandidateData.birthDate).toLocaleDateString(
                        'pt-BR'
                      )}
                    </p>
                  </div>
                  <div className="column">
                    <p className="question">
                      {i18n.t('pages.academic.questions.gender')}
                    </p>
                    <p className="answer">
                      {userCandidateData.gender === 'M'
                        ? i18n.t('person.male')
                        : userCandidateData.gender === 'F'
                        ? i18n.t('person.female')
                        : i18n.t('person.other')}
                    </p>
                  </div>

                  <div className="column">
                    <p className="question">
                      {i18n.t('pages.academic.questions.englishLevel')}
                    </p>
                    <p className="answer">{userCandidateData.englishLevel}</p>
                  </div>

                  <div className="column">
                    <p className="question">
                      {i18n.t('pages.academic.questions.educationalLevel')}
                    </p>
                    <p className="answer">
                      {userCandidateData.educationalLevel}
                    </p>
                  </div>
                  <div className="column">
                    <p className="question">
                      {i18n.t('pages.academic.questions.currentEmployment')}
                    </p>
                    <p className="answer">
                      {userCandidateData.currentEmployment}
                    </p>
                  </div>
                  <div className="column">
                    <p className="question">
                      {i18n.t('pages.academic.questions.developmentExperience')}
                    </p>
                    <p className="answer">
                      {userCandidateData.developmentExperience}
                    </p>
                  </div>

                  <hr />

                  {!userCandidateData.isRestricted && (
                    <>
                      <div className="row">
                        <p className="question">
                          {i18n.t(
                            'pages.academic.questions.professionalExperience'
                          )}
                        </p>
                        <p className="answer">
                          {userCandidateData.professionalExperience}
                        </p>
                      </div>
                      <hr />
                    </>
                  )}
                </div>

                {user.profile === 'ADMIN' && (
                  <div className="actions">
                    <button
                      type="button"
                      className="approve"
                      disabled={!userCandidateData.hasApprovalPending}
                      onClick={() => {
                        setNewStatus('A')
                        setAction(i18n.t('pages.academic.messages.approval'))
                        modalRef.current?.openModal()
                      }}
                    >
                      {i18n.t('pages.academic.buttons.approve')}
                    </button>
                    <button
                      type="button"
                      className="fail"
                      disabled={!userCandidateData.hasApprovalPending}
                      onClick={() => {
                        setNewStatus('R')
                        setAction(i18n.t('pages.academic.messages.disapproval'))
                        modalRef.current?.openModal()
                      }}
                    >
                      {i18n.t('pages.academic.buttons.fail')}
                    </button>
                  </div>
                )}

                {['2', '3', '4', 'D', '9', 'R'].some(
                  (any) => any === userCandidateData.recruitingStatus
                ) && (
                  <div className="line-progress">
                    <RiMailSendLine size={50} color="#32CD32" />
                    <Line active={true} />
                    {userCandidateData.recruitingStatus !== 'D' ? (
                      <IoMdCheckmarkCircleOutline
                        size={50}
                        color={
                          userCandidateData.recruitingStatus === '2'
                            ? '#4b5c6b'
                            : '#32CD32'
                        }
                      />
                    ) : (
                      <MdBackHand size={50} color="#FF6347" />
                    )}
                    <Line
                      active={['4', '9', 'R'].some(
                        (any) => any === userCandidateData.recruitingStatus
                      )}
                    />
                    <FaRegComments
                      size={50}
                      color={
                        userCandidateData.recruitingStatus === '4'
                          ? '#4b5c6b'
                          : ['2', '3', 'D'].some(
                              (any) =>
                                any === userCandidateData.recruitingStatus
                            )
                          ? '#DDD'
                          : '#32CD32'
                      }
                    />
                    <Line
                      active={['9', 'R'].some(
                        (any) => any === userCandidateData.recruitingStatus
                      )}
                    />
                    {userCandidateData.recruitingStatus !== 'R' ? (
                      <FaHandshake
                        size={50}
                        color={
                          userCandidateData.recruitingStatus === '9'
                            ? '#32CD32'
                            : ['2', '3', '4', 'D'].some(
                                (any) =>
                                  any === userCandidateData.recruitingStatus
                              )
                            ? '#DDD'
                            : '#32CD32'
                        }
                      />
                    ) : (
                      <MdBackHand size={50} color="#FF6347" />
                    )}
                  </div>
                )}

                {['3', '4', 'R'].some(
                  (any) => any === userCandidateData.recruitingStatus
                ) &&
                  user.profile === 'TALNT' && (
                    <div className="actions-status">
                      <textarea
                        name="recruiterNotes"
                        placeholder={i18n.t('pages.academic.buttons.notes')}
                        onChange={(event) =>
                          setRecruiterNotes(event.target.value)
                        }
                        value={recruiterNotes}
                      />

                      <div className="buttons">
                        <select
                          className="status-professional"
                          value={newRecruitingStatus}
                          onChange={(event) =>
                            setNewRecruitingStatus(
                              event.target.value as '4' | '9' | 'R'
                            )
                          }
                        >
                          <option disabled value="">
                            {i18n.t('pages.academic.select.options.change')}
                          </option>
                          {!['R', '9', '4'].some(
                            (any) => any === userCandidateData.recruitingStatus
                          ) && (
                            <option value="4">
                              {i18n.t(
                                'pages.academic.select.options.interview'
                              )}
                            </option>
                          )}
                          {userCandidateData.recruitingStatus !== 'R' && (
                            <option value="R">
                              {i18n.t('pages.academic.select.options.rejected')}
                            </option>
                          )}
                          <option value="9">
                            {i18n.t('pages.academic.select.options.hired')}
                          </option>
                        </select>

                        <button
                          type="button"
                          className="save"
                          disabled={!newRecruitingStatus}
                          onClick={() => modalRefRecruiter.current?.openModal()}
                        >
                          {i18n.t('pages.academic.buttons.save')}
                        </button>
                      </div>
                    </div>
                  )}
              </div>

              {!modals.toggleInfo ? (
                <MdKeyboardArrowDown
                  size={25}
                  className="resume"
                  onClick={() =>
                    setModals({
                      ...modals,
                      toggleInfo: !modals.toggleInfo
                    })
                  }
                />
              ) : (
                <MdKeyboardArrowUp
                  size={25}
                  className="resume"
                  onClick={() =>
                    setModals({
                      ...modals,
                      toggleInfo: !modals.toggleInfo
                    })
                  }
                />
              )}
            </PersonalInfo>

            <Skills resume={modals.toggleSkills}>
              <SubTitle>
                <span>
                  <GiBrain size={20} />
                </span>
                Skills
              </SubTitle>

              <div className="modal">
                <ActionSkills active={active}>
                  <span onClick={() => setActive(true)}>
                    {i18n.t('pages.academic.buttons.summary')}
                  </span>
                  <span onClick={() => setActive(false)}>
                    {i18n.t('pages.academic.buttons.detail')}
                  </span>
                </ActionSkills>
                <TableSkills>
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">
                        <Circle color="blue">
                          <Tooltip title="Assessment">A</Tooltip>
                        </Circle>
                      </th>
                      <th scope="col">
                        <Circle color="black">
                          <Tooltip title="Journey">J</Tooltip>
                        </Circle>
                      </th>
                      <th scope="col">
                        <Circle color="red">
                          <Tooltip title="Exam">E</Tooltip>
                        </Circle>
                      </th>
                      <th scope="col">
                        <Circle color="purple">
                          <Tooltip title="Interview">I</Tooltip>
                        </Circle>
                      </th>
                      <th scope="col">
                        <Circle color="orange">
                          <Tooltip title="Project">P</Tooltip>
                        </Circle>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {active &&
                      domains
                        .filter((dt) => dt.candidateDomainPoints > 0)
                        .map((dt) => (
                          <tr key={dt.id}>
                            <th scope="row">{dt.title}</th>
                            <td>
                              <p className="bar-back">
                                <TDSkills
                                  color="#00B1F2"
                                  required={+dt.candidateDomainPoints}
                                  height={1.2}
                                />
                                <TDSkills
                                  color="#ddd"
                                  required={+dt.domainPointsRequired}
                                  height={0.6}
                                />
                              </p>
                            </td>
                            <td>
                              {+dt.assessment
                                ? NumberFormat.format(dt.assessment)
                                : '**'}
                            </td>
                            <td>
                              {+dt.journey
                                ? NumberFormat.format(dt.journey)
                                : '**'}
                            </td>
                            <td>
                              {+dt.exam ? NumberFormat.format(dt.exam) : '**'}
                            </td>
                            <td>
                              {+dt.interview
                                ? NumberFormat.format(dt.interview)
                                : '**'}
                            </td>
                            <td>
                              {+dt.project
                                ? NumberFormat.format(dt.project)
                                : '**'}
                            </td>
                          </tr>
                        ))}
                    {!active &&
                      skills
                        .filter((sk) => sk.candidateDomainPoints > 0)
                        .map((sk) => (
                          <tr key={sk.id}>
                            <th scope="row">{sk.title}</th>
                            <td>
                              <p className="bar-back">
                                <TDSkills
                                  color="#fa8627"
                                  required={+sk.candidateDomainPoints}
                                  height={1.2}
                                />
                                <TDSkills
                                  color="#ddd"
                                  required={+sk.skillPointsRequired}
                                  height={0.6}
                                />
                              </p>
                            </td>
                            <td>
                              {+sk.assessment
                                ? NumberFormat.format(sk.assessment)
                                : '**'}
                            </td>
                            <td>
                              {+sk.journey
                                ? NumberFormat.format(sk.journey)
                                : '**'}
                            </td>
                            <td>
                              {+sk.exam ? NumberFormat.format(sk.exam) : '**'}
                            </td>
                            <td>
                              {+sk.interview
                                ? NumberFormat.format(sk.interview)
                                : '**'}
                            </td>
                            <td>
                              {+sk.project
                                ? NumberFormat.format(sk.project)
                                : '**'}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </TableSkills>
              </div>

              {domains.length > 0 && (
                <>
                  {!modals.toggleSkills ? (
                    <MdKeyboardArrowDown
                      size={25}
                      className="resume"
                      onClick={() =>
                        setModals({
                          ...modals,
                          toggleSkills: !modals.toggleSkills
                        })
                      }
                    />
                  ) : (
                    <MdKeyboardArrowUp
                      size={25}
                      className="resume"
                      onClick={() =>
                        setModals({
                          ...modals,
                          toggleSkills: !modals.toggleSkills
                        })
                      }
                    />
                  )}
                </>
              )}
            </Skills>

            <Chart resume={modals.togglePersonality}>
              <SubTitle>
                <span>
                  <FaChartBar size={20} />
                </span>
                {i18n.t('pages.academic.titles.personality')}
              </SubTitle>

              {modals.togglePersonality && (
                <ActionSkills active={activeChart}>
                  <span onClick={() => setActiveChart(true)}>
                    {i18n.t('pages.academic.buttons.summary')}
                  </span>
                  <span onClick={() => setActiveChart(false)}>
                    {i18n.t('pages.academic.buttons.detail')}
                  </span>
                </ActionSkills>
              )}

              {activeChart && (
                <div className="description">
                  <div className="modal">
                    <RadarChart data={personalityData} />
                  </div>

                  <div className="details">
                    <Details color="#2891cb">
                      <summary>
                        {i18n.t('personality.opennessToExperience')}
                      </summary>
                      <p>
                        {i18n.t('personality.description.opennessToExperience')}
                      </p>
                    </Details>
                    <Details color="#e88ba0">
                      <summary>{i18n.t('personality.agreeableness')}</summary>
                      <p>{i18n.t('personality.description.agreeableness')}</p>
                    </Details>
                    <Details color="#e5264f">
                      <summary>{i18n.t('personality.extroversion')}</summary>
                      <p>{i18n.t('personality.description.extroversion')}</p>
                    </Details>
                    <Details color="#74419e">
                      <summary>
                        {i18n.t('personality.conscientiousness')}
                      </summary>
                      <p>
                        {i18n.t('personality.description.conscientiousness')}
                      </p>
                    </Details>
                    <Details color="#f4cc53">
                      <summary>{i18n.t('personality.emotionality')}</summary>
                      <p>{i18n.t('personality.description.emotionality')}</p>
                    </Details>
                    <Details color="#5ba63d">
                      <summary>{i18n.t('personality.honestyHumility')}</summary>
                      <p>{i18n.t('personality.description.honestyHumility')}</p>
                    </Details>
                  </div>
                </div>
              )}

              {!activeChart && (
                <div className="description">
                  <div className="modal">
                    <BarChart data={personalityDetailData} />
                  </div>

                  <div className="details">
                    <h3>{translateCategory(category)}</h3>
                    {category === 'Agreeableness' && (
                      <>
                        <Details color="#e88ba0">
                          <summary>
                            {i18n.t('personality.sub.flexibility.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.flexibility.description')}
                          </p>
                        </Details>
                        <Details color="#e88ba0">
                          <summary>
                            {i18n.t('personality.sub.forgiveness.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.forgiveness.description')}
                          </p>
                        </Details>
                        <Details color="#e88ba0">
                          <summary>
                            {i18n.t('personality.sub.gentleness.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.gentleness.description')}
                          </p>
                        </Details>
                        <Details color="#e88ba0">
                          <summary>
                            {i18n.t('personality.sub.patience.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.patience.description')}
                          </p>
                        </Details>
                      </>
                    )}
                    {category === 'Conscientiousness' && (
                      <>
                        <Details color="#74419e">
                          <summary>
                            {i18n.t('personality.sub.diligence.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.diligence.description')}
                          </p>
                        </Details>
                        <Details color="#74419e">
                          <summary>
                            {i18n.t('personality.sub.organization.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.organization.description')}
                          </p>
                        </Details>
                        <Details color="#74419e">
                          <summary>
                            {i18n.t('personality.sub.perfectionism.name')}
                          </summary>
                          <p>
                            {i18n.t(
                              'personality.sub.perfectionism.description'
                            )}
                          </p>
                        </Details>
                        <Details color="#74419e">
                          <summary>
                            {i18n.t('personality.sub.prudence.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.prudence.description')}
                          </p>
                        </Details>
                      </>
                    )}
                    {category === 'Emotionality' && (
                      <>
                        <Details color="#f4cc53">
                          <summary>
                            {i18n.t('personality.sub.anxiety.name')}
                          </summary>
                          <p>{i18n.t('personality.sub.anxiety.description')}</p>
                        </Details>
                        <Details color="#f4cc53">
                          <summary>
                            {i18n.t('personality.sub.dependence.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.dependence.description')}
                          </p>
                        </Details>
                        <Details color="#f4cc53">
                          <summary>
                            {i18n.t('personality.sub.fearfulness.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.fearfulness.description')}
                          </p>
                        </Details>
                        <Details color="#f4cc53">
                          <summary>
                            {i18n.t('personality.sub.sentimentality.name')}
                          </summary>
                          <p>
                            {i18n.t(
                              'personality.sub.sentimentality.description'
                            )}
                          </p>
                        </Details>
                      </>
                    )}
                    {category === 'Extraversion' && (
                      <>
                        <Details color="#e5264f">
                          <summary>
                            {i18n.t('personality.sub.liveliness.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.liveliness.description')}
                          </p>
                        </Details>
                        <Details color="#e5264f">
                          <summary>
                            {i18n.t('personality.sub.sociability.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.sociability.description')}
                          </p>
                        </Details>
                        <Details color="#e5264f">
                          <summary>
                            {i18n.t('personality.sub.socialBoldness.name')}
                          </summary>
                          <p>
                            {i18n.t(
                              'personality.sub.socialBoldness.description'
                            )}
                          </p>
                        </Details>
                        <Details color="#e5264f">
                          <summary>
                            {i18n.t('personality.sub.socialSelfEsteem.name')}
                          </summary>
                          <p>
                            {i18n.t(
                              'personality.sub.socialSelfEsteem.description'
                            )}
                          </p>
                        </Details>
                      </>
                    )}
                    {category === 'Honesty/Humility' && (
                      <>
                        <Details color="#5ba63d">
                          <summary>
                            {i18n.t('personality.sub.fairness.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.fairness.description')}
                          </p>
                        </Details>
                        <Details color="#5ba63d">
                          <summary>
                            {i18n.t('personality.sub.greedAvoidance.name')}
                          </summary>
                          <p>
                            {i18n.t(
                              'personality.sub.greedAvoidance.description'
                            )}
                          </p>
                        </Details>
                        <Details color="#5ba63d">
                          <summary>
                            {i18n.t('personality.sub.modesty.name')}
                          </summary>
                          <p>{i18n.t('personality.sub.modesty.description')}</p>
                        </Details>
                        <Details color="#5ba63d">
                          <summary>
                            {i18n.t('personality.sub.sincerity.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.sincerity.description')}
                          </p>
                        </Details>
                      </>
                    )}
                    {category === 'Openness to experience' && (
                      <>
                        <Details color="#2891cb">
                          <summary>
                            {i18n.t(
                              'personality.sub.aestheticAppreciation.name'
                            )}
                          </summary>
                          <p>
                            {i18n.t(
                              'personality.sub.aestheticAppreciation.description'
                            )}
                          </p>
                        </Details>
                        <Details color="#2891cb">
                          <summary>
                            {i18n.t('personality.sub.creativity.name')}
                          </summary>
                          <p>
                            {i18n.t('personality.sub.creativity.description')}
                          </p>
                        </Details>
                        <Details color="#2891cb">
                          <summary>
                            {i18n.t('personality.sub.inquisitiveness.name')}
                          </summary>
                          <p>
                            {i18n.t(
                              'personality.sub.inquisitiveness.description'
                            )}
                          </p>
                        </Details>
                        <Details color="#2891cb">
                          <summary>
                            {i18n.t('personality.sub.unconventionality.name')}
                          </summary>
                          <p>
                            {i18n.t(
                              'personality.sub.unconventionality.description'
                            )}
                          </p>
                        </Details>
                      </>
                    )}

                    <div className="categories">
                      {[
                        ...new Set(personality.map((item) => item.category))
                      ].map((item) => (
                        <ButtonsCategory
                          key={item}
                          type="button"
                          color={colorCategory(item)}
                          active={item === category}
                          onClick={() => setCategory(item)}
                          value={translateCategory(item)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {!modals.togglePersonality ? (
                <MdKeyboardArrowDown
                  size={25}
                  className="resume"
                  onClick={() =>
                    setModals({
                      ...modals,
                      togglePersonality: !modals.togglePersonality
                    })
                  }
                />
              ) : (
                <MdKeyboardArrowUp
                  size={25}
                  className="resume"
                  onClick={() =>
                    setModals({
                      ...modals,
                      togglePersonality: !modals.togglePersonality
                    })
                  }
                />
              )}
            </Chart>

            {journeys.length > 0 && (
              <Journey resume={modals.toggleJourney}>
                <SubTitle>
                  <span>
                    <FaMapSigns size={20} />
                  </span>
                  {i18n.t('pages.academic.titles.journey')}
                </SubTitle>

                <div className="modal">
                  {journeys &&
                    journeys
                      .filter(
                        (journey) => !journey.isComplete && journey.progress > 0
                      )
                      .map((journey) => (
                        <Card
                          key={journey.id}
                          progress={journey.progress}
                          disable={journey.executedTime === null}
                          onClick={() => {
                            setJourneyId(journey.id)
                            setTrailToggle(!trailToggle)
                          }}
                        >
                          <div className="inf">
                            <img
                              src={journey.imagePath || Default}
                              alt="logo"
                            />
                            <span>{`+${journey.points}  ${i18n.t(
                              'pages.academic.messages.points'
                            )}`}</span>
                          </div>

                          <pre>{phaseName(journey.phase)}</pre>
                          <h3>{journey.title}</h3>

                          <div className="progress">
                            <p>
                              {journey.executedTime > 0 && (
                                <span>
                                  {journey.progress > 100
                                    ? '100%'
                                    : journey.progress + '%'}
                                </span>
                              )}
                            </p>
                            <span className="time">
                              {`~${journey.estimatedHour} hrs ${journey.estimatedMin} min`}
                            </span>
                          </div>
                        </Card>
                      ))}

                  {journeys &&
                    journeys
                      .filter(
                        (journey) =>
                          !journey.isComplete && journey.progress === 0
                      )
                      .map((journey) => (
                        <Card
                          key={journey.id}
                          progress={journey.progress}
                          disable={journey.executedTime === null}
                          onClick={() => {
                            if (journey.executedTime !== null) {
                              setJourneyId(journey.id)
                              setTrailToggle(!trailToggle)
                            }
                          }}
                        >
                          <div className="inf">
                            <img
                              src={journey.imagePath || Default}
                              alt="logo"
                            />

                            <span>{`+${journey.points}  ${i18n.t(
                              'pages.academic.messages.points'
                            )}`}</span>
                          </div>

                          <pre>{phaseName(journey.phase)}</pre>
                          <h3>{journey.title}</h3>

                          <div className="progress">
                            <p>
                              {journey.executedTime > 0 && (
                                <span>
                                  {journey.progress > 100
                                    ? '100%'
                                    : journey.progress + '%'}
                                </span>
                              )}
                            </p>
                            <span className="time">
                              {`~${journey.estimatedHour} hrs ${journey.estimatedMin} min`}
                            </span>
                          </div>
                        </Card>
                      ))}

                  <div style={{ display: 'block', width: '100%' }}></div>

                  {journeys &&
                    journeys
                      .filter((journey) => journey.isComplete)
                      .reverse()
                      .map((journey) => (
                        <FullCard
                          key={journey.id}
                          onClick={() => {
                            setJourneyId(journey.id)
                            setTrailToggle(!trailToggle)
                          }}
                        >
                          <div className="journeyInfo">
                            <div className="journey-description">
                              <pre>{phaseName(journey.phase)}</pre>
                              <h3>{journey.title}</h3>
                            </div>
                            <div className="journeyDados">
                              <span className="points">
                                <strong>+{journey.score}</strong>
                                {' / '}
                                {journey.points}
                              </span>
                              <span className="hour">
                                {`~${journey.executedHour} hrs ${journey.executedMin} min`}
                              </span>
                            </div>
                          </div>
                          <IoMdCheckboxOutline
                            className="conclude"
                            size={18}
                            color="green"
                          />
                        </FullCard>
                      ))}
                </div>

                {!modals.toggleJourney ? (
                  <MdKeyboardArrowDown
                    size={25}
                    className="resume"
                    onClick={() =>
                      setModals({
                        ...modals,
                        toggleJourney: !modals.toggleJourney
                      })
                    }
                  />
                ) : (
                  <MdKeyboardArrowUp
                    size={25}
                    className="resume"
                    onClick={() =>
                      setModals({
                        ...modals,
                        toggleJourney: !modals.toggleJourney
                      })
                    }
                  />
                )}
              </Journey>
            )}

            <ContentPopUp toggle={trailToggle}>
              <Close>
                <MdClose
                  size={25}
                  onClick={() => setTrailToggle(!trailToggle)}
                />
              </Close>

              <div className="content">
                <table>
                  <thead>
                    <tr>
                      <th>{i18n.t('pages.academic.table.journey')}</th>
                      <th>{i18n.t('pages.academic.table.trail')}</th>
                      <th className="center">
                        {i18n.t('pages.academic.table.points')}
                      </th>
                      <th className="center">
                        {i18n.t('pages.academic.table.accuracy')}
                      </th>
                      <th className="center">
                        {i18n.t('pages.academic.table.duration')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {journeys.map((journey) =>
                      journey.trails.map(
                        (trail) =>
                          trail.isComplete &&
                          journey.id === journeyId && (
                            <tr key={trail.id}>
                              <td>{journey.title}</td>
                              <td>{trail.title}</td>
                              <td className="center">+{trail.score}</td>
                              <td className="center">
                                {trail.points > 0
                                  ? Math.round(
                                      (trail.score / trail.points) * 100
                                    )
                                  : trail.points}
                                %
                              </td>
                              <td className="center">
                                {`${
                                  trail.estimatedHour > 0
                                    ? trail.estimatedHour + 'hrs'
                                    : ''
                                } ${
                                  trail.estimatedMin > 0
                                    ? trail.estimatedMin + 'min'
                                    : ''
                                }`}
                              </td>
                            </tr>
                          )
                      )
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>Total</td>
                      <td className="center">
                        +
                        {journeys.map(
                          (journey) => journey.id === journeyId && journey.score
                        )}
                      </td>
                      <td className="center">
                        {journeys.map(
                          (journey) =>
                            journey.id === journeyId &&
                            Math.round((journey.score / journey.points) * 100)
                        )}
                        %
                      </td>
                      <td className="center">
                        {journeys.map(
                          (journey) =>
                            journey.id === journeyId &&
                            `${journey.executedHour} hrs ${journey.executedMin} min`
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </ContentPopUp>
          </Info>

          <PopUp ref={modalRef} width="auto">
            <Action>
              <h2>
                {i18n.t('pages.academic.messages.action')} &quot;{action}
                &quot;?
              </h2>
              <div className="warn">
                <Form ref={formRefAction} onSubmit={setStatus}>
                  <Radio
                    isRequired={true}
                    label={i18n.t('pages.academic.buttons.sendEmail')}
                    name="sendMail"
                    options={[
                      {
                        value: '1',
                        label: i18n.t('pages.academic.buttons.yes')
                      },
                      {
                        value: '0',
                        label: i18n.t('pages.academic.buttons.no')
                      }
                    ]}
                  />
                </Form>
                <small>{i18n.t('pages.academic.messages.alert')}</small>
              </div>
              <div className="buttons">
                <input
                  type="button"
                  value={String(i18n.t('pages.academic.buttons.yes'))}
                  style={{ backgroundColor: '#0761e7', color: '#fff' }}
                  onClick={() => formRefAction.current?.submitForm()}
                />
                <input
                  type="button"
                  value={String(i18n.t('pages.academic.buttons.no'))}
                  onClick={modalRef.current?.openModal}
                />
              </div>
            </Action>
          </PopUp>

          <PopUp ref={modalRefAdmin}>
            <Action>
              {adminAction === 'change' && (
                <>
                  <h2>Trocar candidato de programa</h2>
                  <div className="warn">
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <Select
                        isRequired={true}
                        label={i18n.t('pages.academic.buttons.whatProgram')}
                        name="program"
                        defaultValue=""
                        optionsObj={
                          program && program.length > 0
                            ? program.map((prg) => {
                                return {
                                  value: String(prg.id),
                                  label:
                                    '(' + prg.externalId + ') - ' + prg.title
                                }
                              })
                            : [
                                {
                                  value: '',
                                  label: ''
                                }
                              ]
                        }
                      />
                      <Radio
                        isRequired={true}
                        label={i18n.t('pages.academic.buttons.sendEmail')}
                        name="sendMail"
                        options={[
                          {
                            value: '1',
                            label: i18n.t('pages.academic.buttons.yes')
                          },
                          {
                            value: '0',
                            label: i18n.t('pages.academic.buttons.no')
                          }
                        ]}
                      />
                    </Form>
                  </div>
                </>
              )}
              {adminAction === 'generateSkill' && (
                <>
                  <h2>Skills</h2>
                  <div className="warn">
                    {i18n.t('pages.academic.messages.skillsWarn')}
                  </div>
                </>
              )}

              <div className="buttons">
                <input
                  type="button"
                  value={String(i18n.t('pages.academic.buttons.yes'))}
                  style={{ backgroundColor: '#0761e7', color: '#fff' }}
                  onClick={() => {
                    if (adminAction === 'change') formRef.current?.submitForm()
                    if (adminAction === 'generateSkill') generateSkills()
                  }}
                />
                <input
                  type="button"
                  value={String(i18n.t('pages.academic.buttons.no'))}
                  onClick={() => modalRefAdmin.current?.openModal()}
                />
              </div>
            </Action>
          </PopUp>

          <PopUp ref={modalRefRecruiter}>
            <Action>
              <h2>{i18n.t('pages.academic.select.options.change')}</h2>
              <div className="warn">
                {i18n.t('pages.academic.messages.recruiterWarn')}
              </div>

              <div className="buttons">
                <input
                  type="button"
                  value={String(i18n.t('pages.academic.buttons.yes'))}
                  style={{ backgroundColor: '#0761e7', color: '#fff' }}
                  onClick={() => setRecruiterStatus(userCandidateData.id)}
                />
                <input
                  type="button"
                  value={String(i18n.t('pages.academic.buttons.no'))}
                  onClick={() => modalRefRecruiter.current?.openModal()}
                />
              </div>
            </Action>
          </PopUp>
        </Container>
      )}
    </>
  )
}
