import { useRef, useEffect } from 'react'
import { Chart, ChartData, registerables } from 'chart.js'

import { Container } from './styles'

interface IProps {
  data: ChartData
}

export const BarChart = ({ data }: IProps) => {
  const chartRef = useRef<Chart | null>(null)

  Chart.register(...registerables)

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return
    const ctx = canvas.getContext('2d')

    if (ctx) {
      chartRef.current?.destroy()
      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          indexAxis: 'y',
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                display: false
              },
              ticks: {
                maxTicksLimit: 24
              },
              stackWeight: 1,
              stacked: false
            },
            x: {
              grid: {
                display: true
              },
              suggestedMax: 16
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      })
    }
  }

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data
      chartRef.current.update()
    }
  }, [data])

  return (
    <Container>
      <canvas ref={canvasCallback} id="chart"></canvas>
    </Container>
  )
}
