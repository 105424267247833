import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { utcToZonedTime } from 'date-fns-tz'
import { getTime } from 'date-fns'
import { useLocation } from 'react-router'

import { Navbar } from '../../components/Navbar'
import { BreadCrumbs } from '../../components/BreadCrumbs'
import { i18n } from '../../translate/i18n'
import { RequestManager } from '../../security/RequestManager'
import { Container, Content, Table } from './styles'

interface IProject {
  id: number
  title: string
}

interface ICandidate {
  projectId: number
  project: string
  id: number
  name: string
  email: string
  recruiterId: number
  recruiterName: string
  hiredDate: number
}

interface IRecruiter {
  id: number
  name: string
}

interface IRequest {
  projects: IProject[]
  recruiters: IRecruiter[]
  candidates: ICandidate[]
}

interface IFilter {
  projectId: number
  status: string
  startDate: number
  endDate: number
}

export const Recruiter = () => {
  // const [status, setStatus] = useState(['9', 'Declined'])
  const query = useLocation().search
  const id = Number(new URLSearchParams(query).get('project'))

  const [project, setProjects] = useState<IProject[]>([])
  const [professional, setProfessional] = useState<ICandidate[]>([])
  const [filter, setFilter] = useState<IFilter>({} as IFilter)

  useEffect(() => {
    RequestManager.requestAPI('/talent/pool/get-hired-candidates', {
      projectId: id
    }).then((data) => {
      if (!data.hasError) {
        const response = data.receivedData as IRequest

        setProjects(response.projects)
        setProfessional(
          response.candidates.map((candidate) => {
            if (
              response.projects.some(
                (project) => project.id === candidate.projectId
              )
            ) {
              candidate.project = response.projects.filter(
                (project) => project.id === candidate.projectId
              )[0].title
            }
            if (
              response.recruiters.some(
                (recruiter) => recruiter.id === candidate.recruiterId
              )
            ) {
              candidate.recruiterName = response.recruiters.filter(
                (recruiter) => recruiter.id === candidate.recruiterId
              )[0].name
            }
            return candidate
          })
        )
      }
    })

    if (Number(new URLSearchParams(query).get('project')) > 0)
      setFilter({
        ...filter,
        projectId: Number(new URLSearchParams(query).get('project'))
      })
  }, [])

  const filterChange = useCallback(
    (projectId: number, startDate: number, endDate: number) => {
      RequestManager.requestAPI('/talent/pool/get-hired-candidates', {
        projectId,
        startDate,
        endDate
      }).then((data) => {
        if (!data.hasError) {
          const response = data.receivedData as IRequest

          setProfessional(
            response.candidates.map((candidate) => {
              if (
                response.projects.some(
                  (project) => project.id === candidate.projectId
                )
              ) {
                candidate.project = response.projects.filter(
                  (project) => project.id === candidate.projectId
                )[0].title
              }
              if (
                response.recruiters.some(
                  (recruiter) => recruiter.id === candidate.recruiterId
                )
              ) {
                candidate.recruiterName = response.recruiters.filter(
                  (recruiter) => recruiter.id === candidate.recruiterId
                )[0].name
              }
              return candidate
            })
          )
        }
      })
    },
    []
  )

  return (
    <>
      <Navbar />
      <BreadCrumbs
        path={[
          {
            label: i18n.t('breadCrumbs.label.project'),
            route: 'project'
          }
        ]}
      />
      <Container>
        <Content>
          <div className="filters">
            <label htmlFor="program">
              {i18n.t('pages.recruiter.filters.project')}:
            </label>
            <select
              name="program"
              id="program"
              className="field-select"
              value={filter.projectId}
              onChange={(event) => {
                setFilter({
                  ...filter,
                  projectId: Number(event.target.value)
                })
                filterChange(
                  Number(event.target.value),
                  filter.startDate,
                  filter.endDate
                )
              }}
            >
              <option value="0">{i18n.t('pages.recruiter.labels.all')}</option>
              {project.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.title}
                </option>
              ))}
            </select>
            {/* <label htmlFor="status">Status:</label>
            <select
              name="status"
              id="status"
              className="field-select"
              onChange={(event) => {
                setFilter({
                  ...filter,
                  status: event.target.value
                })
              }}
            >
              {status.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select> */}
            <label htmlFor="startDate">
              {i18n.t('pages.recruiter.filters.startDate')}:
            </label>
            <input
              type="date"
              name="startDate"
              onChange={(event) => {
                setFilter({
                  ...filter,
                  startDate: getTime(
                    utcToZonedTime(new Date(event.target.value), 'UTC')
                  )
                })
                filterChange(
                  filter.projectId,
                  getTime(utcToZonedTime(new Date(event.target.value), 'UTC')),
                  filter.endDate
                )
              }}
            />
            <label htmlFor="endDate">
              {i18n.t('pages.recruiter.filters.endDate')}:
            </label>
            <input
              type="date"
              name="endDate"
              onChange={(event) => {
                setFilter({
                  ...filter,
                  endDate: getTime(
                    utcToZonedTime(new Date(event.target.value), 'UTC')
                  )
                })
                filterChange(
                  filter.projectId,
                  filter.startDate,
                  getTime(utcToZonedTime(new Date(event.target.value), 'UTC'))
                )
              }}
            />
          </div>
          <Table>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{i18n.t('pages.recruiter.labels.project')}</th>
                <th scope="col">{i18n.t('pages.recruiter.labels.name')}</th>
                <th scope="col">{i18n.t('pages.recruiter.labels.email')}</th>
                <th scope="col">
                  {i18n.t('pages.recruiter.labels.recruiter')}
                </th>
                <th scope="col">
                  {i18n.t('pages.recruiter.labels.hiredDate')}
                </th>
              </tr>
            </thead>
            {professional.length > 0 && (
              <>
                <tbody>
                  {professional.map((prof, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{prof.project}</td>
                      <td>
                        <div className="flex">
                          {prof.name}
                          <Link
                            to={`/professional/?id=${prof.id}&position=1&candidates=1`}
                            onClick={() => {
                              localStorage.setItem(
                                'TALENT_PROJECT_ID',
                                String(prof.projectId)
                              )
                            }}
                          >
                            <FaExternalLinkAlt color="#1E90FF" />
                          </Link>
                        </div>
                      </td>
                      <td>{prof.email}</td>
                      <td>{prof.recruiterName}</td>
                      <td>
                        <data value={prof.hiredDate}>
                          {new Date(prof.hiredDate).toLocaleDateString('pt-BR')}
                        </data>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={6}>Total {professional.length}</th>
                  </tr>
                </tfoot>
              </>
            )}
          </Table>
        </Content>
      </Container>
    </>
  )
}
