import styled from 'styled-components'

interface ICard {
  show?: boolean
  haveSkill: boolean
}

export const Container = styled.div<ICard>`
  position: relative;
  width: 100%;
  height: ${(props) =>
    !props.show ? (props.haveSkill ? '7rem' : '5.5rem') : 'max-content'};
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 0.5rem;

  .waiting {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .star-full,
  .star {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;

    &:hover {
      color: #ffd700 !important;
    }
  }

  .about {
    display: flex;
  }

  .description {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .options {
    display: flex;
    margin-right: 2rem;

    svg {
      margin-right: 1rem;
    }
  }

  .star-full {
    color: #ffd700 !important;
  }
`
