import {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  ReactNode
} from 'react'

import { Container, Content, Close } from './styles'

interface IProps {
  width?: string
  children?: ReactNode
}

export interface IPropsExport {
  openModal: () => void
}

const PopUp: ForwardRefRenderFunction<IPropsExport, IProps> = (
  { children, width },
  ref
) => {
  const [toggle, setToggle] = useState(false)

  const openModal = useCallback(() => {
    setToggle(!toggle)
  }, [toggle])

  useImperativeHandle(ref, () => {
    return {
      openModal
    }
  })

  return (
    <Container toggle={toggle}>
      <Content width={width}>
        <Close size={25} onClick={() => setToggle(!toggle)} />
        {children}
      </Content>
    </Container>
  )
}

export default forwardRef(PopUp)
