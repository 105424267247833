export const messages = {
  es: {
    translations: {
      nav: {
        pages: {
          program: 'Programas',
          logout: 'Salir',
          candidate: 'Candidatos',
          schedule: 'Calendario',
          project: 'Proyectos',
          hired: 'Contratados'
        },
        flags: {
          pt: 'Portugués',
          en: 'Inglés',
          es: 'Español'
        }
      },
      breadCrumbs: {
        label: {
          program: 'Programa',
          funnel: 'Embudo',
          rank: 'Rango',
          subscribers: 'Inscrito',
          project: 'Proyectos'
        }
      },
      pages: {
        user: {
          title: 'Cambiar contraseña',
          fields: {
            pwd: 'Nueva contraseña',
            confirmPwd: 'Repita la contraseña',
            oldPwd: 'Contraseña actual'
          },
          typeError: {
            pwd: 'Introduzca una nueva contraseña',
            confirmPwd: 'La contraseña no coincide con la nueva contraseña',
            currentPwd: 'Introducir la contraseña actual'
          },
          messages: {
            success: 'Contraseña alterada con éxito',
            error:
              'No se puede cambiar la contraseña, póngase en contacto con el soporte'
          },
          buttons: {
            save: 'Salvar'
          }
        },
        programs: {
          titles: {
            app: 'Crear un nuevo programa',
            close: 'Cerrado'
          },
          messages: {
            create: 'Creado',
            errorProgramCreate: '¡Error al crear el programa!',
            successProgramCreate: '¡Programa creado con éxito!'
          },
          labels: {
            add: 'para agregar',
            program: 'Programa',
            profile: 'Perfil',
            externalId: 'Identificación externa',
            target: 'Objetivo',
            start: 'Comienzo',
            end: 'Final',
            subscribers: 'Inscrito',
            finish: 'Terminar'
          },
          buttons: {
            create: 'Crear'
          },
          tooltips: {
            add: 'Agregar Programa',
            view: 'Ver Programa',
            edit: 'Editar Programa',
            subscribers: 'Aprobar Entradas',
            finish: 'Final del Programa'
          },
          typeError: {
            title: '¡Se requiere el nombre del programa!',
            profile: '¡Se requiere el nombre del perfil!',
            externalId: '¡Se requiere el Identificación externa!',
            target: '¡Número de vacantes requeridas!',
            targetMin: 'Mínimo 1',
            startDate: '¡Fecha de inicio requerida!',
            endDate: '¡Se requiere la fecha de finalización!'
          }
        },
        profiles: {
          buttons: {
            select: 'Seleccione'
          },
          profile: {
            buttons: {
              create: 'Criar Programa'
            }
          }
        },
        funnel: {
          messages: {
            subscription: 'Inscrito',
            approved: 'Aprobado',
            test: 'Prueba',
            interview: 'Entrevista',
            assessment: 'Evaluación',
            start: 'comienzo',
            end: 'final'
          },
          status: {
            concluded: 'Terminado',
            pending: 'Pendiente'
          }
        },
        academic: {
          titles: {
            personality: 'Personalidad',
            journey: 'Viaje'
          },
          messages: {
            points: 'puntos',
            viewMore: 'más',
            viewLess: 'menos',
            approval: 'Aprovação',
            disapproval: 'Reprovação',
            action: '¿Quieres continuar con la acción de',
            alert:
              'Atención una vez confirmada la acción, no será posible revertirla',
            success: '¡Cambio completado con éxito!',
            error: '¡No se pudo completar la acción!',
            skillsWarn: '¿Quieres generar habilidades para este candidato?',
            recruiterWarn: '¿Quieres cambiar el estado del candidato?',
            noChanged:
              '¡No se puede cambiar el programa, póngase en contacto con el soporte!',
            successChanged: 'Programa cambiado con éxito',
            successSkills: '¡Skills generadas con éxito'
          },
          questions: {
            phone: 'Teléfono',
            email: 'Email',
            linkedIn: 'LinkedIn',
            trailHead: 'Trailhead',
            taxId: 'Taxid',
            birthDate: 'Fecha de nacimiento',
            gender: 'Género',
            englishLevel: 'Nivel de inglés',
            currentEmployment: '¿Estas trabajando?',
            educationalLevel: 'Educación',
            developmentExperience: '¿Tiene experiencia en desarrollo?',
            professionalExperience: 'Experiencia profesional'
          },
          select: {
            options: {
              change: 'Cambiar Status',
              interview: 'Entrevistas',
              hired: 'Contratado',
              rejected: 'Rechazado'
            }
          },
          buttons: {
            challenge: 'Enviar Desafío',
            approve: 'Aprobar',
            fail: 'Fallar',
            save: 'Salvar',
            yes: 'Sí',
            no: 'Não',
            notes: 'Comentarios',
            changeProgram: 'Cambio del Programa',
            generateSkill: 'Generar Skills',
            summary: 'Resumen',
            detail: 'Detalle',
            whatProgram: '¿Para que Programa?',
            sendEmail:
              '¿Quiere notificar al candidato por correo electrónico del cambio?'
          },
          table: {
            journey: 'Viaje',
            trail: 'Sendero',
            points: 'Puntos',
            accuracy: 'Precisión',
            duration: 'Duración'
          },
          fields: {
            required: 'Campo obligatorio'
          }
        },
        rank: {
          titles: {
            ranking: 'Posición de Clasificación',
            sort: 'Ordenar por',
            filter: 'Filtrado por',
            finish: 'Final del programa'
          },
          messages: {
            filter: 'Filtrado por',
            search: 'Su búsqueda arrojó [?] entradas',
            day: 'dias',
            points: 'Puntos',
            searchBy: 'Buscar por correo electrónico o nombre',
            notFound: 'Aún no hay suscriptores para este programa.',
            attention: 'Atención Rango desactualizado',
            attentionMessage:
              'Hay un nuevo cambio de rango, haga clic en el botón de abajo para actualizar la página.',
            finishProgram: '¿Quieres terminar el programa [?]?',
            warnFinishProgram:
              'Esta acción es irreversible y puede afectar algunas funciones del sistema, como la aprobación de candidatos, el registro, etc.'
          },
          buttons: {
            save: 'Salvar',
            reset: 'Reiniciar',
            load: 'Carga más...',
            close: 'Confirmar',
            cancel: 'Cancelar'
          }
        },
        subscribers: {
          messages: {
            search: 'Su búsqueda arrojó [?] entradas',
            notSubscribers: 'Aún no hay suscriptores para este programa.',
            notFound: 'No hay suscriptores para estos filtros.',
            filterBy: 'Filtrado por',
            searchBy: 'Buscar por correo electrónico o nombre'
          },
          cardFilters: {
            english: 'Inglés: ',
            schooling: 'Enseñanza: ',
            region: 'Región: ',
            working: 'Laboral: ',
            programing: 'Programación: ',
            salesforce: 'Salesforce: '
          },
          buttons: {
            load: 'Carga más...'
          },
          tooltips: {
            approve: 'Aprobado',
            fail: 'Desaprobado',
            pending: 'Pendiente'
          }
        },
        subscriber: {
          fields: {
            student: 'Candidato',
            taxId: 'Taxid',
            email: 'Email',
            phone: 'Teléfono',
            gender: 'Género',
            region: 'Región',
            city: 'Ciudad',
            birthDate: 'Fecha de nacimiento',
            program: 'Programa',
            ethnicity: 'Etnia',
            withDisability: '¿Tienes alguna discapacidad?',
            disabilityDescription: '¿Cuales?',
            educationalLevel: 'Educación',
            course: 'Curse',
            university: 'Universidad',
            currentSemester: 'Semestre actual',
            graduationYear: 'Año previsto de graduación',
            englishLevel: 'Nivel de inglés',
            lastSignOnInDays: 'Última vez conectado (a)',
            mentor: 'Mentor',
            linkedin: 'LinkedIn',
            currentEmployment: '¿Está trabajando?',
            developmentExperience: 'Experiencia de desarrollo',
            industryExperience: 'Experiencia industrial',
            salesforceExperience: 'Experiencia en Salesforce',
            trailheadProfile: 'Perfil Trailhead',
            recommendedBy: 'Recomendado por',
            timeAvailability: '¿Tiene disponibilidad de 16 horas semanales?',
            haveComputer: '¿Tienes una computadora con internet?',
            professionalExperience: 'Experiencia profesional',
            whySubscribed: '¿Por qué te registraste?',
            creationDate: 'Fecha de creación',
            notes: 'Comentarios',
            approveOrFail: 'Aprobar o Rechazar'
          },
          buttons: {
            pending: 'Aprobación pendiente',
            approve: 'Aprobar',
            fail: 'Fallar',
            save: 'Guardar'
          },
          messages: {
            noNotes: '¡No se han agregado anotaciones para este candidato!',
            errorTitle: 'Error al aprobar al usuario',
            errorMessage:
              '¡Póngase en contacto con el administrador del sistema para obtener una solución!',
            codeMessage: 'Rrro',
            success: '¡Cambio completado con éxito!',
            error: '¡No se pudo completar la acción!',
            days: 'Dias',
            day: 'Día',
            today: 'Hoy'
          },
          gender: {
            man: 'Masculino',
            woman: 'Femenino',
            other: 'Otro'
          }
        },
        notFound: {
          messages: {
            warning:
              'Se requiere autenticación, será redirigido a la página de inicio de sesión en un momento.'
          }
        },
        interview: {
          titles: {
            questions: 'Preguntas'
          }
        },
        admin: {
          candidates: {
            messages: {
              placeholder: 'Ingrese su nombre o correo electrónico',
              notFound: 'No hay candidatos para esta encuesta.',
              found: 'Su búsqueda encontró [*] resultados'
            }
          }
        },
        project: {
          titles: {
            project: 'Proyecto'
          },
          fields: {
            labels: {
              profile: 'Perfil',
              projectTitle: 'Título del Proyecto',
              location: 'Localización',
              workModel: 'Modelo de Trabajo',
              target: 'Vacantes',
              message: 'Mensaje',
              start: 'Comienzo',
              end: 'Fin',
              create: 'Crear proyecto'
            },
            placeholders: {
              location: 'Curitiba, PR',
              message:
                'Este mensaje será enviado de forma predeterminada al profesional seleccionado.'
            },
            options: {
              workModel: {
                remote: 'Remoto',
                hybrid: 'Híbrido',
                presential: 'Presencial'
              }
            }
          },
          messages: {
            success: 'Proyecto creado con exito',
            warning: 'Atención'
          },
          buttons: {
            create: 'Crear'
          }
        },
        pool: {
          titles: {
            contact: 'Contacto',
            sort: 'Ordenar por',
            filter: 'Filtrado por'
          },
          select: {
            all: 'Todos',
            selected: 'Seleccionados',
            invited: 'Invitados',
            scheduled: 'Reservados',
            interviewee: 'Entrevistados',
            hired: 'Contratados',
            declined: 'Rechazados',
            released: 'Liberados'
          },
          fields: {
            labels: {
              jobTitle: 'Título Profesional',
              workModel: 'Modelo del Trabajo',
              location: 'Localización',
              message: 'Mensaje'
            },
            options: {
              workModel: {
                remote: 'Remoto',
                hybrid: 'Híbrido',
                presential: 'Presencial'
              }
            },
            required: {
              jobTitle: '¡El campo del "Título Profesional" es obligatorio!',
              workModel: '¡El campo del "Modelo del Trabajo" es obligatorio!',
              location: '¡El campo del "Localización" es obligatorio!',
              message: '¡El campo del "Mensaje" es obligatorio!'
            }
          },
          buttons: {
            send: 'Enviar',
            save: 'Salvar',
            reset: 'Reiniciar'
          },
          messages: {
            filter: 'Filtrado por',
            points: 'Puntos',
            notFound: 'Aún no hay profesionales para este programa.',
            attention: 'Atención Rango desactualizado',
            attentionMessage:
              'Hay un nuevo cambio de rango, haga clic en el botón de abajo para actualizar la página.',
            updateAt: 'actualizado hace [?]'
          },
          notify: {
            sentEmail: 'Email sent!',
            notSentEmail:
              '¡No se puede enviar el correo electrónico, comuníquese con el ADMIN!'
          },
          tooltip: {
            video: 'Video',
            remote: 'Remoto',
            presential: 'Presencial',
            contractMode: 'PJ',
            invite: 'Invitación'
          }
        },
        recruiter: {
          filters: {
            project: 'Proyecto',
            startDate: 'Comienzo',
            endDate: 'Final'
          },
          labels: {
            all: 'Todos',
            project: 'Proyecto',
            name: 'Nombre',
            email: 'Email',
            recruiter: 'Reclutador(a)',
            hiredDate: 'Contratado',
            total: 'Total'
          }
        }
      },
      filters: {
        fields: {
          salesforceExperience: '¿Tiene conocimientos de Salesforce?',
          developmentExperience: '¿Tiene experiencia en desarrollo?',
          englishLevel: 'Nivel de inglés',
          educationalLevel: 'Educación',
          region: 'Región',
          gender: 'Género',
          currentEmployment: '¿Estas trabajando?',
          ethnicity: 'Etnia',
          withDisability: '¿Tienes alguna discapacidad?'
        },
        phaseName: {
          subscription: 'Inscripciones',
          discovery: 'Discovery',
          ignite: 'Ignite',
          accelerate: 'Accelerate'
        },
        stepName: {
          training: 'capacitación',
          assessment: 'evaluación',
          exam: 'prueba',
          agoge: 'agogê',
          interview: 'entrevista',
          approval: 'aprobación'
        },
        type: {
          completed: 'completo',
          approved: 'aprobar',
          pending: 'pendiente',
          rejected: 'rechazado'
        }
      },
      calendar: {
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        list: 'Lista',
        save: 'Guardar',
        ctrlZ: 'Deshacer',
        allDay: 'Todo el día',
        available: 'Disponible',
        messages: {
          title: 'Proporcionar horario',
          description:
            '¡Esto hará que los candidatos puedan programar la entrevista en los horarios disponibles!'
        },
        buttons: {
          save: 'Publicar horario',
          cancel: 'Cancelar'
        }
      },
      person: {
        male: 'Masculino',
        female: 'Mujer',
        other: 'Otro'
      },
      personality: {
        opennessToExperience: 'Abierto a la experiencia',
        agreeableness: 'Amabilidad',
        emotionality: 'Emocionalidad',
        conscientiousness: 'Escrupulosidad',
        extroversion: 'Extroversión',
        honestyHumility: 'Honestidad/Humildad',
        description: {
          opennessToExperience:
            'Las personas con puntuaciones muy altas en la escala de Apertura a la Experiencia se absorben en la belleza del arte y la naturaleza, son curiosas sobre varios dominios del conocimiento, usan su imaginación libremente en la vida cotidiana y se interesan por ideas o personas inusuales. Por el contrario, las personas con puntuaciones muy bajas en esta escala no se sienten impresionadas por la mayoría de las obras de arte, sienten poca curiosidad intelectual, evitan las actividades creativas y sienten poca atracción por ideas que pueden parecer radicales o poco convencionales.',
          agreeableness:
            'Las personas con puntuaciones muy altas en la escala de amabilidad perdonan los males que sufrieron, son indulgentes al juzgar a los demás, están dispuestas a comprometerse y cooperar con los demás y pueden controlar fácilmente su temperamento. Por el contrario, las personas con puntuaciones muy bajas en esta escala guardan rencor a quienes las han dañado, son bastante críticas con las deficiencias de los demás, son obstinadas a la hora de defender su punto de vista y se enojan fácilmente en respuesta al maltrato.',
          extroversion:
            'Las personas con puntuaciones muy altas en la escala de extraversión se sienten positivamente consigo mismas, se sienten seguras al dirigir o dirigirse a grupos de personas, disfrutan de las reuniones e interacciones sociales y experimentan sentimientos positivos de entusiasmo y energía. Por el contrario, las personas con puntuaciones muy bajas en esta escala se consideran impopulares, se sienten incómodas cuando son el centro de atención social, son indiferentes a las actividades sociales y se sienten menos animadas y optimistas que los demás.',
          conscientiousness:
            'Las personas con puntajes muy altos en la escala de Conciencia organizan su tiempo y su entorno físico, trabajan de manera disciplinada hacia sus metas, luchan por la precisión y perfección en sus tareas y deliberan con cuidado al tomar decisiones. Por el contrario, las personas con puntuaciones muy bajas en esta escala tienden a no preocuparse por los entornos u horarios ordenados, evitan tareas difíciles o metas desafiantes, están satisfechas con trabajos que contienen algunos errores y toman decisiones por impulso o con poca reflexión.',
          emotionality:
            'Las personas con puntuaciones muy altas en la escala de Emocionalidad experimentan miedo a los peligros físicos, experimentan ansiedad en respuesta al estrés de la vida, sienten la necesidad de apoyo emocional de los demás y sienten empatía y vínculos sentimentales con los demás. Por el contrario, las personas con puntuaciones muy bajas en esta escala no se ven disuadidas por la perspectiva de daño físico, sienten poca preocupación incluso en situaciones estresantes, tienen poca necesidad de compartir sus preocupaciones con los demás y se sienten emocionalmente desapegadas de los demás.',
          honestyHumility:
            'Las personas con puntuaciones muy altas en la escala Honestidad-Humildad evitan manipular a los demás para su beneficio personal, sienten poca tentación de romper las reglas, no están interesadas en la riqueza y los lujos lujosos y no sienten ningún derecho especial a un estatus social elevado. Por el contrario, las personas con puntajes muy bajos en esta escala adularán a los demás para que obtengan lo que quieren, se inclinan a romper las reglas para obtener ganancias personales, están motivadas por ganancias materiales y sienten un fuerte sentido de importancia personal.'
        },
        sub: {
          flexibility: {
            name: 'Flexibilidad',
            description:
              'La escala de flexibilidad evalúa la disposición de uno a comprometerse y cooperar con los demás. Los que obtienen puntajes bajos son considerados tercos y están dispuestos a discutir, mientras que los que obtienen puntajes altos evitan las discusiones y se adaptan a las sugerencias de los demás, incluso cuando estas pueden no ser razonables.'
          },
          forgiveness: {
            name: 'Perdon',
            description:
              'La escala de Perdón evalúa la disposición de uno a sentir confianza y agrado por aquellos que pueden haberle causado daño. Los que obtienen puntuaciones bajas suelen guardar rencor a quienes los han ofendido, mientras que los que obtienen puntuaciones altas suelen estar dispuestos a volver a confiar en los demás y a restablecer relaciones amistosas después de haber sido maltratados.'
          },
          gentleness: {
            name: 'Dulzura',
            description:
              'La escala de Gentileza evalúa la tendencia a ser suave e indulgente en el trato con otras personas. Los que obtienen puntajes bajos tienden a ser críticos en sus evaluaciones de los demás, mientras que los que obtienen puntajes altos son reacios a juzgar a los demás con dureza.'
          },
          patience: {
            name: 'Paciencia',
            description:
              'La escala de Paciencia evalúa la tendencia a mantener la calma en lugar de enojarse. Los que obtienen puntuaciones bajas tienden a perder los estribos rápidamente, mientras que los que obtienen puntuaciones altas tienen un umbral alto para sentir o expresar enojo.'
          },
          diligence: {
            name: 'Diligencia',
            description:
              'La escala de Diligencia evalúa la tendencia a trabajar duro. Los que obtienen puntajes bajos tienen poca autodisciplina y no están fuertemente motivados para lograr logros, mientras que los que obtienen puntajes altos tienen una fuerte "ética de trabajo" y están dispuestos a esforzarse.'
          },
          organization: {
            name: 'Organización',
            description:
              'La escala de Organización evalúa la tendencia a buscar el orden, particularmente en el entorno físico. Los que obtienen puntuaciones bajas tienden a ser descuidados y al azar, mientras que los que obtienen puntuaciones altas mantienen las cosas en orden y prefieren un enfoque estructurado de las tareas.'
          },
          perfectionism: {
            name: 'Perfeccionismo',
            description:
              'La escala de perfeccionismo evalúa la tendencia a ser minucioso y preocupado por los detalles. Los que obtienen puntajes bajos toleran algunos errores en su trabajo y tienden a descuidar los detalles, mientras que los que obtienen puntajes altos revisan cuidadosamente los errores y las posibles mejoras.'
          },
          prudence: {
            name: 'Prudencia',
            description:
              'La escala de Prudencia evalúa la tendencia a deliberar con cuidado e inhibir los impulsos. Los que obtienen puntajes bajos actúan por impulso y tienden a no considerar las consecuencias, mientras que los que obtienen puntajes altos consideran sus opciones con cuidado y tienden a ser cautelosos y autocontrolados.'
          },
          anxiety: {
            name: 'Ansiedad',
            description:
              'La escala de ansiedad evalúa la tendencia a preocuparse en una variedad de contextos. Los que obtienen puntajes bajos sienten poco estrés en respuesta a las dificultades, mientras que los que obtienen puntajes altos tienden a preocuparse incluso por problemas relativamente menores.'
          },
          dependence: {
            name: 'Dependencia',
            description:
              'La escala de dependencia evalúa la necesidad de apoyo emocional de los demás. Los que obtienen un puntaje bajo se sienten seguros de sí mismos y pueden lidiar con los problemas sin ayuda o consejo, mientras que los que obtienen un puntaje alto quieren compartir sus dificultades con aquellos que les brinden aliento y consuelo.'
          },
          fearfulness: {
            name: 'Espanto',
            description:
              'La escala de miedo evalúa la tendencia a experimentar miedo. Los que obtienen puntuaciones bajas sienten poco miedo a sufrir lesiones y son relativamente duros, valientes e insensibles al dolor físico, mientras que los que obtienen puntuaciones altas se inclinan fuertemente a evitar el daño físico.'
          },
          sentimentality: {
            name: 'Sentimentalismo',
            description:
              'La escala de Sentimentalismo evalúa la tendencia a sentir fuertes lazos emocionales con los demás. Los que obtienen puntajes bajos sienten poca emoción cuando se despiden o en reacción a las preocupaciones de los demás, mientras que los que obtienen puntajes altos sienten fuertes lazos emocionales y una sensibilidad empática hacia los sentimientos de los demás.'
          },
          liveliness: {
            name: 'Vivacidad',
            description:
              'La escala de vivacidad evalúa el entusiasmo y la energía típicos de uno. Los que obtienen puntuaciones bajas tienden a no sentirse especialmente alegres o dinámicos, mientras que los que obtienen puntuaciones altas suelen experimentar una sensación de optimismo y buen humor.'
          },
          sociability: {
            name: 'Sociabilidad',
            description:
              'La escala de Sentimentalismo evalúa la tendencia a sentir fuertes lazos emocionales con los demás. Los que obtienen puntajes bajos sienten poca emoción cuando se despiden o en reacción a las preocupaciones de los demás, mientras que los que obtienen puntajes altos sienten fuertes lazos emocionales y una sensibilidad empática hacia los sentimientos de los demás.'
          },
          socialBoldness: {
            name: 'Audacia social',
            description:
              'La escala de audacia social evalúa la comodidad o la confianza en una variedad de situaciones sociales. Los que obtienen puntajes bajos se sienten tímidos o incómodos en posiciones de liderazgo o cuando hablan en público, mientras que los que obtienen puntajes altos están dispuestos a acercarse a extraños y están dispuestos a hablar en el entorno del grupo.'
          },
          socialSelfEsteem: {
            name: 'Autoestima social',
            description:
              'La escala de Autoestima Social evalúa la tendencia a tener una autoestima positiva, particularmente en contextos sociales. Los puntajes altos generalmente están satisfechos consigo mismos y consideran que tienen cualidades agradables, mientras que los puntajes bajos tienden a tener un sentido de inutilidad personal y a verse a sí mismos como impopulares.'
          },
          fairness: {
            name: 'Justicia',
            description:
              'La escala de equidad evalúa la tendencia a evitar el fraude y la corrupción. Los que obtienen un puntaje bajo están dispuestos a ganar haciendo trampas o robando, mientras que los que obtienen un puntaje alto no están dispuestos a aprovecharse de otras personas o de la sociedad en general.'
          },
          greedAvoidance: {
            name: 'Evitación de la codicia',
            description:
              'La escala de Evitación de la Codicia evalúa la tendencia a no estar interesado en poseer riquezas abundantes, artículos de lujo y signos de alto estatus social. Los que obtienen un puntaje bajo quieren disfrutar y mostrar riqueza y privilegios, mientras que los que obtienen un puntaje alto no están especialmente motivados por consideraciones monetarias o de estatus social.'
          },
          modesty: {
            name: 'Modestia',
            description:
              'La escala de Modestia evalúa la tendencia a ser modesto y sin pretensiones. Los que obtienen un puntaje bajo se consideran superiores y tienen derecho a privilegios que otros no tienen, mientras que los que obtienen un puntaje alto se ven a sí mismos como personas comunes sin ningún derecho a un trato especial.'
          },
          sincerity: {
            name: 'Sinceridad',
            description:
              'La escala de Sinceridad evalúa la tendencia a ser genuino en las relaciones interpersonales. Los que obtienen puntuaciones bajas halagarán a los demás o fingirán que les agradan para obtener favores, mientras que los que obtendrán puntuaciones altas no estarán dispuestos a manipular a los demás.'
          },
          aestheticAppreciation: {
            name: 'Apreciación estética',
            description:
              'La escala de Apreciación Estética evalúa el disfrute de la belleza en el arte y en la naturaleza. Los que obtienen puntajes bajos tienden a no quedar absortos en obras de arte o en maravillas naturales, mientras que los que obtienen puntajes altos tienen una gran apreciación de las diversas formas de arte y de las maravillas naturales.'
          },
          creativity: {
            name: 'Creatividad',
            description:
              'La escala de creatividad evalúa la preferencia de uno por la innovación y la experimentación. Los puntajes bajos tienen poca inclinación por el pensamiento original, mientras que los puntajes altos buscan activamente nuevas soluciones a los problemas y se expresan en el arte.'
          },
          inquisitiveness: {
            name: 'Curiosidad',
            description:
              'La escala de Inquisitividad evalúa la tendencia a buscar información y experimentar con el mundo natural y humano. Los que obtienen puntajes bajos tienen poca curiosidad por las ciencias naturales o sociales, mientras que los que obtienen puntajes altos leen mucho y están interesados en viajar.'
          },
          unconventionality: {
            name: 'Poco convencionalidad',
            description:
              'La escala de no convencionalidad evalúa la tendencia a aceptar lo inusual. Los puntajes bajos evitan a las personas excéntricas o disconformes, mientras que los puntajes altos son receptivos a ideas que pueden parecer extrañas o radicales.'
          }
        }
      }
    }
  }
}
