import { SiWebauthn } from 'react-icons/si'
import { ImWarning } from 'react-icons/im'
import { i18n } from '../../translate/i18n'

import { RequestManager } from '../../security/RequestManager'

import { Container, Content } from './styles'

export const Auth = () => {
  setTimeout(() => {
    RequestManager.goBackToLogin()
  }, 2000)

  return (
    <Container>
      <Content>
        <h2>
          <ImWarning /> {i18n.t('pages.notFound.messages.warning')}
        </h2>
        <SiWebauthn size={500} />
      </Content>
    </Container>
  )
}
