import styled, { css } from 'styled-components'

interface IColor {
  opacity: number
  type: 'green' | 'red' | 'orange' | 'lightGreen' | 'lightRed' | 'lightOrange'
}

interface IProps {
  width?: number
  active?: boolean
  toggle?: boolean
  isComplete?: boolean
}

interface ISelected {
  active: boolean
  canDrop?: boolean
  over?: boolean
}

const colorTypeVariations = {
  green: css`
    background: #006400;
  `,
  red: css`
    background: #ff0d0d;
  `,
  lightGreen: css`
    background: #32cd32;
  `,
  lightRed: css`
    background: #ff4e11;
  `,
  orange: css`
    background: #ff8e15;
  `,
  lightOrange: css`
    background: #fab733;
  `
}

export const Rank = styled.span<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.isComplete ? '#1373fc' : '#ccc')};
  color: #fff;
  padding: 1rem 1.4rem;
  border-radius: 4px;
  min-width: 4.5rem;
`

export const Container = styled.div`
  display: flex;
  width: 100%;

  padding: 1rem;

  .max {
    cursor: pointer;
  }

  .points {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem 1rem;
    border-right: 1px dotted #ccc;
  }

  .numberPoints {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .textPoints {
    color: #ccc;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  .contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem 1rem;
    width: max-content;
    min-width: 200px;
    text-transform: capitalize;
  }

  .colum {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100vw;
  }

  .tog {
    position: fixed;
    top: calc(100vh * 0.5);
    left: 0;
    height: 100vh;
    color: #1373fc;
    z-index: 10;
    opacity: 0.2;

    &:hover {
      opacity: 1;
    }

    svg:hover {
      cursor: pointer;
      color: #4b5c6b;
    }
  }

  .tf {
    width: 2rem;
    color: #1373fc;
    cursor: pointer;
    margin-left: 1rem;

    &:hover {
      color: #4b5c6b;
    }
  }

  .titleDomain {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    text-transform: capitalize;
  }

  .df {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .hoverEffect {
    cursor: pointer;
    &:hover {
      color: #ccc !important;
    }
  }

  .interested {
    margin-bottom: 1rem;
  }
`

export const Content = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.toggle ? '73%' : '100%')};
  position: relative;
  padding: 0 0.5rem;

  h1 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #4b5c6b;
    letter-spacing: -1px;
    margin: 0;
    margin-bottom: 0.5rem;
    padding: 0;
  }

  h4 {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-weight: 400;
  }

  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #1373fc;
      color: #fff;
      font-size: 0.8rem;
      width: 150px;
      border-radius: 20px;
      cursor: pointer;
      padding: 0.3rem 0.5rem;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .external {
    margin-left: 0.5rem;
    color: #4b5c6b;

    &:hover {
      color: #1373fc;
    }
  }

  .schedule {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;

    z-index: 2;
    cursor: pointer;
  }
`

export const TitleSidebar = styled.h2`
  display: flex;
  align-items: center;
  width: 100%;
  color: #1373fc;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;

  .resume {
    display: flex;
    align-items: flex-end;
    padding-top: 0.3rem;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5rem;
  position: relative;

  svg.arrow {
    position: absolute;
    bottom: 0;
    right: 50%;
    color: #ddd !important;
    cursor: pointer;

    &:hover {
      color: #1373fc !important;
    }
  }
`

export const Domain = styled.span<IColor>`
  p {
    display: flex;
    justify-content: center;
    min-width: 4rem;
    min-height: 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    color: #fff;
    border-radius: 1rem;
    text-transform: none;
    ${(props) => colorTypeVariations[props.type]}
    opacity: ${(props) => (props.opacity <= 0.5 ? 0.5 : props.opacity)};
    text-shadow: black 0.1em 0.1em 0.2em;
  }
`

export const DomainRequired = styled.span<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  height: 0.3rem;
  width: ${(props) => props.width}%;
  color: white;
  border-radius: 1px;
  background: #4b5c6b;
  margin-bottom: 0.5rem;
`

export const Range = styled.input`
  display: flex;
  outline: 0;
  border: 0;
  width: 100%;
  max-width: 100%;
  transition: box-shadow 0.2s ease-in-out;
  background-color: #ddd !important;

  // Chrome
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    & {
      overflow: hidden;
      height: 1rem;
      -webkit-appearance: none;
      background-color: #fff;
    }
    &::-webkit-slider-runnable-track {
      height: 1rem;
      -webkit-appearance: none;
      color: #444;
      transition: box-shadow 0.2s ease-in-out;
    }
    &::-webkit-slider-thumb {
      width: 1rem;
      -webkit-appearance: none;
      height: 1rem;
      cursor: ew-resize;
      background: #fff;
      box-shadow: -540px 0 0 535px #1373fc, inset 0 0 0 40px #1373fc;
      border: 2px solid #fff;
      border-radius: 50%;
      transition: box-shadow 0.2s ease-in-out;
      position: relative;
    }
    &:active::-webkit-slider-thumb {
      background: #1373fc;
      box-shadow: -340px 0 0 335px #1373fc, inset 0 0 0 3px #1373fc;
    }

    // Firefox
    &::-moz-range-progress {
      background-color: #1373fc;
    }
    &::-moz-range-track {
      background-color: #1373fc;
    }
    // IE
    &::-ms-fill-lower {
      background-color: #1373fc;
    }
    &::-ms-fill-upper {
      background-color: #1373fc;
    }
  }
`

export const Sidebar = styled.div<IProps>`
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  width: 35%;
  height: 100vh;
  flex-direction: column;
  flex-grow: 1;
  position: sticky;
  top: 1rem;

  border-radius: 4px;
  overflow: auto;

  .bg {
    background: #fff;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    padding: 1rem;
  }

  .position {
    display: flex;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 1.2rem;
    }

    select {
      display: flex;
      width: 100%;
      min-height: 2.4rem;
      border-radius: 4px;
      border: 1px solid #ddd;
      margin-left: 0.5rem;

      &:focus-visible {
        border: 1px solid #6495ed;
        outline: none;
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  button {
    width: 48%;
    background-color: #1373fc;
    color: #fff;
    border: none;
    border-radius: 2px;
    padding: 0.3rem;

    &:hover {
      filter: brightness(0.8);
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
  }
`

export const NeedRefresh = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.8);

  .needRefresh {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #fff;
    border-radius: 4px;
    padding: 2rem;
  }

  h1 {
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0 2rem;
  }

  button {
    width: 20%;
    background-color: #1373fc;
    color: #fff;
    border: none;
    border-radius: 2px;
    padding: 0.3rem;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const Selection = styled.div`
  position: sticky;
  top: 1rem;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100vh;

  button {
    background: #1373fc;
    color: #fff;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    margin-top: 0.5rem;
  }

  .bg {
    background: #fff;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    padding: 1rem;
  }

  .selected {
    width: 100%;
    margin-top: 1rem;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const Selected = styled.div<ISelected>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 5rem;
  border-radius: 4px;
  margin-top: 0.5rem;

  ${(props) =>
    props.active
      ? css`
          color: #aaa;
          border: 1px solid #aaa;

          .bin {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;

            &:hover {
              filter: brightness(0.8);
            }
          }

          .bin:hover {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            cursor: pointer;
          }
        `
      : css`
          border: 1px dashed #ddd;
          color: #ddd;
        `};

  & + & {
    margin-top: 0.5rem;
  }

  ${(props) =>
    props.canDrop &&
    css`
      color: #4b5c6b;
      border: 2px dashed #4b5c6b;
    `}

  ${(props) =>
    props.over &&
    css`
      color: #32cd32;
      border: 2px dashed #32cd32;
    `}
`

export const ContentPopUp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: orange;
    margin-bottom: 1rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #eee;
    padding: 1rem 0;
    margin-bottom: 1rem;
  }
`

export const Favorite = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
  user-select: none;

  color: #1373fc;
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;

  .hover {
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const CandidateDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  margin: 0;
  padding: 2rem 2rem 1rem;
  border-radius: 4px;

  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #4b5c6b;
    margin-bottom: 1rem;
    padding-bottom: 0.25rem;
  }

  .star-full,
  .star {
    cursor: pointer;

    &:hover {
      color: #ffd700 !important;
    }
  }

  .star-full {
    color: #ffd700 !important;
  }

  .container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .skills {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0.5rem;
    background: #eee;
    border-radius: 4px;
    padding: 1rem;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      font-size: 1rem;
    }
  }

  .domainTitle {
    display: flex;
    justify-content: row;
    width: 100%;
  }

  .information {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 0.5rem;
    background: #eee;
    border-radius: 4px;
    padding: 1rem;
    user-select: none;

    p {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      font-size: 1rem;

      span {
        color: #4b5c6b;
        font-weight: bold;
        font-size: 0.9rem;
      }
    }
  }

  .stars {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .selection {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      border: none;
      background: #4b5c6b;
      padding: 0.5rem 2rem;
      color: #fff;
      border-radius: 4px;
      margin-top: 2rem;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`

export const ContentSchedule = styled.div`
  padding: 1rem;
  width: 100%;
  background: #fff;
  border-radius: 4px;

  .form {
    display: flex;
    width: 100%;
    flex-direction: column;

    .partition {
      display: flex;
      width: 100%;
    }

    h2 {
      font-size: 2rem;
      letter-spacing: 0.06rem;
      margin-bottom: 1rem;
      text-align: center;
    }

    .btn {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-right: 1rem;
      margin: 1rem 0;

      input {
        border-radius: 4px;
        padding: 0.5rem 2rem;
        border: none;
        background: #ff441d;
        color: #fff;
        text-transform: uppercase;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }
`

export const TitleRank = styled.h1`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  font-size: 1.5rem !important;

  span {
    display: flex;
    color: #4b5c6b;
    font-size: 0.8rem;
    align-items: baseline;
  }
`

export const Update = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1373fc;
  border-radius: 4px;
  padding: 0.5rem;
  color: #fff;
  border: none;
  margin-left: 0.25rem;
`
