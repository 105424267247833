import styled from 'styled-components'

interface IProps {
  width?: number
  active?: boolean
  toggle?: boolean
}

export const Container = styled.div`
  display: flex;
  width: 100%;

  padding: 1rem;

  .about {
    display: flex;
  }

  .rank {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1373fc;
    color: white;
    padding: 1rem 1.4rem;
    border-radius: 4px;
  }

  .points {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem 1rem;
    border-right: 1px dotted #ccc;
  }

  .numberPoints {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .textPoints {
    color: #ccc;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  .contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem 1rem;
    width: max-content;
    min-width: 200px;
  }

  .bar-back {
    width: 100%;
    height: 1.2rem;
    background-color: #efefef;
    margin: 0;
    border-radius: 2px;
  }

  .colum {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100vw;
  }

  .progress {
    display: flex;
    padding-left: 2rem;
  }

  .title-step {
    font-size: 0.9rem;
    color: #80868b;

    span {
      color: green;
    }
  }

  .tog {
    position: fixed;
    top: calc(100vh * 0.5);
    left: 0;
    height: 100vh;
    color: #1373fc;
    z-index: 10;
    opacity: 0.2;

    &:hover {
      opacity: 1;
    }

    svg:hover {
      cursor: pointer;
      color: #000;
    }
  }

  .tf {
    width: 2rem;
    color: #1373fc;
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }

  .titleDomain {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
  }

  .df {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const Content = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.toggle ? '73%' : '100%')};
  position: relative;
  padding-left: 0.5rem;

  h1 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #4b5c6b;
    letter-spacing: -1px;
    margin: 0;
    margin-bottom: 0.5rem;
    padding: 0;
  }

  h4 {
    position: absolute;
    top: 0.5rem;
    right: 0;
    font-weight: 400;
  }

  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #1373fc;
      color: #fff;
      font-size: 0.8rem;
      width: 150px;
      border-radius: 20px;
      cursor: pointer;
      padding: 0.3rem 0.5rem;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`

export const TitleSidebar = styled.h2`
  display: flex;
  align-items: center;
  width: 100%;
  color: #1373fc;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
`

export const Card = styled.div`
  display: flex;

  background: #fff;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 0.5rem;

  &:hover {
    background: #fafafa;
  }

  .about {
    width: 27%;
    span {
      text-transform: capitalize;
      letter-spacing: -0.5px;
    }
  }

  .actions-subscription {
    display: flex;
    align-items: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    width: 70%;
  }

  .filters {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 0.8rem;
    margin: 0 1rem;
    text-transform: capitalize;

    p {
      color: #aaa;

      span {
        /* font-weight: bold; */
        letter-spacing: -0.5px;
      }
    }
  }
`

export const Sidebar = styled.div<IProps>`
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  height: 100vh;
  flex-direction: column;
  flex-grow: 1;
  position: sticky;
  top: 1rem;
  overflow: auto;

  .bg {
    background: #fff;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    padding: 1rem;
  }

  .position {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 1.2rem;
    }

    select {
      border-radius: 4px;
      min-width: 13rem;
      border: 1px solid #ddd;
      min-height: 2.4rem;
      margin-left: 0.5rem;

      &:focus-visible {
        border: 1px solid #6495ed;
        outline: none;
      }
    }

    h4 {
      display: flex;
      align-items: flex-end;
      border-radius: 20px;
      background-color: #fff;
      height: max-content;
      padding: 0.25rem 0.5rem;
    }
  }

  .search {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
  }
`

export const Search = styled.form`
  display: flex;
  align-items: center;

  background: #f2f2f2;
  border-radius: 4px;
  padding-right: 0.5rem;

  input {
    display: flex;
    width: 100%;
    height: 2.5rem;
    border: 1px solid #f2f2f2;
    padding: 0 0.5rem;
    margin-right: 0.5rem;
    border-radius: 4px;

    &:focus-visible {
      border: 1px solid #6495ed;
      outline: none;
    }

    &::placeholder {
      color: #ddd;
    }
  }

  button {
    border: none;
    background: transparent;
    width: max-content;
    margin: 0;
    padding: 0;
  }

  svg {
    cursor: pointer;
    margin: 0.25rem 0;
  }
`
