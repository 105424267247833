import styled, { css } from 'styled-components'

interface IBar {
  required: number
  color: string
  height: number
}

interface IProps {
  color?: string
  active?: boolean
  resume?: boolean
}

interface ICard {
  progress?: number
  disable: boolean
}

interface IAlertProps {
  toggle: boolean
  type?: 'warning' | 'danger' | 'info'
}

interface ITrail {
  toggle: boolean
}

interface ILine {
  active: boolean
}

interface ICategory {
  color: string
  active: boolean
}

interface IDetails {
  color: string
}

const alertTypeVariations = {
  info: css`
    background: #add8e6;
    color: #3172b7;
  `,
  warning: css`
    background: #fffacd;
    color: #ffa500;
  `,
  danger: css`
    background: #fddede;
    color: #e52e4d;
  `
}

export const Alert = styled.div<IAlertProps>`
  position: relative;
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  width: 98%;
  height: 4rem;

  margin: 1rem 1rem 0;
  padding: 0 1rem;
  border-radius: 5px;

  p {
    width: 70%;
    margin: 0;
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .buttons {
    width: 20%;
    display: flex;
  }

  ${(props) => alertTypeVariations[props.type || 'info']}

  button {
    border: none;
    padding: 0.25rem 1rem;
    border-radius: 1rem;
    width: 8rem;
    margin: 0 0.5rem;
    font-size: 0.8rem;
  }

  .approve {
    background-color: #6495ed;
    color: white;

    &:hover {
      filter: brightness(0.8);
    }
  }

  .fail {
    background-color: #e52e4d;
    color: white;
  }

  .interview {
    background: transparent;
    border: 1px solid #6495ed;
    color: #6495ed;

    &:hover {
      background: #6495ed;
      color: white;
    }
  }

  svg {
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
`

export const PersonalInfo = styled.div<IProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  background: #fff;
  border-radius: 5px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  user-select: text;

  svg {
    cursor: pointer;
  }

  h2 {
    font-size: 1.3rem;

    span {
      font-size: 1rem;
    }
  }

  .resume {
    position: absolute;
    bottom: 0;
    left: 48.5%;
  }

  .modal {
    display: ${(props) => (!props.resume ? 'none' : 'block')};
    margin-top: 1rem;
  }

  .social-media {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;

    a {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      flex-direction: row;

      span {
        padding-left: 0.25rem;
      }
    }

    .social-links {
      display: flex;
      width: 100%;
      flex-direction: row;
      /* justify-content: space-evenly; */

      a {
        display: flex;
        width: max-content;
        margin-right: 1rem;
      }
    }
  }

  .block {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    position: relative;

    hr {
      display: flex;
      width: 100%;
      border: none;
      border-bottom: 1px solid #ddd;
    }

    .contact {
      position: absolute;
      top: 0;
      right: 0;

      display: flex;
      align-items: center;

      a {
        padding-left: 0.5rem;
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);
  }

  .question {
    font-size: 0.75rem;
    font-weight: bold;
  }

  .answer {
    margin: 0.25rem 0 0.5rem;
    font-size: 0.75rem;
    letter-spacing: 1px;
    text-align: justify;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      width: 7rem;

      border: none;
      color: #fff;
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      margin: 0.5rem;

      &:hover {
        filter: brightness(0.8);
      }
    }

    .approve {
      background: #34af23;
    }

    .fail {
      background: #e52e4d;
    }
  }

  .actions-status {
    display: flex;
    margin: 1rem 0;

    button {
      width: 100%;

      border: none;
      color: #fff;
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      margin-top: 0.5rem;

      &:hover {
        filter: brightness(0.8);
      }
    }

    textarea {
      display: flex;
      width: 100%;
      height: 4.7rem;
      border: 1px solid #ddd;
      resize: vertical;
      padding: 0.5rem;
    }

    .save {
      border: 1px solid #3f4348;
      background: #3f4348;
      height: max-content;
    }

    .buttons {
      padding-left: 0.5rem;
    }
  }

  .admin-info {
    color: #4b5c6b;
    font-size: 0.8rem;
    height: 2.5rem;
  }

  .status-professional {
    width: 100%;
    padding: 0.3rem 1rem;
    height: 2.1rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    background: #fff;

    &:hover {
      filter: brightness(0.9);
    }
  }

  .line-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

export const Line = styled.span<ILine>`
  display: flex;
  width: 200px;
  height: 0.5rem;
  border-top: 1px solid ${(props) => (props.active ? '#32CD32' : '#eee')};
  border-bottom: 1px solid ${(props) => (props.active ? '#32CD32' : '#eee')};
  background: ${(props) => (props.active ? '#32CD32' : '#eee')};
  margin: 0 0.25rem;
`

export const Social = styled.a`
  display: flex;
  align-items: flex-end;
  width: 100%;

  font-size: 0.8rem;
  color: #4b5c6b;
  margin-top: 0.25rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: #4b5c6b;
    filter: brightness(0.8);
  }

  span {
    padding-left: 0.25rem;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`

export const Title = styled.h2`
  position: relative;
  display: flex;
  width: 100%;
  color: #ff0000;
  background: #fff;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;

  .ranking {
    position: absolute;
    right: 1rem;

    span {
      font-size: 1.2rem;
    }
  }
`

export const SubTitle = styled.h3`
  display: flex;
  width: 100%;

  span {
    margin-right: 0.5rem;
  }
`

export const Skills = styled.section<IProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;
  border-radius: 5px;
  padding: 1rem;

  svg {
    cursor: pointer;
  }

  .resume {
    position: absolute;
    bottom: 0;
    left: 48.5%;
  }

  .modal {
    display: ${(props) => (!props.resume ? 'none' : 'block')};
    margin-top: 1rem;
  }
`

export const ActionSkills = styled.p<IProps>`
  display: flex;
  position: absolute;
  top: 1rem;
  right: 0.75rem;

  span {
    display: flex;
    width: 5rem;
    color: white;
    justify-content: center;

    background: ${(props) => (props.active ? '#3F4348' : '#ddd')};
    color: ${(props) => (props.active ? '#ddd' : '#3F4348')};

    border-radius: 10px;
    margin: 0 0.25rem;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 0.25rem 0;

    &:hover {
      filter: brightness(0.8);
    }

    & + span {
      background: ${(props) => (props.active ? '#ddd' : '#3F4348')};
      color: ${(props) => (props.active ? '#3F4348' : '#ddd')};
    }
  }
`

export const TableSkills = styled.table`
  width: 100%;
  color: #4b5c6b;
  margin-bottom: 1rem;

  thead {
    th {
      padding: 0 0.8rem;
    }
  }

  tbody {
    th {
      text-align: right;
      font-weight: 300;
      text-transform: capitalize;
      width: max-content;
    }
  }

  td {
    text-align: center;

    .bar-back {
      width: 15rem;
      height: 1.3rem;
      background-color: #f8f8ff;
      margin: 0.5rem 1rem;
      box-shadow: inset 0px 0px 0px 1px #eee;
      border-radius: 2px;
    }

    span {
      font-size: 0.7rem;
    }
  }
`

export const TDSkills = styled.span<IBar>`
  display: flex;
  background: ${(IBar) => IBar.color};
  width: ${(IBar) => IBar.required + '%'};
  height: ${(IBar) => IBar.height + 'rem'};
  line-height: 3rem;
  margin: 0;
`

export const Circle = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(IPropsCircle) => IPropsCircle.color};
  font-family: 'IBM Plex Sans', sans-serif;
  color: #ffffff;
  width: 1.5rem !important;
  height: 1.5rem !important;
  line-height: 1rem;
  font-size: 1rem;
  border: 1px solid ${(IPropsCircle) => IPropsCircle.color} !important;
  border-radius: 50%;
  font-weight: bold;
`

export const Chart = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  background: #fff;
  border-radius: 5px;
  margin: 1rem 0;

  padding: 1rem;
  position: relative;

  svg {
    cursor: pointer;
  }

  .resume {
    position: absolute;
    bottom: 0;
    left: 48.5%;
  }

  .description {
    display: ${(props) => (!props.resume ? 'none' : 'flex')};
    justify-content: space-around;
    width: 100%;

    .modal {
      display: block;
      height: max-content;
      margin: 2rem 0;
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      margin: 0 3rem;

      h3 {
        margin-bottom: 2rem;
        text-align: center;
        color: #4b5c6b;
      }
    }

    .categories {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
`

export const Journey = styled.div<IProps>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 7px;
  background: #fff;
  border-radius: 5px;
  margin: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;

  .modal {
    display: ${(props) => (!props.resume ? 'none' : 'flex')};
    width: 100%;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  svg {
    cursor: pointer;
  }

  .resume {
    position: absolute;
    bottom: 0;
    left: 48.5%;
  }
`

export const Card = styled.div<ICard>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 32%;

  opacity: ${(props) => (props.disable ? 0.4 : 1)};

  border-top: 0.6rem solid #3f4348;
  background: #f2f2f2;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem;

  cursor: ${(props) => (props.disable ? 'normal' : 'pointer')};

  h3 {
    margin: 0;
    padding-right: 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ff441d;
  }

  .inf {
    display: flex;
    justify-content: space-between;

    margin-bottom: 0.5rem;

    img {
      width: 7rem;
    }

    span {
      background: #cdcdcd;
      border-radius: 10px;
      width: max-content;
      height: max-content;
      padding: 0 0.5rem;
      font-size: 0.7rem;
      font-weight: bold;
    }
  }

  .progress {
    display: flex;
    align-items: center;
    background: transparent;
    margin-top: 0.5rem;

    p {
      display: flex;
      align-items: center;
      width: 70%;
      background: #cdcdcd;
      margin-right: 1rem;
      border-radius: 10px;

      span {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-radius: 10px;
        width: ${(props) => props.progress}%;
        min-width: 1.7rem;
        height: 1rem;
        background: ${(props) =>
          props.progress === 0 ? '#cdcdcd' : '#00B1F2'};
        color: #fff;
        padding-right: 0.4rem;
        font-size: 0.6rem;
      }
    }

    span {
      display: flex;
      align-items: center;
      width: 30%;
      font-size: 0.6rem;
      font-weight: bold;
    }

    .compare {
      position: absolute;
      bottom: 40%;
      right: 0;
      width: max-content;
      background: transparent;
      font-size: 1.5rem;
      color: #4b5c6b;

      span {
        display: block;
        border-radius: 10px;
        width: max-content;
        height: auto;
        background: transparent;
        color: #4b5c6b;
        padding: 0;
        font-size: 1rem;
      }
    }
  }

  .lock {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: ${(props) => (props.disable ? 'flex' : 'none')};
  }
`

export const FullCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 32%;
  height: 5rem;
  border-top: 0.6rem solid #3f4348;
  background: #f2f2f2;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  cursor: pointer;

  .journeyInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .journey-description {
      h3 {
        color: #ff441d;
        margin: 0;
        font-size: 0.9rem;
        padding-right: 0.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .journeyDados {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.25rem;
  }

  .hour {
    font-size: 0.6rem;
    border-radius: 10px;
    padding: 0.25rem 0.5rem;
    font-weight: bold;
    width: max-content;
  }

  .points {
    background: #ddd;
    padding: 0.25rem 0.5rem;
    color: #3f4348;
    border-radius: 10px;
    font-size: 0.6rem;
    width: max-content;
  }

  .conclude {
    position: absolute;
    top: 0;
    right: 0.2rem;
  }
`

export const ContentPopUp = styled.div<ITrail>`
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  flex-direction: column;
  z-index: 10;

  width: 100%;
  height: 100vh;

  background: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;

  .content {
    position: relative;
    display: flex;
    width: 90vw;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
  }

  table {
    font-family: 'Roboto', sans-serif;
    border-collapse: collapse;
    width: 100%;
    height: max-content;
    margin: 1rem;
  }

  td,
  th {
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background: #4b5c6b;
    color: #fff;
  }

  tfoot > tr > td {
    text-align: left;
    background: #4b5c6b;
    color: #fff;
  }

  .center {
    text-align: center;
  }
`

export const Close = styled.div`
  display: flex;
  width: 94vw;
  justify-content: flex-end;
  color: #fff;

  svg {
    cursor: pointer;

    &:hover {
      color: var(--red);
    }
  }
`

export const Action = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0;
  border-radius: 4px;
  padding: 2rem;
  background-color: #fff;

  .warn {
    margin: 2rem 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1rem 0;
  }

  select {
    border: none;
    border-bottom: 1px solid #ddd;
  }

  .warn form {
    margin-bottom: 1rem;
  }

  small {
    padding: 1rem 0 0 0.25rem;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    input[type='button'] {
      border: none;
      padding: 1rem;
      margin-left: 0.5rem;
      cursor: pointer;
      border-radius: 4px;
      text-transform: uppercase;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
`

export const Details = styled.details<IDetails>`
  background: #eee;
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  border-right: 3px solid
    ${(props) => (props.color !== undefined ? props.color : 'transparent')};

  &:hover {
    box-shadow: 0 0 2px #aaa;
  }

  summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    font-size: 1.1rem;
    cursor: pointer;
  }

  summary:after {
    color: ${(props) => (props.color !== undefined ? props.color : 'inherit')};
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    padding: 0 0.5rem;
    width: 20px;
    content: '+';
    text-align: center;
  }

  &[open] summary:after {
    content: '-';
    font-size: 1.8rem;
  }

  p {
    padding: 0.5rem;
    text-align: justify;
    color: inherit;
    font-size: 0.9rem;
    letter-spacing: 0;
    line-height: 1rem;
  }
`

export const ButtonsCategory = styled.input<ICategory>`
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  margin: 0.5rem 1rem 0.25rem 0;
  cursor: pointer;
  border: none;

  &:hover {
    background: ${(props) => props.color};
    color: #fff;
    opacity: 0.8;
  }

  background: ${(props) => (props.active ? props.color : '#ddd')};
  color: ${(props) => (props.active ? '#fff' : 'inherit')};
`

export const Admin = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  background: #fff;
  border-radius: 5px;
  margin-bottom: 1rem;

  padding: 1rem;

  .admin-buttons {
    display: flex;

    button {
      width: max-content;
      padding: 0.25rem 0.5rem;
      margin-left: 1rem;
      background: transparent;
      border: 1px solid #4b5c6b;
      border-radius: 3px;

      &:hover {
        background: #4b5c6b;
        color: #fff;
      }
    }
  }
`
