import { useCallback, useEffect, useState, useRef, useContext } from 'react'
import { i18n } from '../../translate/i18n'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { MdVisibility, MdAddCircle } from 'react-icons/md'
import { FaUserFriends, FaUserEdit } from 'react-icons/fa'
import { VscBellDot } from 'react-icons/vsc'
import { toast, TypeOptions } from 'react-toastify'
import { utcToZonedTime } from 'date-fns-tz'
import { getTime } from 'date-fns'

import { AuthContext, IUser } from '../../contexts/auth'
import { Navbar } from '../../components/Navbar'
import { RequestManager } from '../../security/RequestManager'
import { Tooltip } from '../../components/Tooltip'

import { getValidationErrors } from '../../utils/getValidationErrors'
import PopUp, { IPropsExport } from '../../components/PopUp'
import { Input } from '../../components/Form/Input'
import { Select } from 'src/components/Form/Select'

import {
  Container,
  Actions,
  Layer,
  Content,
  Complement,
  Action
} from './styles'

interface IProgram {
  id: number
  externalId: string
  title: string
  startDate: number
  endDate: number
  status: 'C' | 'O' | 'P'
  target: number
  isDeleted: boolean
  subscribers: number
  phases: {
    candidates: number
    phase: 'A' | 'D' | 'I'
    status: 'C' | 'O' | 'P' | 'I'
  }[]
}

interface IPrograms {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    programs: IProgram[]
  }
}

interface IProfile {
  profileId: number
  profileExternalId: number
  profileTitle: string
  profileDescription: string
  domains: [
    {
      domainId: number
      profileId: number
      domainLevel: number
      domainType: string
      domainTag: string
      domainTitle: string
      domainDescription: string
      sequence: number
      skills: [
        {
          domainId: number
          skillId: number
          skillLevel: number
          profileId: number
          skillTitle: string
          skillCategory: string
          skillGroup: string
        }
      ]
    }
  ]
}

interface IProfiles {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    talentProfiles: IProfile[]
  }
}

export const Programs = () => {
  const formRef = useRef<FormHandles>(null)
  const modalRef = useRef<IPropsExport>(null)

  const { user: userData } = useContext(AuthContext)

  const [programs, setPrograms] = useState<IProgram[]>([])

  const [user, setUser] = useState<IUser>({} as IUser)
  const [profiles, setProfiles] = useState<IProfile[]>()

  useEffect(() => {
    ;(async () => {
      const response = (await RequestManager.requestAPI(
        '/talent/program/list'
      )) as IPrograms
      if (
        response.receivedData.programs &&
        response.receivedData.programs.length > 0
      )
        setPrograms(response.receivedData.programs)
    })()
    ;(async () => {
      const response = (await RequestManager.requestAPI(
        '/talent/talentprofile/list'
      )) as IProfiles

      setProfiles(response.receivedData.talentProfiles)
    })()

    localStorage.removeItem('TALENT_PROGRAM_ID')
  }, [])

  useEffect(() => {
    userData && setUser(userData)
  }, [userData])

  const notify = useCallback(
    (text: string, type: TypeOptions, autoClose: number) => {
      toast(text, {
        type,
        autoClose,
        onClose: () => {}
      })
    },
    []
  )

  const handleSubmit = useCallback(
    async (data: {
      programTitle: string
      profile: string
      externalId: String
      target: number
      startDate: Date
      endDate: Date
    }) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          programTitle: Yup.string().required(
            i18n.t('pages.programs.typeError.title')
          ),
          externalId: Yup.string().required(
            i18n.t('pages.programs.typeError.externalId')
          ),
          profile: Yup.string().required(
            i18n.t('pages.programs.typeError.profile')
          ),
          target: Yup.number()
            .typeError(i18n.t('pages.programs.typeError.target'))
            .min(1, i18n.t('pages.programs.typeError.targetMin')),
          startDate: Yup.date()
            .default(() => {
              return new Date()
            })
            .typeError(i18n.t('pages.programs.typeError.startDate')),
          endDate: Yup.date()
            .default(() => {
              return new Date()
            })
            .typeError(i18n.t('pages.programs.typeError.endDate'))
        })

        await schema.validate(data, {
          abortEarly: false
        })

        RequestManager.requestAPI('/talent/program/save', {
          orgId: user.orgId,
          programTitle: data.programTitle,
          externalId: data.externalId,
          profileId: Number(data.profile),
          target: Number(data.target),
          startDate: getTime(utcToZonedTime(new Date(data.startDate), 'UTC')),
          endDate: getTime(utcToZonedTime(new Date(data.endDate), 'UTC')),
          domains: profiles!.filter(
            (profile) => profile.profileId === Number(data.profile)
          )[0].domains
        })
          .then(() => {
            notify(
              i18n.t('pages.programs.messages.successProgramCreate'),
              'success',
              2000
            )
            formRef.current?.reset()
            location.reload()
          })
          .catch((err) => {
            notify(err, 'error', 2000)
          })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)
        }

        notify(
          i18n.t('pages.programs.messages.errorProgramCreate'),
          'error',
          2000
        )
      }
    },
    [profiles, user]
  )

  const programInfo = useCallback((id: number) => {
    localStorage.setItem('TALENT_PROGRAM_ID', String(id))
  }, [])

  const wichPhase = (type: string): string => {
    let name = ''
    switch (type) {
      case 'A':
        name = 'Accelerate'
        break
      case 'D':
        name = 'Discovery'
        break
      case 'I':
        name = 'Ignite'
        break
      default:
        break
    }

    return name
  }

  const parmUrl = useCallback(
    (id: number): string => {
      let phaseName = ''

      programs
        .filter((program) => program.status !== 'C')
        [id].phases.forEach((phase) => {
          if (phase.status === 'O' && phase.candidates > 0) {
            phaseName = phase.phase
          }
        })
      return phaseName
    },
    [programs]
  )

  document.title = 'Programs :: Talent'

  return (
    <>
      <Navbar />
      <Container>
        <div className="content">
          {user?.profile === 'ADMIN' && (
            <div className="layer">
              <div className="newProgram">
                <MdAddCircle size={35} />
              </div>
              <Actions>
                <Tooltip title={i18n.t('pages.programs.tooltips.add')}>
                  <Action to="#" onClick={() => modalRef.current?.openModal()}>
                    <MdAddCircle size={35} />
                    {i18n.t('pages.programs.labels.add')}
                  </Action>
                </Tooltip>
              </Actions>
            </div>
          )}
          {programs &&
            programs
              .filter((program) => program.status !== 'C')
              .map((program, index) => (
                <div key={program.id} className="layer">
                  <h2 style={{ paddingBottom: '0.5rem' }}>
                    {program.title}
                    {program.subscribers > 0 && (
                      <VscBellDot className="bell" color="#3F4348" />
                    )}
                  </h2>
                  {program.phases &&
                    program.phases.map((phase) => (
                      <Layer
                        key={phase.phase}
                        width={
                          phase.phase === 'D'
                            ? '80%'
                            : phase.phase === 'I'
                            ? '69%'
                            : '59%'
                        }
                        pending={phase.status === 'P' || phase.status === 'I'}
                      >
                        <Content>
                          <h3 className="title">{wichPhase(phase.phase)}</h3>
                        </Content>
                        <Complement>
                          <p>
                            <FaUserFriends size={20} color="#3F4348" />
                            &nbsp;{phase.candidates}
                          </p>
                        </Complement>
                      </Layer>
                    ))}

                  <p className="pt-2">
                    <span className="start">
                      <span>{i18n.t('pages.programs.labels.start')}</span>{' '}
                      <data value={program.startDate}>
                        {new Date(program.startDate).toLocaleDateString(
                          'pt-BR'
                        )}
                      </data>
                    </span>
                    <span className="end">
                      <span>{i18n.t('pages.programs.labels.end')}</span>{' '}
                      <data value={program.endDate}>
                        {new Date(program.endDate).toLocaleDateString('pt-BR')}
                      </data>
                    </span>
                  </p>

                  <Actions>
                    {program.subscribers > 0 ? (
                      <Tooltip
                        title={i18n.t('pages.programs.tooltips.subscribers')}
                      >
                        <Action
                          to={`/subscribers?filter=0,${i18n.t(
                            'filters.type.pending'
                          )}`}
                          onClick={() => programInfo(program.id)}
                          style={{ color: '#fa8e00' }}
                        >
                          <FaUserEdit size={35} color="#fa8e00" />
                          {i18n.t('pages.programs.labels.subscribers')}
                        </Action>
                      </Tooltip>
                    ) : (
                      <Tooltip title={i18n.t('pages.programs.tooltips.view')}>
                        <Action
                          to={
                            program.phases.some(
                              (phase) =>
                                phase.status === 'O' && phase.candidates > 0
                            )
                              ? `/rank?phase=${parmUrl(index)}`
                              : '/subscribers'
                          }
                          onClick={() => programInfo(program.id)}
                        >
                          <MdVisibility size={35} />
                          {i18n.t('pages.programs.labels.view')}
                        </Action>
                      </Tooltip>
                    )}
                  </Actions>
                </div>
              ))}
        </div>
        <div className="content" style={{ paddingTop: '3rem' }}>
          {programs &&
            programs
              .filter((program) => program.status === 'C')
              .map((program, index) => (
                <div
                  key={program.id}
                  className="layer"
                  style={{ borderTop: '0.6rem solid #5a141a' }}
                >
                  <h2 style={{ paddingBottom: '0.5rem' }}>
                    {program.title +
                      ' - ' +
                      i18n.t('pages.programs.titles.close')}
                  </h2>
                  {program.phases &&
                    program.phases.map((phase) => (
                      <Layer
                        key={phase.phase}
                        width={
                          phase.phase === 'D'
                            ? '80%'
                            : phase.phase === 'I'
                            ? '69%'
                            : '59%'
                        }
                        pending={true}
                      >
                        <Content>
                          <h3 className="title">{wichPhase(phase.phase)}</h3>
                        </Content>
                        <Complement>
                          <p>
                            <FaUserFriends size={20} color="#3F4348" />
                            &nbsp;{phase.candidates}
                          </p>
                        </Complement>
                      </Layer>
                    ))}

                  <p className="pt-2">
                    <span className="start">
                      <span>{i18n.t('pages.programs.labels.start')}</span>{' '}
                      <data value={program.startDate}>
                        {new Date(program.startDate).toLocaleDateString(
                          'pt-BR'
                        )}
                      </data>
                    </span>
                    <span className="end">
                      <span>{i18n.t('pages.programs.labels.end')}</span>{' '}
                      <data value={program.endDate}>
                        {new Date(program.endDate).toLocaleDateString('pt-BR')}
                      </data>
                    </span>
                  </p>

                  <Actions>
                    <Tooltip title={i18n.t('pages.programs.tooltips.view')}>
                      <Action
                        to={
                          program.phases.some(
                            (phase) =>
                              phase.status === 'O' && phase.candidates > 0
                          )
                            ? `/rank?phase=${parmUrl(index)}`
                            : '/subscribers'
                        }
                        onClick={() => programInfo(program.id)}
                      >
                        <MdVisibility size={35} />
                        {i18n.t('pages.programs.labels.view')}
                      </Action>
                    </Tooltip>
                  </Actions>
                </div>
              ))}
        </div>
        <PopUp ref={modalRef} width="75%">
          <Form className="form" ref={formRef} onSubmit={handleSubmit}>
            <h2 className="titlePop">{i18n.t('pages.programs.titles.app')}</h2>
            <Select
              isRequired={true}
              label={i18n.t('pages.programs.labels.profile')}
              name="profile"
              defaultValue=""
              optionsObj={
                profiles && profiles.length > 0
                  ? profiles.map((profile) => {
                      return {
                        value: String(profile.profileId),
                        label: profile.profileTitle
                      }
                    })
                  : [
                      {
                        value: '',
                        label: ''
                      }
                    ]
              }
            />

            <div className="partition">
              <Input
                type="text"
                isRequired={true}
                id="programTitle"
                name="programTitle"
                label={i18n.t('pages.programs.labels.program')}
              />

              <Input
                type="text"
                isRequired={true}
                id="externalId"
                name="externalId"
                label={i18n.t('pages.programs.labels.externalId')}
              />
            </div>
            <div className="partition">
              <Input
                type="number"
                isRequired={true}
                id="target"
                name="target"
                label={i18n.t('pages.programs.labels.target')}
              />
              <Input
                type="date"
                isRequired={true}
                id="start"
                name="startDate"
                label={i18n.t('pages.programs.labels.start')}
              />
              <Input
                type="date"
                isRequired={true}
                id="end"
                name="endDate"
                label={i18n.t('pages.programs.labels.end')}
              />
            </div>
            <div className="btn">
              <button type="submit">
                {i18n.t('pages.programs.buttons.create')}
              </button>
            </div>
          </Form>
        </PopUp>
      </Container>
    </>
  )
}
