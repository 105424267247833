import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;

  h2 {
    position: fixed;
    top: 0;
    background: #fffacd;
    color: #ffa500;
    padding: 1rem;
    width: 100%;
    text-align: center;
  }
`
