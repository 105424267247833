import { useState, useEffect, useCallback } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { i18n } from '../../translate/i18n'
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaThumbsDown,
  FaThumbsUp
} from 'react-icons/fa'
import { BiFilterAlt } from 'react-icons/bi'
import { GiSandsOfTime } from 'react-icons/gi'
import { FcSearch } from 'react-icons/fc'
import produce from 'immer'

import { Navbar } from '../../components/Navbar'
import { BreadCrumbs } from '../../components/BreadCrumbs'

import { RequestManager } from '../../security/RequestManager'
import { ISubscriber } from '../Subscriber'
import { Filter } from '../../components/Filter'
import { CreateFilterContext } from '../../contexts/filter'

import {
  Container,
  Content,
  Sidebar,
  TitleSidebar,
  Card,
  Search
} from './styles'

import { Tooltip } from 'src/components/Tooltip'

import { IFunnel } from '../Funnel'
import { IFilterSelect } from '../Rank'

import Funnel from '../../assets/img/funnel.svg'

export type FieldsOfFilter =
  | 'currentEmployment'
  | 'englishLevel'
  | 'developmentExperience'
  | 'ethnicity'
  | 'gender'
  | 'salesforceExperience'
  | 'withDisability'
  | 'region'
  | 'educationalLevel'

interface IRequest {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    subscribers: ISubscriber[]
    orgId: number
    programId: number
    programTitle: string
    searchCount: number
    hasMore: boolean
  }
}

interface IFilter {
  currentEmployment: {
    options: {
      option: string
      count: number
    }[]
  }
  englishLevel: {
    options: {
      option: string
      count: number
    }[]
  }
  developmentExperience: {
    options: {
      option: string
      count: number
    }[]
  }
  ethnicity: {
    options: {
      option: string
      count: number
    }[]
  }
  gender: {
    options: {
      option: string
      count: number
    }[]
  }
  salesforceExperience: {
    options: {
      option: string
      count: number
    }[]
  }
  withDisability: {
    options: {
      option: string
      count: number
    }[]
  }
  region: {
    options: {
      option: string
      count: number
    }[]
  }
  educationalLevel: {
    options: {
      option: string
      count: number
    }[]
  }
}

interface IRequestFilter {
  hasError: boolean
  receivedData: {
    error?: number
    message?: string
    filters: IFilter
    program: {
      title: string
      phases: IFunnel[]
    }
  }
}

interface IFilterData {
  field: FieldsOfFilter
  filter: string
  visible?: boolean
  filterOptions: {
    label: string
    quantity: number
  }[]
}

export const SubscriberList = () => {
  const query = useLocation().search
  const filterURL = new URLSearchParams(query)
    .get('filter')
    ?.replace(/ /g, '')
    .trim()

  const history = useHistory()

  const [visible, setVisible] = useState(true)
  const [loadFilters, setLoadFilters] = useState(false)
  const [change, setChange] = useState(true)
  const [configs, setConfigs] = useState({
    offset: 0,
    limit: 100
  })
  const [programInfo, setProgramInfo] = useState({
    orgId: 0,
    programId: Number(localStorage.getItem('TALENT_PROGRAM_ID')),
    programTitle: '',
    searchCount: 0,
    hasMore: false
  })
  const [candidates, setCandidates] = useState<ISubscriber[]>([])
  const [filter, setFilter] = useState<IFilter>({} as IFilter)
  const [filters, setFilters] = useState<IFilterSelect[]>([])
  const [selectFilter, setSelectFilter] = useState('')
  const [filtered, setFiltered] = useState(
    [] as {
      name: string
      options: string[]
    }[]
  )
  const [data, setData] = useState<IFilterData[]>([])
  const [dataFilter, setDataFilter] = useState<IFunnel[]>([])
  const [status, setStatus] = useState(() => {
    if (filterURL) {
      let newStatus = ''
      switch (filterURL.split(',')[1]) {
        case i18n.t('filters.type.approved'):
          newStatus = 'A'
          break
        case i18n.t('filters.type.pending'):
          newStatus = 'P'
          break
        case i18n.t('filters.type.rejected'):
          newStatus = 'R'
          break
        case i18n.t('filters.type.completed'):
          break
        default:
          break
      }
      return newStatus
    } else {
      return ''
    }
  })
  const [search, setSearch] = useState('')

  const subscriberList = useCallback(
    async (
      filters?: { name: string; options: string[] }[],
      search?: string
    ) => {
      const response = (await RequestManager.requestAPI(
        '/talent/subscriber/list',
        {
          programId: programInfo.programId,
          limit: configs.limit,
          offset: configs.offset,
          filters,
          search,
          status
        }
      )) as IRequest
      if (response.receivedData.subscribers) {
        setCandidates(response.receivedData.subscribers)
        setProgramInfo({
          orgId: response.receivedData.orgId,
          programId: response.receivedData.programId,
          programTitle: response.receivedData.programTitle,
          searchCount: response.receivedData.searchCount,
          hasMore: response.receivedData.hasMore
        })
      }
    },
    [programInfo, status, configs]
  )

  const filterBy = useCallback(async () => {
    const response = (await RequestManager.requestAPI(
      '/talent/subscriber/filter',
      {
        programId: programInfo.programId,
        status
      }
    )) as IRequestFilter
    if (response.receivedData.filters) {
      setFilter(response.receivedData.filters)
      setLoadFilters(true)
    }
  }, [status, programInfo])

  const filteredField = useCallback(
    async (field: FieldsOfFilter, option: string, action: 'add' | 'remove') => {
      if (filtered.length === 0) {
        setFiltered(
          produce(filtered, (draft) => {
            draft.push({ name: field, options: [option] })
          })
        )
      } else {
        let indice = 0
        if (action === 'add') {
          setFiltered(
            produce(filtered, (draft) => {
              if (action === 'add') {
                if (draft.some((any) => any.name === field)) {
                  draft.forEach((any, index) => {
                    if (any.name === field) {
                      indice = index
                      return indice
                    }
                  })
                  draft[indice].options.push(option)
                } else draft.push({ name: field, options: [option] })
              }
            })
          )
        } else {
          setFiltered(
            produce(filtered, (draft) => {
              draft.map((drf, index) => {
                if (drf.name === field) {
                  if (drf.options.length > 1)
                    draft[index].options = draft[index].options.filter(
                      (fil) => fil !== option
                    )
                  else {
                    draft[index].options = []
                  }
                }
                return drf
              })
            })
          )
        }
      }
      setChange(true)
    },
    [filtered]
  )

  const loadMore = useCallback(
    async (filters?: { name: string; options: string[] }[]) => {
      setConfigs({
        limit: 100,
        offset: configs.offset + 100
      })
      const response = (await RequestManager.requestAPI(
        '/talent/subscriber/list',
        {
          programId: programInfo.programId,
          limit: configs.limit,
          offset: configs.offset + 100,
          filters,
          status
        }
      )) as IRequest
      setCandidates((oldArray) => [
        ...oldArray,
        ...response.receivedData.subscribers
      ])
      setProgramInfo({
        orgId: programInfo.orgId,
        programId: programInfo.programId,
        programTitle: programInfo.programTitle,
        searchCount: programInfo.searchCount,
        hasMore: response.receivedData.hasMore
      })
    },
    [configs, programInfo, status]
  )

  const found = useCallback(
    (field: FieldsOfFilter, option: string): boolean => {
      if (
        filtered.some((op) => op.name === field) &&
        filtered.some((filter) => filter.options.some((op) => op === option))
      ) {
        return true
      } else return false
    },
    [filtered]
  )

  if (loadFilters) {
    setData([
      {
        field: 'salesforceExperience',
        filter: i18n.t('filters.fields.salesforceExperience'),
        filterOptions:
          filter?.salesforceExperience?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'developmentExperience',
        filter: i18n.t('filters.fields.developmentExperience'),
        filterOptions:
          filter?.developmentExperience?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'englishLevel',
        filter: i18n.t('filters.fields.englishLevel'),
        filterOptions:
          filter?.englishLevel?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'educationalLevel',
        filter: i18n.t('filters.fields.educationalLevel'),
        filterOptions:
          filter?.educationalLevel?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'region',
        filter: i18n.t('filters.fields.region'),
        filterOptions:
          filter?.region?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'gender',
        filter: i18n.t('filters.fields.gender'),
        filterOptions:
          filter?.gender?.options.map((fil) => {
            return {
              label:
                fil.option === 'M'
                  ? i18n.t('person.male')
                  : fil.option === 'F'
                  ? i18n.t('person.female')
                  : i18n.t('person.other'),
              quantity: fil.count
            }
          }) || []
      },
      {
        field: 'ethnicity',
        filter: i18n.t('filters.fields.ethnicity'),
        filterOptions:
          filter?.ethnicity?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'withDisability',
        filter: i18n.t('filters.fields.withDisability'),
        filterOptions:
          filter?.withDisability?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      },
      {
        field: 'currentEmployment',
        filter: i18n.t('filters.fields.currentEmployment'),
        filterOptions:
          filter?.currentEmployment?.options.map((filter) => {
            return {
              label: filter.option,
              quantity: filter.count
            }
          }) || []
      }
    ])
    setLoadFilters(false)
  }

  const subscriberStepIds = useCallback((): Number[] => {
    const ids: Number[] = []
    dataFilter.forEach((dt) => {
      if (dt.phase !== 'S' && dt.status !== 'I')
        dt.steps.forEach((step) => {
          ids.push(step.id)
        })
    })
    return ids
  }, [dataFilter])

  useEffect(() => {
    if (filterURL) setSelectFilter(filterURL)
  }, [])

  useEffect(() => {
    ;(async () => {
      const response = (await RequestManager.requestAPI(
        '/talent/recruiter/loadprogramdata',
        {
          programId: Number(localStorage.getItem('TALENT_PROGRAM_ID'))
        }
      )) as IRequestFilter
      if (response.receivedData.program) {
        setDataFilter(response.receivedData.program.phases)
        response.receivedData.program.phases.forEach((dt) => {
          let phaseName = ''
          switch (dt.phase) {
            case 'S':
              phaseName = i18n.t('filters.phaseName.subscription')
              break
            case 'D':
              phaseName = i18n.t('filters.phaseName.discovery')
              break
            case 'I':
              phaseName = i18n.t('filters.phaseName.ignite')
              break
            case 'A':
              phaseName = i18n.t('filters.phaseName.accelerate')
              break
            default:
              break
          }
          dt.steps.forEach((step) => {
            let stepName = ''
            switch (step.type) {
              case '0':
                stepName = ''
                break
              case '1':
                stepName = i18n.t('filters.stepName.training')
                break
              case '2':
                stepName = i18n.t('filters.stepName.assessment')
                break
              case '3':
                stepName = i18n.t('filters.stepName.exam')
                break
              case '4':
                stepName = i18n.t('filters.stepName.agoge')
                break
              case '5':
                stepName = i18n.t('filters.stepName.interview')
                break
              case '9':
                stepName = ''
                break
              default:
                break
            }
            if (step.candidates.completed && step.status !== 'I') {
              setFilters((oldArray) => [
                ...oldArray,
                {
                  value: step.id + ',' + i18n.t('filters.type.completed'),
                  label: String(
                    (stepName ? phaseName + ' ' + stepName : phaseName) +
                      ' ' +
                      i18n.t('filters.type.completed') +
                      ' (#' +
                      (step.candidates.completed || 0) +
                      ')'
                  )
                }
              ])
            }
            if (step.candidates.approved && step.status !== 'I') {
              setFilters((oldArray) => [
                ...oldArray,
                {
                  value: step.id + ',' + i18n.t('filters.type.approved'),
                  label: String(
                    (stepName ? phaseName + ' ' + stepName : phaseName) +
                      ' ' +
                      i18n.t('filters.type.approved') +
                      ' (#' +
                      (step.candidates.approved || 0) +
                      ')'
                  )
                }
              ])
            }
            if (step.candidates.pending && step.status !== 'I') {
              setFilters((oldArray) => [
                ...oldArray,
                {
                  value: step.id + ',' + i18n.t('filters.type.pending'),
                  label: String(
                    (stepName ? phaseName + ' ' + stepName : phaseName) +
                      ' ' +
                      i18n.t('filters.type.pending') +
                      ' (#' +
                      (step.candidates.pending || 0) +
                      ')'
                  )
                }
              ])
            }
            if (step.candidates.rejected && step.status !== 'I') {
              setFilters((oldArray) => [
                ...oldArray,
                {
                  value: step.id + ',' + i18n.t('filters.type.rejected'),
                  label: String(
                    (stepName ? phaseName + ' ' + stepName : phaseName) +
                      ' ' +
                      i18n.t('filters.type.rejected') +
                      ' (#' +
                      (step.candidates.rejected || 0) +
                      ')'
                  )
                }
              ])
            }
          })
        })
      }
    })()
  }, [])

  useEffect(() => {
    if (change) {
      subscriberList(filtered)
      filterBy()
      setChange(false)
    }
  }, [change])

  document.title = 'Subscribers :: Talent'

  return (
    <CreateFilterContext.Provider value={{ filteredField }}>
      <Navbar />
      <BreadCrumbs
        path={[
          {
            label: i18n.t('breadCrumbs.label.program'),
            route: 'programs'
          }
        ]}
      />
      {candidates.length === 0 && filtered.length === 0 ? (
        <h1>{i18n.t('pages.subscribers.messages.notSubscribers')}</h1>
      ) : (
        <Container>
          <div className="tog">
            {!visible && (
              <FaChevronCircleRight
                size={20}
                onClick={() => setVisible(!visible)}
              />
            )}
          </div>

          <Sidebar toggle={visible}>
            <div className="bg">
              <div className="position">
                <div className="search">
                  <Link to="/funnel">
                    <img src={Funnel} alt="Funnel" width="34" />
                  </Link>
                  <select
                    name="filter"
                    id="filter"
                    value={selectFilter}
                    onChange={(event) => {
                      if (
                        subscriberStepIds().find(
                          (id) =>
                            id === Number(event.target.value.split(',')[0])
                        ) &&
                        event.target.value.split(',')[1].trim() !== ''
                      ) {
                        history.push(
                          `/rank?filter=${
                            event.target.value.split(',')[0] +
                            ',' +
                            event.target.value.split(',')[1]
                          }`
                        )
                      }

                      setSelectFilter(event.target.value)
                      switch (event.target.value.split(',')[1].trim()) {
                        case i18n.t('filters.type.approved'):
                          setStatus('A')
                          break
                        case i18n.t('filters.type.pending'):
                          setStatus('P')
                          break
                        case i18n.t('filters.type.rejected'):
                          setStatus('R')
                          break
                        case i18n.t('filters.type.completed'):
                          setStatus('')
                          break
                        default:
                          break
                      }

                      setChange(true)
                    }}
                  >
                    {filters.map(
                      (fil) =>
                        fil.label && (
                          <option key={fil.value} value={fil.value}>
                            {fil.label}
                          </option>
                        )
                    )}
                  </select>
                </div>
                <FaChevronCircleLeft
                  className="tf"
                  size={25}
                  onClick={() => setVisible(!visible)}
                />
              </div>
            </div>
            <div className="bg">
              <Search
                onSubmit={(event) => {
                  event.preventDefault()
                  search && subscriberList(undefined, search)
                }}
              >
                <input
                  type="search"
                  placeholder={i18n.t('pages.subscribers.messages.searchBy')}
                  onChange={(event) => setSearch(event.target.value)}
                />
                <button type="submit">
                  <FcSearch size={25} />
                </button>
              </Search>
            </div>
            <div className="bg">
              <div className="df">
                <TitleSidebar>
                  {i18n.t('pages.subscribers.messages.filterBy')}
                </TitleSidebar>
                <BiFilterAlt size={20} color="#1373fc" />
              </div>

              {data.length > 0 &&
                data.map((fil) => (
                  <Filter
                    key={fil.filter}
                    visible={fil?.visible}
                    field={fil.field}
                    filter={fil.filter}
                    filterOptions={fil.filterOptions || []}
                  />
                ))}
            </div>
          </Sidebar>

          <Content toggle={visible}>
            <h1>Subscribers - {programInfo.programTitle}</h1>
            <h4>
              {i18n
                .t('pages.subscribers.messages.search')
                .replace('[?]', String(programInfo.searchCount))}
            </h4>

            {candidates.length === 0 && (
              <h1>{i18n.t('pages.subscribers.messages.notFound')}</h1>
            )}

            {candidates.map((c) => (
              <Link
                key={c.id}
                to={`/subscriber/?id=${c.id}&count=${programInfo.searchCount}`}
              >
                <Card>
                  <p className="about">
                    <span className="contact">
                      {c.firstName.toLowerCase() +
                        ' ' +
                        c.lastName.toLowerCase()}
                    </span>
                  </p>
                  <div className="row">
                    <div className="filters">
                      <p>
                        {i18n.t('pages.subscribers.cardFilters.english')}
                        <span>
                          {found('englishLevel', c.englishLevel) ? (
                            <strong>{c.englishLevel}</strong>
                          ) : (
                            c.englishLevel
                          )}
                        </span>
                      </p>
                      <p>
                        {i18n.t('pages.subscribers.cardFilters.schooling')}
                        <span>
                          {found('educationalLevel', c.educationalLevel) ? (
                            <strong>{c.educationalLevel}</strong>
                          ) : (
                            c.educationalLevel
                          )}
                        </span>
                      </p>
                      <p>
                        {i18n.t('pages.subscribers.cardFilters.region')}
                        <span>
                          {found('region', c.region) ? (
                            <strong>
                              {c.region + '/' + c.city.toLowerCase()}
                            </strong>
                          ) : (
                            c.region + '/' + c.city.toLowerCase()
                          )}
                        </span>
                      </p>
                    </div>
                    <div className="filters">
                      <p>
                        {i18n.t('pages.subscribers.cardFilters.working')}
                        <span>
                          {found('currentEmployment', c.currentEmployment) ? (
                            <strong>{c.currentEmployment}</strong>
                          ) : (
                            c.currentEmployment
                          )}
                        </span>
                      </p>
                      <p>
                        {i18n.t('pages.subscribers.cardFilters.programing')}
                        <span>
                          {found(
                            'developmentExperience',
                            c.developmentExperience
                          ) ? (
                            <strong>{c.developmentExperience}</strong>
                          ) : (
                            c.developmentExperience
                          )}
                        </span>
                      </p>
                      <p>
                        {i18n.t('pages.subscribers.cardFilters.salesforce')}
                        <span>
                          {found(
                            'salesforceExperience',
                            c.salesforceExperience
                          ) ? (
                            <strong>{c.salesforceExperience}</strong>
                          ) : (
                            c.salesforceExperience
                          )}
                        </span>
                      </p>
                    </div>
                  </div>

                  {c.status === 'A' && (
                    <div className="actions-subscription">
                      <Tooltip
                        title={i18n.t('pages.subscribers.tooltips.approve')}
                      >
                        <FaThumbsUp size={35} color="#99cc20" />
                      </Tooltip>
                    </div>
                  )}
                  {c.status === 'R' && (
                    <div className="actions-subscription">
                      <Tooltip
                        title={i18n.t('pages.subscribers.tooltips.fail')}
                      >
                        <FaThumbsDown size={35} color="#E52E4D" />
                      </Tooltip>
                    </div>
                  )}
                  {c.status === 'N' && (
                    <div className="actions-subscription">
                      <Tooltip
                        title={i18n.t('pages.subscribers.tooltips.pending')}
                      >
                        <GiSandsOfTime size={35} color="#ddd" />
                      </Tooltip>
                    </div>
                  )}
                </Card>
              </Link>
            ))}

            {programInfo.hasMore && (
              <div className="load-more">
                <span onClick={() => loadMore(filtered)}>
                  {i18n.t('pages.subscribers.buttons.load')}
                </span>
              </div>
            )}
          </Content>
        </Container>
      )}
    </CreateFilterContext.Provider>
  )
}
